import { createAsyncAction, createStandardAction } from "typesafe-actions";
import { TagInterface } from "@h1eng/interfaces";

export interface CurrentTags {
  [personId: string]: TagInterface[];
}

export type BulkTagPayload =
  | { personIds: string[]; tagIds: string[] }
  | { personIds: string[]; tagName: string };

export const requestTagOptions = createAsyncAction(
  "tags.manage.options.request",
  "tags.manage.options.success",
  "tags.manage.options.failure"
)<never, Pick<TagInterface, "id" | "name" | "isH1">[], Error>();

export const setTagOptionsLoading = createStandardAction(
  "tags.manage.options.setLoading"
)<boolean>();

export const cancelTagOptionsRequest = createStandardAction(
  "tags.manage.search.cancel"
)<never>();

export const addTagToPerson = createAsyncAction(
  "tags.manage.addToPerson.request",
  "tags.manage.addToPerson.success",
  "tags.manage.addToPerson.failure"
)<
  { personId: string; tagId: string },
  { personId: string; tags: TagInterface[] },
  Error
>();

export const createAndAddTagToPerson = createAsyncAction(
  "tags.manage.createAndAddToPerson.request",
  "tags.manage.createAndAddToPerson.success",
  "tags.manage.createAndAddToPerson.failure"
)<
  { personId: string; name: string },
  { personId: string; tags: TagInterface[] },
  Error
>();

export const removeTagFromPerson = createAsyncAction(
  "tags.manage.removeFromPerson.request",
  "tags.manage.removeFromPerson.success",
  "tags.manage.removeFromPerson.failure"
)<
  { personId: string; tagId: string },
  { personId: string; tags: TagInterface[] },
  Error
>();

export const requestCurrentTags = createAsyncAction(
  "tags.manage.currentTags.request",
  "tags.manage.currentTags.success",
  "tags.manage.currentTags.failure"
)<string[], CurrentTags, Error>();

export const appendCurrentTagsForPeople = createAsyncAction(
  "tags.manage.currentTags.updatePerson.request",
  "tags.manage.currentTags.updatePerson.success",
  "tags.manage.currentTags.updatePerson.failure"
)<string | string[], CurrentTags, Error>();

export const bulkAddTagsToPeople = createAsyncAction(
  "tags.manage.bulk.addToPeople.request",
  "tags.manage.bulk.addToPeople.success",
  "tags.manage.bulk.addToPeople.failure"
)<BulkTagPayload, CurrentTags, Error>();

export const bulkRemoveTagsFromPeople = createAsyncAction(
  "tags.manage.bulk.removeFromPeople.request",
  "tags.manage.bulk.removeFromPeople.success",
  "tags.manage.bulk.removeFromPeople.failure"
)<{ personIds: string[]; tagIds: string[] }, CurrentTags, Error>();
