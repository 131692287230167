/* tslint:disable:cyclomatic-complexity */
import { AnalyticsPayload } from "./analytics";
import {
  FilterSearchInterface,
  SearchTypes,
  SearchView
} from "@h1eng/interfaces";
import {
  userSearchElastic,
  userSetSearchView
} from "../../actions/userInteractionActions";
import { ActionType } from "typesafe-actions";

export const search = (action: ActionType<typeof userSearchElastic>) => {
  const {
    filters: {
      specialty,
      country,
      state,
      institution,
      publications,
      trials,
      congresses,
      grants,
      payments
    },
    query,
    searchType
  } = action.payload;

  let obj: AnalyticsPayload = {
    query: query.join(", "),
    searchType,
    specialty: specialty.values.join(", "),
    country: country.values.join(", "),
    state: state.values.join(", "),
    institution: institution.values.join(", ")
  };

  if (searchType !== SearchTypes.NAME) {
    obj = {
      ...obj,
      "publications.minCount": publications.minCount.value || "",
      "publications.journal": publications.journal.values.join(", "),
      "publications.type": publications.type.values.join(", "),
      "trials.minCount": publications.minCount.value || "",
      "trials.status": trials.status.values.join(", "),
      "trials.phase": trials.phase.values.join(", "),
      "trials.studyType": trials.studyType.values.join(", "),
      "trials.funderType": trials.funderType.values.join(", "),
      "trials.sponsor": trials.sponsor.values.join(", "),
      "congresses.minCount": congresses.minCount.value || "",
      "congresses.name": congresses.name.values.join(", "),
      "congresses.type": congresses.type.values.join(", "),
      "grants.minAmount": grants.minAmount.value || "",
      "grants.funder": grants.funder.values.join(", "),
      "payments.minAmount": payments.minAmount.value || "",
      "payments.company": payments.company.values.join(", "),
      "payments.drugOrDevice": payments.drugOrDevice.values.join(", "),
      "payments.fundingType": payments.fundingType.values.join(", ")
    };
  }

  Object.keys(obj).forEach(key => {
    // @ts-ignore
    if (obj[key] === "") {
      // @ts-ignore
      delete obj[key];
    }
  });

  return obj;
};

export const setSearchView = (action: ActionType<typeof userSetSearchView>) => {
  return {
    searchView: SearchView[action.payload]
  };
};
