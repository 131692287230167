import { ProjectFeatures } from "@h1eng/interfaces";
import {
  ProjectsAction,
  getProjectsForUser,
  getProjectFeatures
} from "../actions/projects";
import { getType } from "typesafe-actions";

export interface ProjectState {
  projects: string[];
  selectedId: string | null;
  features: ProjectFeatures[];
}

const initialState: ProjectState = {
  projects: [],
  selectedId: null,
  features: []
};

export const projectReducer = (
  state: ProjectState = initialState,
  action: ProjectsAction
): ProjectState => {
  switch (action.type) {
    case getType(getProjectsForUser.success): {
      return {
        ...state,
        projects: action.payload,
        selectedId: action.payload[0]
      };
    }

    case getType(getProjectFeatures.success): {
      return {
        ...state,
        features: action.payload
      };
    }

    default:
      return state;
  }
};
