import * as React from "react";
import { connect } from "react-redux";
import {
  withStyles,
  createStyles,
  Theme,
  WithStyles
} from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import { FilterCountChip } from "../components/FilterCountChip";
import {
  AdvancedFilterSections,
  toggleAdvancedFilterSection
} from "../../../../store/actions/searchFilters";
import { RootState } from "../../../../store/reducers";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      boxShadow: "none",
      borderTop: "1px solid #979797",
      marginTop: 0
    },
    heading: {
      color: "#333333",
      fontSize: 14,
      fontWeight: "bold",
      lineHeight: "18px"
    },
    expansionPanelSummary: {
      height: 57,
      paddingTop: 5
    },
    headingRoot: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: "auto"
    },
    expansionPanelSummaryContent: {
      alignItems: "center",
      justifyContent: "space-between"
    },
    expansionPanelDetails: {
      display: "flex",
      flexDirection: "column",
      padding: 0
    },
    expansionPanelSummaryRoot: {
      "&:hover": {
        backgroundColor: "#f5f5f5"
      }
    }
  });

interface MappedStateProps {
  expandedOptions: AdvancedFilterSections[];
}

interface DispatchProps {
  toggleSection: (section: AdvancedFilterSections) => void;
}

interface Props {
  id: string;
  title: string;
  count: number;
  sectionKey: AdvancedFilterSections;
}

const FilterSectionPanel: React.FunctionComponent<
  Props & WithStyles<typeof styles> & MappedStateProps & DispatchProps
> = props => {
  const {
    classes,
    title,
    children,
    count,
    expandedOptions,
    toggleSection,
    sectionKey,
    id
  } = props;

  const toggleExpanded = (section: AdvancedFilterSections) => (
    event: React.ChangeEvent<{}>
  ) => {
    event.preventDefault();
    toggleSection(section);
  };

  const expanded = expandedOptions.indexOf(sectionKey) !== -1;

  return (
    <ExpansionPanel
      id={id}
      square
      className={classNames(classes.root)}
      expanded={expanded}
      onChange={toggleExpanded(sectionKey)}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        className={classNames(classes.expansionPanelSummary)}
        classes={{
          root: classes.expansionPanelSummaryRoot,
          content: classes.expansionPanelSummaryContent
        }}
      >
        <Typography className={classes.heading}>{title}</Typography>
        <div>
          <FilterCountChip label={count} />
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expansionPanelDetails}>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const mapStateToProps = (state: RootState): MappedStateProps => ({
  expandedOptions: state.searchFilters.advancedFiltersOpen
});

const mapDispatchToProps: DispatchProps = {
  toggleSection: toggleAdvancedFilterSection
};

export const FilterSection = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FilterSectionPanel));
