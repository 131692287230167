/* tslint:disable:cyclomatic-complexity */
const Fuse = require("fuse.js");
import { TextFilter } from "@h1eng/interfaces";
import { ENDPOINTS } from "../api-endpoints";

/**
 * Used to get the initial options, so filters
 * can be selected without searching
 */
export const getInitialOptions = async (key?: string) => {
  let endpoint = ENDPOINTS.searchFilters.initialOptions;

  if (key) {
    endpoint = `${endpoint}?key=${key}`;
  }

  const res = await fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include"
  });

  return res.json();
};

export const getOptions = async (filter: TextFilter): Promise<string[]> => {
  const res = await fetch(ENDPOINTS.searchFilters.getSuggestion, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(filter)
  });

  const options: string[] = await res.json();

  return fuzzySearch(filter.query, options);
};

function fuzzySearch(query: string, options: string[]) {
  const fuseConfig = {
    shouldSort: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1
  };

  if (!query || !options || options.length < 1) {
    return [];
  }

  try {
    const fuse = new Fuse(options, fuseConfig);

    return fuse
      .search(query)
      .map((i: number) => options[i])
      .filter(Boolean);
  } catch (e) {
    console.log(e);
    return [];
  }
}

export const searchOptions = async (
  filter: TextFilter,
  options: string[]
): Promise<string[]> => {
  return fuzzySearch(filter.query, options);
};
