import * as React from "react";
import classNames from "classnames";
import { RootState } from "../../../store/reducers";
import { connect } from "react-redux";
import { ListMeta } from "../ListManagement/ListManagementInterfaces";
import { MODAL } from "../../Modal";
import { ModalMessage, ModalMessageProps } from "../../modals/genericModal";
import { selectListId } from "../../../store/actions";
import {
  getAvailableLists,
  getCardsLoadingStatus,
  getSelectedListId
} from "../../../store/selectors";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#fff",
      border: "2px solid #E8E6E1",
      borderRadius: 8,
      fontFamily: theme.typography.fontFamily,
      marginTop: 54
    },
    list: {
      fontFamily: theme.typography.fontFamily
    },
    listTitle: {
      color: "#333333",
      fontSize: 18,
      fontWeight: "bold",
      lineHeight: "22px",
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 24,
      paddingBottom: 16,
      width: "auto",
      cursor: "default"
    },
    listItem: {
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      paddingLeft: 24,
      paddingRight: 24,
      "&:not(:last-of-type)": {
        borderBottom: "1px solid #A39E92"
      }
    },
    noListsItem: {
      cursor: "default"
    },
    inactiveListItem: {
      "&:hover": {
        backgroundColor: "#F5F5F5"
      }
    },
    listItemTitle: {
      fontSize: 14,
      lineHeight: "18px",
      fontWeight: "bold",
      width: "100%"
    },
    listItemContent: {
      width: "100%",
      fontSize: 12,
      fontWeight: 600,
      marginTop: 7,
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      alignItems: "center"
    },
    listItemCount: {
      // marginRight: "auto"
    },
    deleteButtonWrapper: {
      alignItems: "center",
      alignSelf: "center",
      justifySelf: "flex-end"
    },
    deleteButton: {
      fontWeight: 600
    },
    deleteButtonIcon: {
      width: 15,
      height: 15,
      marginRight: 7
    }
  });

interface ComponentProps {
  deleteList: (listId: string) => void;
}

interface MappedStateProps {
  selectedListId: string | null;
  listsAvailable: ListMeta[];
  loading: boolean;
}

interface DispatchProps {
  setSelectedList: (id: string) => void;
}

type Props = ComponentProps &
  MappedStateProps &
  DispatchProps &
  RouteComponentProps &
  WithStyles<typeof styles>;

interface State {
  modalOpen: boolean;
}

class ListMenuClass extends React.Component<Props, State> {
  static modalMessageProps = {
    title: "Delete List",
    boldText: "This list will be deleted.  This action can not be undone.",
    buttonText: "Are you sure you want to delete this list?"
  };

  state: State = {
    modalOpen: false
  };

  get modalMessageProps(): ModalMessageProps {
    return {
      ...ListMenuClass.modalMessageProps,
      onClose: this.setModalOpen(false),
      action: this.handleModalCTA
    };
  }

  setModalOpen = (modalOpen: boolean) => (event?: any) => {
    this.setState({ modalOpen });
  };

  handleModalCTA = () => {
    if (this.props.selectedListId) {
      this.props.deleteList(this.props.selectedListId);
      this.setState({ modalOpen: false });
    }
  };

  getDate = (date: string) => {
    const inputDate = new Date(date);
    const todaysDate = new Date();
    if (inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
      return "Today";
    }
    return inputDate.toLocaleDateString("en-US");
  };

  handleListItemClick = (listId: string) => () => {
    this.props.history.push(`/saved-lists/${listId}`);
  };

  render() {
    const { listsAvailable, selectedListId, classes } = this.props;

    return (
      <>
        <MODAL open={this.state.modalOpen} onClose={this.setModalOpen(false)}>
          <ModalMessage {...this.modalMessageProps} />
        </MODAL>
        <div className={classes.root}>
          <div className={classes.listTitle}>Saved Lists</div>
          <List className={classes.list}>
            {!listsAvailable ||
              (listsAvailable.length === 0 && (
                <ListItem
                  className={classNames(classes.listItem, classes.noListsItem)}
                >
                  <span className={classes.listItemTitle}>
                    No Lists Available
                  </span>
                </ListItem>
              ))}
            {listsAvailable.map((list: ListMeta) => {
              const selected = list.id === selectedListId;

              return (
                <ListItem
                  key={`list=${list.id}`}
                  selected={selected}
                  className={classNames(classes.listItem, {
                    [classes.inactiveListItem]: !selected
                  })}
                  onClick={this.handleListItemClick(list.id)}
                >
                  <span className={classes.listItemTitle}>{list.title}</span>
                  <div className={classes.listItemContent}>
                    <span className={classes.listItemCount}>{list.length}</span>
                    <span>{this.getDate(list.updateDate || "")}</span>
                    <div className={classes.deleteButtonWrapper}>
                      <Button
                        size="small"
                        className={classes.deleteButton}
                        onClick={this.setModalOpen(true)}
                      >
                        <DeleteIcon
                          color="inherit"
                          className={classes.deleteButtonIcon}
                        />
                        Delete
                      </Button>
                    </div>
                  </div>
                </ListItem>
              );
            })}
          </List>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): MappedStateProps => ({
  selectedListId: getSelectedListId(state),
  listsAvailable: getAvailableLists(state),
  loading: getCardsLoadingStatus(state)
});

const mapDispatchToProps: DispatchProps = {
  setSelectedList: selectListId
};

export const ListMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(ListMenuClass)));
