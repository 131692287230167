import * as React from "react";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog, {
  InjectedProps
} from "@material-ui/core/withMobileDialog";
import { WithWidth } from "@material-ui/core/withWidth";
import { RootState } from "../../../../../store/reducers";
import { setCustomSortModalOpen } from "../../../../../store/actions";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import { Manage, Create } from "./Tabs";
import { CustomSortBy } from "@h1eng/interfaces";

const styles = (theme: Theme) =>
  createStyles({
    titleWrapper: {
      display: "flex",
      flexDirection: "row",
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: "auto",
      alignItems: "center",
      justifyContent: "space-between"
    },
    titleText: {
      color: "#fff",
      fontSize: 20,
      fontFamily: "Montserrat"
    },
    closeButton: {
      alignSelf: "flex-start"
    },
    appBarRoot: {
      boxShadow: "0px 1px 0px #d8d8d8"
    },
    paper: {
      minWidth: 474,
      [theme.breakpoints.up("md")]: {
        width: 474
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: "unset"
      }
    }
  });

interface MappedStateProps {
  open: boolean;
  customSortConfigurations: CustomSortBy[];
}

interface DispatchProps {
  setOpen: (open: boolean) => void;
}

const TabOptions = ["Manage", "Create"];

const CustomSortModalComponent: React.FunctionComponent<
  MappedStateProps &
    DispatchProps &
    InjectedProps &
    Partial<WithWidth> &
    WithStyles<typeof styles>
> = props => {
  const handleSetOpen = (open: boolean) => () => {
    props.setOpen(open);
  };

  const [tab, setTab] = React.useState(1);

  React.useEffect(() => {
    if (!props.open) {
      if (props.customSortConfigurations.length === 0) {
        setTab(1);
      } else {
        setTab(0);
      }
    }
  });

  const { classes } = props;

  return (
    <Dialog
      fullScreen={props.fullScreen}
      open={props.open}
      onClose={handleSetOpen(false)}
      scroll="paper"
      PaperProps={{
        classes: {
          root: classes.paper
        }
      }}
    >
      <AppBar
        color="primary"
        position="sticky"
        classes={{ root: classes.appBarRoot }}
      >
        <div className={classes.titleWrapper}>
          <DialogTitle disableTypography>
            <span className={classes.titleText}>Custom Sorting</span>
          </DialogTitle>
        </div>
        <Tabs
          value={tab}
          onChange={(e: any, value: number) => {
            setTab(value);
          }}
          variant="fullWidth"
        >
          {TabOptions.map(i => (
            <Tab label={i} key={i} />
          ))}
        </Tabs>
      </AppBar>
      {tab === 0 && (
        <Manage
          onClose={handleSetOpen(false)}
          createNewOption={() => {
            setTab(1);
          }}
        />
      )}
      {tab === 1 && <Create onClose={handleSetOpen(false)} />}
    </Dialog>
  );
};

const mapStateToProps = (state: RootState): MappedStateProps => ({
  open: state.searchFilters.customSortModalOpen,
  customSortConfigurations: state.searchFilters.customSortingOptions
});

const mapDispatchToProps: DispatchProps = {
  setOpen: setCustomSortModalOpen
};

export const CustomSortModal = withMobileDialog()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(CustomSortModalComponent))
);
