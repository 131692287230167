import { isActionOf, ActionType } from "typesafe-actions";
import {
  Epic,
  StateObservable,
  ActionsObservable,
  ofType
} from "redux-observable";
import { GrowlerTypes } from "@h1eng/interfaces";
import {
  switchMap,
  filter,
  catchError,
  map,
  withLatestFrom
} from "rxjs/operators";
import { of, concat, from, pipe, empty } from "rxjs";
import {
  requestSpecialtyOptions,
  requestCountryOptions,
  requestStateOptions,
  requestInstitutionOptions,
  clearSearchFilters,
  getInitialFilterOptions,
  setInitialOptionsLoaded,
  setFilterCompletionQueryLoading,
  getFilterOptionsByKey,
  setFilterOptionLoading,
  clearFilterOptionLoading,
  SearchFilterAction
} from "../../actions/searchFilters";
import { createGrowler } from "../../actions/growler";
import { getInitialOptions } from "../../../apis/searchFilters";
import { RootState } from "../../reducers";
import specialtyEpics from "./specialties";
import countryEpics from "./countries";
import stateEpics from "./states";
import institutionEpics from "./institutions";
import publicationsEpics from "./publications";
import tagsEpics from "./tags";
import congressesEpics from "./congresses";
import trialsEpics from "./trials";
import grantsEpics from "./grants";
import paymentsEpics from "./payments";
import handleSearchEpics from "./handleSearch";
import customSortOptionsEpics from "./customSortOptions";
import autosuggestQueryEpics from "./queryAutosuggest";

export const DEBOUNCE_TIME = 600;

const requestInitialOptionsFlow: Epic<any, any, any> = action$ =>
  action$.pipe(
    ofType(getInitialFilterOptions.request),
    switchMap(() =>
      concat(
        of(setInitialOptionsLoaded(false)),
        from(getInitialOptions()).pipe(
          map(getInitialFilterOptions.success),
          catchError(
            pipe(
              getInitialFilterOptions.failure,
              of
            )
          )
        )
      )
    )
  );

const requestOptionsByKeyFlow = (
  action$: ActionsObservable<ActionType<typeof getFilterOptionsByKey>>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(getFilterOptionsByKey.request)),
    // withLatestFrom(state$),
    switchMap(({ payload }) =>
      concat(
        of(setFilterOptionLoading(payload)),
        from(getInitialOptions(payload)).pipe(
          map(getFilterOptionsByKey.success),
          catchError(
            pipe(
              getFilterOptionsByKey.failure,
              of
            )
          )
        )
      )
    )
  );

const handleGetFilterOptionsByKeySuccessFlow = (
  action$: ActionsObservable<ActionType<typeof getFilterOptionsByKey>>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(getFilterOptionsByKey.success)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      concat(
        of(
          Object.keys(action.payload).length > 0
            ? clearFilterOptionLoading(Object.keys(action.payload))
            : empty()
        ),
        of(
          getInitialFilterOptions.success({
            ...state.searchFilters.initialOptions,
            ...action.payload
          })
        )
      )
    )
  );

const initialOptionsLoadedFlow: Epic<any, any, any> = action$ =>
  action$.pipe(
    ofType(getInitialFilterOptions.success),
    switchMap(() =>
      concat(
        of(setInitialOptionsLoaded(true)),
        of(setFilterCompletionQueryLoading(false))
      )
    )
  );

// const initialOptionsFailureFlow: Epic<any, any, any> = action$ =>
//   action$.pipe(
//     ofType(getInitialFilterOptions.failure),
//     switchMap(() => concat(of(setInitialOptionsLoaded(true))))
//   );

const handleOptionsRequestErrorFlow: Epic<SearchFilterAction, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(
      isActionOf([
        requestSpecialtyOptions.failure,
        requestCountryOptions.failure,
        requestStateOptions.failure,
        requestInstitutionOptions.failure
      ]),
      switchMap(() =>
        concat(
          of(clearSearchFilters()),
          of(
            createGrowler({
              title: "Error",
              description:
                "There was an error fetching options for this filter",
              titleIcon: "warning",
              growler: GrowlerTypes.fail
            })
          )
        )
      )
    )
  );

export const searchFilterEpics = [
  ...specialtyEpics,
  ...countryEpics,
  ...stateEpics,
  ...institutionEpics,
  ...publicationsEpics,
  ...tagsEpics,
  ...trialsEpics,
  ...congressesEpics,
  ...grantsEpics,
  ...paymentsEpics,
  ...handleSearchEpics,
  ...customSortOptionsEpics,
  ...autosuggestQueryEpics,
  handleOptionsRequestErrorFlow,
  requestInitialOptionsFlow,
  initialOptionsLoadedFlow,
  requestOptionsByKeyFlow,
  handleGetFilterOptionsByKeySuccessFlow
  // initialOptionsFailureFlow
];
