import { KolCardsState, kolCardsReducer } from "./kolCards";
import { searchReducer, SearchState } from "./search";
import { userReducer, UserState } from "./User";
import { listCardReducer, ListState } from "./lists";
import { growlerReducer, GrowlerState } from "./growler";
import { ProjectState, projectReducer } from "./projects";
import { SearchBarQuery, searchBarReducer } from "./searchBar";
import { configReducer, ConfigState } from "./config";
import { CollaboratorState, CollaboratorReducer } from "./collaborators";
import { SearchFilterState, searchFilterReducer } from "./searchFilterReducer";
import {
  UserInfoRequestState,
  userInfoRequestReducer
} from "./userInfoRequestReducer";
import { TagsState, tagsReducer } from "./tagsReducer";
import {
  SearchBarQuery as documentSearchBar,
  searchBarReducer as documentSearchBarReducer
} from "./documentSearchBar";
import { DataExportsState, dataExportsReducer } from "./dataExportsReducer";

export interface RootState {
  kolCards: KolCardsState;
  searchResults: SearchState;
  user: UserState;
  config: ConfigState;
  lists: ListState;
  projects: ProjectState;
  growlers: GrowlerState;
  searchBar: SearchBarQuery;
  documentSearchBar: documentSearchBar;
  collaborator: CollaboratorState;
  searchFilters: SearchFilterState;
  userInfoRequest: UserInfoRequestState;
  dataExports: DataExportsState;
  tags: TagsState;
}

export const reducersObj = {
  kolCards: kolCardsReducer,
  searchResults: searchReducer,
  user: userReducer,
  config: configReducer,
  lists: listCardReducer,
  projects: projectReducer,
  growlers: growlerReducer,
  searchBar: searchBarReducer,
  documentSearchBar: documentSearchBarReducer,
  collaborator: CollaboratorReducer,
  searchFilters: searchFilterReducer,
  userInfoRequest: userInfoRequestReducer,
  dataExports: dataExportsReducer,
  tags: tagsReducer
};
