import { combineEpics } from "redux-observable";
import {
  fetchKolCardFlow,
  fetchKolSavedListsCardFlow,
  clearLoadingMessageFlow,
  setKOLDataRequestFlow
} from "./kolCards";
import {
  fetchSearchFlow,
  fetchSearchDocumentsFlow,
  refetchDocuments,
  setPageNumberFlow,
  searchFailureAlertFlow,
  searchLoadingMessageFlow,
  handleHeapAnalyticsFlow
} from "./search";
import { fetchProjectsFlow, fetchProjectFeaturesFlow } from "./projects";
import { fetchCurrentUserFlow /*, logoutFlow*/ } from "./auth";
import {
  getListOptionFlow,
  fetchListFlow,
  createListFlow,
  refetchListFlow,
  addToListFlow,
  deleteListFlow,
  removeFromListFlow,
  // createListWithGrowlerFlow,
  setSelectedListFlow,
  setSavedListPageNumFlow,
  removePeopleFromListFlow
} from "./lists";
import {
  fetchCollaborators,
  autoFetchCollaborators,
  fetchCollaboratorsDetials,
  fetchLiteProfiles,
  autoFetchCollaboratorsAfterPageUpdate,
  autoFetchCollaboratorsAfterQureyUpdate,
  autoFetchCollaboratorsAfterDateChange,
  fetchTagsForCollaborators
} from "./collaborators";
import { searchFilterEpics } from "./searchFilters";
import userInfoRequestEpics from "./userInfoRequestEpics";

import dataExportsEpics from "./dataExportEpics";

import userInteractionEpics from "./userInteractionEpics";
import tagEpics from "./tagsEpics";

const allEpics = [
  fetchKolCardFlow,
  fetchSearchFlow,
  fetchListFlow,
  getListOptionFlow,
  fetchProjectsFlow,
  fetchProjectFeaturesFlow,
  createListFlow,
  removeFromListFlow,
  refetchListFlow,
  addToListFlow,
  deleteListFlow,
  // createListWithGrowlerFlow,
  fetchCurrentUserFlow,
  fetchSearchDocumentsFlow,
  setPageNumberFlow,
  refetchDocuments,
  fetchKolSavedListsCardFlow,
  fetchCollaborators,
  autoFetchCollaborators,
  fetchCollaboratorsDetials,
  fetchLiteProfiles,
  autoFetchCollaboratorsAfterPageUpdate,
  autoFetchCollaboratorsAfterQureyUpdate,
  autoFetchCollaboratorsAfterDateChange,
  fetchTagsForCollaborators,
  setSelectedListFlow,
  searchFailureAlertFlow,
  setSavedListPageNumFlow,
  removePeopleFromListFlow,
  searchLoadingMessageFlow,
  clearLoadingMessageFlow,
  handleHeapAnalyticsFlow,
  setKOLDataRequestFlow,
  ...searchFilterEpics,
  ...userInfoRequestEpics,
  ...dataExportsEpics,
  ...userInteractionEpics,
  ...tagEpics

  //,
  // logoutFlow
];

export const rootEpics = combineEpics(...allEpics);
