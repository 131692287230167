/* tslint:disable:react-unused-props-and-state */
import * as React from "react";

import { LoggedInShell } from "../../../templates/LoggedInShell";

import {
  currentUser,
  getSelectedProject,
  getAvailableListsLoaded
} from "../../../../store/selectors";

import { RootState } from "../../../../store/reducers/index";
import { connect } from "react-redux";
import { UserVO } from "../../../../models/UserVO";
import { CurieSearch } from "../components/Search";
import { H3 } from "@h1eng/ui-components";
import { getListOptions } from "../../../../store/actions";

interface CurieSearchState {
  user: UserVO | null;
  projectId: string | null;
  listLoaded: boolean;
}

interface CurieSearchDispatchers {
  fetchAvailableLists: (opts: { projectId: string; userId: string }) => void;
}

export class CurieSearchPageClass extends React.Component<
  CurieSearchState & CurieSearchDispatchers,
  {
    recentProjectId: string;
  }
> {
  state = {
    recentProjectId: ""
  };

  loadFiltersIfNeeded = () => {
    const recentProjectId = this.props.projectId;
    if (recentProjectId && recentProjectId !== this.state.recentProjectId) {
      this.setState({
        recentProjectId
      });
    }
  };

  loadListsIfNeeded = () => {
    const recentProjectId = this.props.projectId;
    const user = this.props.user;
    if (recentProjectId && user) {
      this.props.fetchAvailableLists({
        projectId: recentProjectId,
        userId: user.id
      });
    }
  };

  componentDidMount() {
    // this.loadFiltersIfNeeded();
    this.loadListsIfNeeded();
  }

  componentDidUpdate() {
    // this.loadFiltersIfNeeded();
    this.loadListsIfNeeded();
  }

  canRender = () => this.props.user && this.props.projectId;

  render() {
    return (
      <LoggedInShell>
        {this.canRender() ? (
          <CurieSearch />
        ) : (
          <div style={{ marginTop: "40px", textAlign: "center" }}>
            <H3>{`Loading...`}</H3>
          </div>
        )}
      </LoggedInShell>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  projectId: getSelectedProject(state),
  user: currentUser(state),
  listLoaded: getAvailableListsLoaded(state)
});

const mapDispatchToProps = {
  fetchAvailableLists: getListOptions.request
};

export const CurieSearchPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(CurieSearchPageClass);
