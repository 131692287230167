import { ActionsObservable } from "redux-observable";
import { ActionType, isActionOf } from "typesafe-actions";
import { filter, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { RootState } from "../reducers";
import * as actions from "../actions/userInteractionActions";
import {
  setPageNum,
  searchElastic,
  setSearchView,
  clearLoadingMessage,
  searchSubDocumentsElastic,
  setQuery
} from "../actions";

type Action = ActionType<typeof actions>;

type ObservableAction = ActionsObservable<Action>;

const searchFlows = [
  (action$: ObservableAction) =>
    action$.pipe(
      filter(isActionOf(actions.userSearchElastic)),
      mergeMap(({ payload }) => of(searchElastic.request(payload)))
    ),
  (action$: ObservableAction) =>
    action$.pipe(
      filter(isActionOf(actions.userSetSearchView)),
      mergeMap(({ payload }) => of(setSearchView(payload)))
    ),
  (action$: ObservableAction) =>
    action$.pipe(
      filter(isActionOf(actions.userSetPageNum)),
      mergeMap(({ payload }) => of(setPageNum(payload)))
    ),
  (action$: ObservableAction) =>
    action$.pipe(
      filter(isActionOf(actions.userClearLoadingMessage)),
      mergeMap(() => of(clearLoadingMessage()))
    ),
  (action$: ObservableAction) =>
    action$.pipe(
      filter(isActionOf(actions.userSearchSubDocumentsElastic)),
      mergeMap(({ payload }) => of(searchSubDocumentsElastic.request(payload)))
    ),
  (action$: ObservableAction) =>
    action$.pipe(
      filter(isActionOf(actions.userSetQuery)),
      mergeMap(({ payload }) => of(setQuery(payload)))
    )
];

export default [...searchFlows];
