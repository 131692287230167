import * as React from "react";
import { connect } from "react-redux";
import { styled, PRIMARY_BUTTON } from "@h1eng/ui-components";
import {
  getListForUser,
  getListOptions,
  createList
} from "../../store/actions";
import { GrowlerTypes } from "../growler/GrowlerTypes";
import { createGrowler, GrowlerInterface } from "../../store/actions/growler";
import { currentUser, getSelectedProject } from "../../store/selectors";
import { RootState } from "../../store/reducers";
import { UserVO } from "../../models/UserVO";
import { ListVO } from "../../models/ListVO";

interface MappedStateProps {
  user: UserVO;
  projectId: string;
}

interface DispatchProps {
  getList: (opts: {
    projectId: string;
    userId: string;
    personIds: string[];
  }) => void;
  getListOption: (opts: { projectId: string; userId: string }) => void;
  createNewGrowler: (args: GrowlerInterface) => void;
  createNewList: (opts: ListVO) => void;
}

const CARD = styled.div`
  display: flex;
  flex-direction: column;
  width: 472px;
  border-radius: 1px;
  box-shadow: 0 9.5px 19px 0 rgba(0, 0, 0, 0.3),
    0 7.5px 6px 0 rgba(0, 0, 0, 0.22);

  background-image: linear-gradient(#fafafa, #fafafa),
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.4) 5%,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0)
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

const CARD_HEARD = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  align-items: center;
  background: #060464;
`;
const SAVE_TO_LIST = styled.div`
  font-size: 20px;
  font-family: Montserrat;
  margin-left: 15px;
  color: white;
`;

const X_CONTAINER = styled.div`
  margin-right: 15px;
  cursor: pointer;
  cursor: hand;
`;
const MESSAGE = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  color: #333333;
  text-align: center;
  margin-top: 20px;

  padding-right: 40px;
  padding-left: 40px;
`;
const TEXT_SEARCH_INPUT = styled.input<{ color: string }>`
  border: 0;
  outline: 0;
  background: transparent;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 2px solid #060464;
  width: 100%;
  margin-top: 20px;
  text-align: left;
  margin-right: 40px;
  margin-left: 40px;
`;

const INPUT_CONTAINER = styled.div`
  display: flex;
  justify-content: center;
`;
const CANCEL_CONTAINER = styled.div`
  margin-top: 10px;
  padding-right: 40px;
  padding-left: 40px;
  display: flex;
  justify-content: center;
`;

const CANCEL = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  color: #0aaacd;
  padding-bottom: 30px;
  cursor: pointer;
  cursor: hand;
`;
const BUTTON_TEXT = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: #ffffff;
  width: 82px;
  height: 36px;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  background-color: #060464;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BUTTON_CONTAINER = styled.div`
  margin-top: 20px;
  padding-right: 40px;
  padding-left: 40px;
  display: flex;
  justify-content: center;
`;
interface CreateNewListModalProps {
  onClose: () => void;
  personIds: string[];
}
interface CreateNewListReduxProps {
  user: UserVO;
  projectId: string;
}

interface CreateNewListModalState {
  message: string;
  newListName: string;
}

export class CreateNewListModalClass extends React.Component<
  CreateNewListModalProps &
    CreateNewListReduxProps &
    DispatchProps &
    MappedStateProps,
  CreateNewListModalState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      newListName: "",
      message: `You can save these ${
        props.personIds.length
      } profiles to a new list after you name and create it.`
    };
  }

  createNewListHelper() {
    if (this.state.newListName.trim() === "") {
      alert("Please enter a valid list name.");
    } else {
      this.props.createNewList({
        personIds: this.props.personIds,
        listName: this.state.newListName,
        projectId: this.props.projectId,
        userId: this.props.user.id
      });
      this.props.createNewGrowler({
        title: `Saved To List`,
        description: `You have saved ${
          this.props.personIds.length
        } people to the list ${this.state.newListName} `,
        growler: GrowlerTypes.success,
        titleIcon: "check"
      });
      this.props.onClose();
    }
  }

  render() {
    return (
      <CARD>
        <CARD_HEARD>
          <SAVE_TO_LIST>Create New List</SAVE_TO_LIST>
          <X_CONTAINER>
            <img
              src={"https://d10ba5vkyum61s.cloudfront.net/img/xclose-white.svg"}
              onClick={this.props.onClose}
            />
          </X_CONTAINER>
        </CARD_HEARD>
        <MESSAGE>{this.state.message}</MESSAGE>
        <INPUT_CONTAINER>
          <TEXT_SEARCH_INPUT
            placeholder={"List Name"}
            value={this.state.newListName}
            onChange={(event: any) => {
              this.setState({ newListName: event.target.value });
            }}
            color={"black"}
          />
        </INPUT_CONTAINER>
        <BUTTON_CONTAINER>
          <BUTTON_TEXT
            onClick={() => {
              this.createNewListHelper();
            }}
          >
            Save
          </BUTTON_TEXT>
        </BUTTON_CONTAINER>
        <CANCEL_CONTAINER>
          <CANCEL onClick={this.props.onClose}>Cancel</CANCEL>
        </CANCEL_CONTAINER>
      </CARD>
    );
  }
}

const mapStateToProps = (state: RootState): MappedStateProps => ({
  user: currentUser(state) as UserVO,
  projectId: getSelectedProject(state) as string
});

const mapDispatchToProps: DispatchProps = {
  getList: getListForUser.request,
  getListOption: getListOptions.request,
  createNewGrowler: createGrowler,
  createNewList: createList.request
};

export const CreateNewListModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewListModalClass);
