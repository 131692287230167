import {
  createAsyncAction,
  createStandardAction,
  ActionType
} from "typesafe-actions";
import { ProjectFeatures } from "@h1eng/interfaces";

export const getProjectsForUser = createAsyncAction(
  "projects/request",
  "projects/success",
  "projects/failure"
)<string, string[], Error>();

export const selectProjectId = createStandardAction("projects/selectId")<
  string
>();

export const getProjectFeatures = createAsyncAction(
  "projects/features/request",
  "projects/features/success",
  "projects/features/error"
)<never, ProjectFeatures[], Error>();

export type GetProjectsAction = ActionType<typeof getProjectsForUser>;
export type SelectProjectAction = ActionType<typeof selectProjectId>;

export type ProjectsAction =
  | GetProjectsAction
  | SelectProjectAction
  | ActionType<typeof getProjectFeatures>;
