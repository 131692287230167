import { styled, B3, themeColors } from "@h1eng/ui-components";
import * as React from "react";
import OutsideAlerter from "../../OutsideAlerter";
import { SubViews, subviewPaths } from "./Profile";

const OPTIONS = styled.div`
  background-color: #f6f6f6;
  overflow: hidden;
  overflow-y: auto;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
  position: inherit;
`;
const OPTION = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${themeColors.themeBodyDefualt}
  padding: 10px;


`;

const DropDownContainer = styled.div`
  display: flex;
  z-index: 100000;
  flex-direction: column;
`;

const StyledActionSelector = styled.div`
  border-bottom: 1px solid black;
`;
const StyledActionSelectorStyled = styled.div``;

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
  margin-left: 10px;
`;
const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  margin-left: 10px;
`;
const ActionsBarContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

const SelectedOption = styled.div`
  border-bottom: solid 2px #80d0e1;
  font-size: 12px;
  height: 25px;
`;
const SelectedOptionNoBottom = styled.div`
  font-size: 12px;
  height: 25px;
`;
const Path = styled.div`
  margin-left: 10px;
  :hover {
    background-color: #eeeeee;
    cursor: pointer;
    cursor: hand;
  }
  padding-right: 10px;
`;
const Veiw = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  padding-right: 20px;
`;

const DropDownText = styled.div`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 12px;
  color: #333333;
`;
const DropDownTextOpen = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  color: #333333;
`;
export class ProfileSectionSelector extends React.Component<
  {
    paths: { path: SubViews; title: string }[];
    onChoose: any;
    selected: SubViews;
  },
  any
  > {
  state = {
    open: false,
    subpath: "",
    vewing: "Test"
  };
  toggleOpen() {
    this.setState({ open: !this.state.open });
  }

  render() {
    const ActionSelector = this.state.open
      ? StyledActionSelector
      : StyledActionSelectorStyled;

    const selectedOptionPicker = !this.state.open ? (
      <DropDownText>
        {subviewPaths[this.props.selected].title.toUpperCase()}
      </DropDownText>
    ) : (
        <DropDownText>
          {subviewPaths[this.props.selected].title.toUpperCase()}
        </DropDownText>
      );
    const Selected = this.state.open ? SelectedOptionNoBottom : SelectedOption;
    return (
      <div
        style={{
          zIndex: 400000,
          display: "flex",
          justifyContent: "center",
          height: "40px",
          maxHeight: "40px",
          paddingTop: "20px",
          cursor: "pointer"
        }}
      >
        <OutsideAlerter callBack={() => this.setState({ open: false })}>
          <DropDownContainer>
            <ActionSelector onClick={() => this.toggleOpen()}>
              <ActionsBarContainer>
                <Veiw>Viewing:</Veiw>
                <Selected>{selectedOptionPicker}</Selected>
                {this.state.open ? <ArrowDown /> : <ArrowUp />}
              </ActionsBarContainer>
            </ActionSelector>
            {this.state.open && this.renderOpen()}
          </DropDownContainer>
        </OutsideAlerter>
      </div>
    );
  }
  renderOpen() {
    const inner = this.props.paths.map(
      (e: { path: SubViews; title: string }) => (
        <Path
          onClick={() =>
            this.props.onChoose(e.path) || this.setState({ open: false })
          }
        >
          {<OPTION>{subviewPaths[e.path].title}</OPTION>}
        </Path>
      )
    );
    return <OPTIONS>{inner}</OPTIONS>;
  }
}
