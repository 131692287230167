import * as React from "react";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";

type Without<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type ThemedTooltipProps = Without<TooltipProps, "children" | "title">;

const styles = (theme: Theme) =>
  createStyles({
    tooltip: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontSize: 12
    },
    arrowPopper: arrowGenerator(theme.palette.primary.main),
    arrow: {
      position: "absolute",
      fontSize: 6,
      width: "3em",
      height: "3em",

      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid"
      }
    }
  });

type Props = TooltipProps & WithStyles<typeof styles>;

const ThemedTooltipComponent: React.FunctionComponent<Props> = ({
  classes,
  title,
  children,
  ...props
}) => {
  const [arrowRef, setArrowRef] = React.useState(
    null as null | HTMLSpanElement
  );

  return (
    <Tooltip
      title={
        <React.Fragment>
          {title}
          <span className={classes.arrow} ref={setArrowRef} />
        </React.Fragment>
      }
      classes={{ popper: classes.arrowPopper, tooltip: classes.tooltip }}
      PopperProps={{
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: Boolean(arrowRef),
              element: arrowRef
            }
          }
        }
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export { TooltipProps };

export const ThemedTooltip = withStyles(styles)(ThemedTooltipComponent);

export default ThemedTooltip;

function arrowGenerator(color: string) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.95em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${color} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.95em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${color} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.95em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${color} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.95em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${color}`
      }
    }
  };
}
