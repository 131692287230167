import * as React from "react";
import { connect } from "react-redux";
import { FilterSection } from "../FilterSection";
import { RootState } from "../../../../../store/reducers";
import { getTrialsFiltersCount } from "../../../../../store/selectors";
import { TextFilterWrapper } from "../../components/TextFilterWrapper";
import { TrialsMinCount } from "./TrialsMinCount";
import { StatusFilters } from "./StatusFilters";
import { PhaseFilters } from "./PhaseFilters";
import { StudyTypeFilters } from "./StudyTypeFilters";
// import { FunderTypeFilters } from "./FunderTypeFilters";
// import { SponsorFilters } from "./SponsorFilters";

interface MappedStateProps {
  filterCount: number;
}

type Props = MappedStateProps;

function TrialsComponent(props: Props) {
  return (
    <FilterSection
      id={"Clinical-Trial-DropDown"}
      sectionKey="trials"
      title="Clinical Trials"
      count={props.filterCount}
    >
      <TextFilterWrapper>
        <TrialsMinCount />
      </TextFilterWrapper>
      <TextFilterWrapper>
        <StatusFilters />
      </TextFilterWrapper>
      <TextFilterWrapper>
        <PhaseFilters />
      </TextFilterWrapper>
      <TextFilterWrapper>
        <StudyTypeFilters />
      </TextFilterWrapper>
      {/* <TextFilterWrapper>
        <FunderTypeFilters />
      </TextFilterWrapper>
      <TextFilterWrapper>
        <SponsorFilters />
      </TextFilterWrapper> */}
    </FilterSection>
  );
}

const mapStateToProps = (state: RootState): MappedStateProps => ({
  filterCount: getTrialsFiltersCount(state)
});

export const Trials = connect(mapStateToProps)(TrialsComponent);
