import { getType } from "typesafe-actions";
import {
  Epic,
  ofType,
  ActionsObservable,
  StateObservable
} from "redux-observable";
import { switchMap, filter, catchError, map } from "rxjs/operators";
import { of, concat, from, pipe } from "rxjs";
import {
  loadCustomSortingOptions,
  createCustomSortingOption,
  editCustomSortingOption,
  deleteCustomSortingOption,
  setCustomSortingOptionsLoaded,
  setSortBy,
  SearchFilterAction
} from "../../actions/searchFilters";
import { RootState } from "../../reducers";
import {
  fetchSortOptions,
  createSortOption,
  editSortOption,
  deleteSortOption
} from "../../../apis/searchFilters";
import { createGrowler } from "../../actions";
import { getCurrentUserId } from "../../selectors";
import { GrowlerTypes, CustomSortBy } from "@h1eng/interfaces";

const fetchSortOptionsFlow: Epic<any, any, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(loadCustomSortingOptions.request),
    switchMap(() =>
      from(fetchSortOptions(getCurrentUserId(state$.value))).pipe(
        map(loadCustomSortingOptions.success),
        catchError(
          pipe(
            loadCustomSortingOptions.failure,
            of
          )
        )
      )
    )
  );

const createSortOptionFlow: Epic<any, any, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(createCustomSortingOption.request),
    switchMap(({ payload }) =>
      from(
        createSortOption(
          payload,
          state$.value.searchFilters.customSortingOptions,
          getCurrentUserId(state$.value)
        )
      ).pipe(
        map(createCustomSortingOption.success),
        catchError(
          pipe(
            createCustomSortingOption.failure,
            of
          )
        )
      )
    )
  );

const updateSortOptionFlow: Epic<any, any, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(editCustomSortingOption.request),
    switchMap(({ payload }) =>
      from(
        editSortOption(
          payload,
          state$.value.searchFilters.customSortingOptions,
          getCurrentUserId(state$.value)
        )
      ).pipe(
        map(editCustomSortingOption.success),
        catchError(
          pipe(
            editCustomSortingOption.failure,
            of
          )
        )
      )
    )
  );

const deleteSortOptionFlow: Epic<any, any, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(deleteCustomSortingOption.request),
    switchMap(({ payload }) =>
      from(
        deleteSortOption(
          payload,
          state$.value.searchFilters.customSortingOptions,
          getCurrentUserId(state$.value)
        )
      ).pipe(
        map(deleteCustomSortingOption.success),
        catchError(
          pipe(
            deleteCustomSortingOption.failure,
            of
          )
        )
      )
    )
  );

const handleSuccessGrowlerFlow: Epic<any, any, RootState> = (
  action$: ActionsObservable<SearchFilterAction>,
  state$
) =>
  action$.pipe(
    ofType(
      // @ts-ignore
      createCustomSortingOption.success,
      editCustomSortingOption.success,
      deleteCustomSortingOption.success
    ),
    switchMap(action =>
      of(
        createGrowler({
          title: "Custom Sorting",
          description: `Your custom sorting configuration has been successfully ${getActionTypeName(
            // @ts-ignore
            action
          )}`,
          growler: GrowlerTypes.success,
          titleIcon: "check"
        })
      )
    )
  );

const setOptionsLoadingFlow: Epic<any, any, RootState> = action$ =>
  action$.pipe(
    ofType(loadCustomSortingOptions.request),
    switchMap(() => of(setCustomSortingOptionsLoaded(false)))
  );

const unsetOptionsLoadingFlow: Epic<any, any, RootState> = action$ =>
  action$.pipe(
    ofType(loadCustomSortingOptions.success),
    switchMap(() => of(setCustomSortingOptionsLoaded(true)))
  );

const handleSetNewestCustomSortByFlow: Epic<any, any, RootState> = (
  action$,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(createCustomSortingOption.success),
    switchMap(({ payload }: { payload: CustomSortBy[] }) =>
      of(
        setSortBy(
          getLatestSortOption(payload) || state$.value.searchFilters.sortBy
        )
      )
    )
  );

function getActionTypeName(action: SearchFilterAction) {
  switch (action.type) {
    case getType(createCustomSortingOption.success): {
      return "created";
    }
    case getType(deleteCustomSortingOption.success): {
      return "deleted";
    }
    default: {
      return "edited";
    }
  }
}

function getLatestSortOption(options: CustomSortBy[]) {
  return [...options]
    .filter(i => Boolean(i.created))
    .sort((a, b) => b.created! - a.created!)[0];
}

export default [
  fetchSortOptionsFlow,
  createSortOptionFlow,
  updateSortOptionFlow,
  deleteSortOptionFlow,
  handleSuccessGrowlerFlow,
  setOptionsLoadingFlow,
  unsetOptionsLoadingFlow,
  handleSetNewestCustomSortByFlow
];
