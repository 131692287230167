import { isActionOf } from "typesafe-actions";
import {
  Epic,
  StateObservable,
  ofType,
  ActionsObservable
} from "redux-observable";
import {
  requestTrialStatusOptions,
  setTrialStatusQuery,
  requestTrialPhaseOptions,
  setTrialPhaseQuery,
  requestTrialStudyTypeOptions,
  setTrialStudyTypeQuery,
  requestTrialFunderTypeOptions,
  setTrialFunderTypeQuery,
  requestTrialSponsorOptions,
  setTrialSponsorQuery,
  resetInitialOptions,
  SearchFilterAction
} from "../../actions/searchFilters";
import { RootState } from "../../reducers";
import {
  debounceTime,
  switchMap,
  takeUntil,
  skip,
  map,
  filter,
  catchError
} from "rxjs/operators";
import { from, of, pipe } from "rxjs";
import { getOptions } from "../../../apis/searchFilters";
import { DEBOUNCE_TIME } from ".";

const setTrialStatusQueryFlow: Epic<SearchFilterAction, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(ofType(setTrialStatusQuery)).pipe(
    debounceTime(DEBOUNCE_TIME),
    switchMap(({ payload }) =>
      !!payload
        ? of(
            requestTrialStatusOptions.request(
              state$.value.searchFilters.trials.status
            )
          ).pipe(takeUntil(action$.pipe(skip(1))))
        : of(resetInitialOptions())
    )
  );
const getTrialStatusOptionsFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(requestTrialStatusOptions.request)),
    switchMap(({ payload }) =>
      from(
        getOptions(
          payload
          // state$.value.searchFilters.initialOptions.trials.status
        )
      ).pipe(
        map(requestTrialStatusOptions.success),
        catchError(
          pipe(
            requestTrialStatusOptions.failure,
            of
          )
        )
      )
    )
  );

const setTrialPhaseQueryFlow: Epic<SearchFilterAction, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(ofType(setTrialPhaseQuery)).pipe(
    debounceTime(DEBOUNCE_TIME),
    switchMap(({ payload }) =>
      !!payload
        ? of(
            requestTrialPhaseOptions.request(
              state$.value.searchFilters.trials.phase
            )
          ).pipe(takeUntil(action$.pipe(skip(1))))
        : of(resetInitialOptions())
    )
  );
const getTrialPhaseOptionsFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(requestTrialPhaseOptions.request)),
    switchMap(({ payload }) =>
      from(
        getOptions(
          payload
          // state$.value.searchFilters.initialOptions.trials.phase
        )
      ).pipe(
        map(requestTrialPhaseOptions.success),
        catchError(
          pipe(
            requestTrialPhaseOptions.failure,
            of
          )
        )
      )
    )
  );

const setTrialStudyTypeQueryFlow: Epic<SearchFilterAction, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(ofType(setTrialStudyTypeQuery)).pipe(
    debounceTime(DEBOUNCE_TIME),
    switchMap(({ payload }) =>
      !!payload
        ? of(
            requestTrialStudyTypeOptions.request(
              state$.value.searchFilters.trials.studyType
            )
          ).pipe(takeUntil(action$.pipe(skip(1))))
        : of(resetInitialOptions())
    )
  );
const getTrialStudyTypeOptionsFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(requestTrialStudyTypeOptions.request)),
    switchMap(({ payload }) =>
      from(
        getOptions(
          payload
          // state$.value.searchFilters.initialOptions.trials.studyType
        )
      ).pipe(
        map(requestTrialStudyTypeOptions.success),
        catchError(
          pipe(
            requestTrialStudyTypeOptions.failure,
            of
          )
        )
      )
    )
  );

const setTrialFunderTypeQueryFlow: Epic<SearchFilterAction, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(ofType(setTrialFunderTypeQuery)).pipe(
    debounceTime(DEBOUNCE_TIME),
    switchMap(({ payload }) =>
      !!payload
        ? of(
            requestTrialFunderTypeOptions.request(
              state$.value.searchFilters.trials.funderType
            )
          ).pipe(takeUntil(action$.pipe(skip(1))))
        : of(resetInitialOptions())
    )
  );
const getTrialFunderTypeOptionsFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(requestTrialFunderTypeOptions.request)),
    switchMap(({ payload }) =>
      from(
        getOptions(
          payload
          // state$.value.searchFilters.initialOptions.trials.funderType
        )
      ).pipe(
        map(requestTrialFunderTypeOptions.success),
        catchError(
          pipe(
            requestTrialFunderTypeOptions.failure,
            of
          )
        )
      )
    )
  );

const setTrialSponsorQueryFlow: Epic<SearchFilterAction, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(ofType(setTrialSponsorQuery)).pipe(
    debounceTime(DEBOUNCE_TIME),
    switchMap(({ payload }) =>
      !!payload
        ? of(
            requestTrialSponsorOptions.request(
              state$.value.searchFilters.trials.sponsor
            )
          ).pipe(takeUntil(action$.pipe(skip(1))))
        : of(resetInitialOptions())
    )
  );
const getTrialSponsorOptionsFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(requestTrialSponsorOptions.request)),
    switchMap(({ payload }) =>
      from(
        getOptions(
          payload
          // state$.value.searchFilters.initialOptions.trials.sponsor
        )
      ).pipe(
        map(requestTrialSponsorOptions.success),
        catchError(
          pipe(
            requestTrialSponsorOptions.failure,
            of
          )
        )
      )
    )
  );

export default [
  setTrialStatusQueryFlow,
  getTrialStatusOptionsFlow,
  setTrialPhaseQueryFlow,
  getTrialPhaseOptionsFlow,
  setTrialStudyTypeQueryFlow,
  getTrialStudyTypeOptionsFlow,
  setTrialFunderTypeQueryFlow,
  getTrialFunderTypeOptionsFlow,
  setTrialSponsorQueryFlow,
  getTrialSponsorOptionsFlow
];
