/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { connect } from "react-redux";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import { TagInterface } from "@h1eng/interfaces";
import { RootState } from "../../../../store/reducers";
import {
  requestTagOptions,
  addTagToPerson,
  createAndAddTagToPerson
} from "../../../../store/actions/tags";
import {
  getCurrentTagsForPerson,
  createTagsPermission
} from "../../../../store/selectors/tagSelectors";
import { filterTags, TagOption } from "./lib/filterTags";
import {
  getOptionsAndNameList,
  isValidTagName
} from "./lib/tagValidationHelpers";

const MAX_HEIGHT = 256;

const styles = (theme: Theme) =>
  createStyles({
    iconButton: {
      padding: 7
    },
    menuPopper: {
      maxWidth: 340,
      width: "100%",
      zIndex: 9,
      maxHeight: MAX_HEIGHT
    },
    textFieldWrapper: {
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 12
    },
    addButtonWrapper: {
      display: "flex",
      flexGrow: 1,
      flexShrink: 0,
      flexBasis: "auto",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 2,
      paddingBottom: 16
    },
    withLabelButton: {
      // paddingLeft: 0
    },
    menuItem: {
      height: "auto",
      whiteSpace: "unset",
      wordBreak: "break-word"
    }
  });

interface ComponentProps {
  personId: string;
  withLabel?: boolean;
}

interface MappedStateProps {
  loading: boolean;
  options: TagOption[];
  selectedTags: TagInterface[];
  createPermission: boolean;
}

interface DispatchProps {
  fetchOptions: () => void;
  addTag: (props: { personId: string; tagId: string }) => void;
  createTag: (props: { personId: string; name: string }) => void;
}

type Props = ComponentProps &
  MappedStateProps &
  DispatchProps &
  WithStyles<typeof styles>;

type AnchorEl = EventTarget & HTMLElement;

type TagOptionWithStatus = TagOption & { disabled: boolean };

function addStatusToOptions(options: TagOption[], selected: TagOption[]) {
  return options.map(option => {
    const disabled = Boolean(selected.find(i => i.id === option.id));

    return {
      ...option,
      disabled
    };
  });
}

const TagManagerButtonComponent: React.FunctionComponent<Props> = props => {
  const [anchorEl, setAnchorEl] = React.useState(null as AnchorEl | null);
  const [inputVal, setInputVal] = React.useState("");

  const handleOpen = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // Use timeout to account for <Grow /> CSS transition
    setTimeout(() => {
      updateInputVal("");
    }, 300);
  };

  const handleSelect = (tagId: string) => () => {
    props.addTag({ tagId, personId: props.personId });
    handleClose();
  };

  React.useEffect(() => {
    if (!props.loading && !props.options.length) {
      props.fetchOptions();
    }
  }, []);

  function updateInputVal(val: string) {
    setInputVal(val);
  }

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { value } = e.target;
    updateInputVal(value);
  };

  const handleCreateTag = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    props.createTag({ name: inputVal, personId: props.personId });
    handleClose();
  };

  const { names, options: validOptions } = getOptionsAndNameList(props.options);

  const tagOptions: TagOptionWithStatus[] = addStatusToOptions(
    inputVal.length ? filterTags(inputVal, validOptions) : validOptions,
    props.selectedTags
  );

  const button = props.withLabel ? (
    <Button
      color="default"
      onClick={handleOpen}
      className={props.classes.withLabelButton}
    >
      <AddIcon fontSize="default" color="secondary" />
      &nbsp;Add Tag
    </Button>
  ) : (
    <IconButton
      color="secondary"
      className={props.classes.iconButton}
      onClick={handleOpen}
    >
      <AddIcon fontSize="default" color="inherit" />
    </IconButton>
  );

  const hasError =
    !props.createPermission || !isValidTagName({ inputVal, names });

  return (
    <>
      {button}
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        className={props.classes.menuPopper}
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              maxHeight: MAX_HEIGHT,
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper style={{ maxHeight: MAX_HEIGHT }}>
              <ClickAwayListener onClickAway={handleClose}>
                <div style={{ maxHeight: MAX_HEIGHT, overflow: "auto" }}>
                  <div className={props.classes.textFieldWrapper}>
                    <TextField
                      onChange={handleInputChange}
                      inputProps={{
                        maxLength: 50,
                        autoCapitalize: "on"
                      }}
                      value={inputVal}
                      fullWidth
                      label={<em>Tag Name</em>}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ pointerEvents: "none" }}
                          >
                            <SearchIcon color="primary" />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                  <MenuList>
                    {props.loading && (
                      <MenuItem key={`option-loading-${props.personId}`}>
                        <em>Loading...</em>
                      </MenuItem>
                    )}
                    {!props.loading &&
                      tagOptions
                        .filter(opt => inputVal.length > 0 || !opt.disabled)
                        .map(opt => (
                          <MenuItem
                            className={props.classes.menuItem}
                            disabled={opt.disabled}
                            key={`option-${props.personId}-${opt.id}-${
                              opt.disabled ? "disabled" : "enabled"
                            }`}
                            onClick={
                              !opt.disabled ? handleSelect(opt.id) : undefined
                            }
                          >
                            {opt.name}
                          </MenuItem>
                        ))}
                  </MenuList>
                  {!hasError && (
                    <div className={props.classes.addButtonWrapper}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleCreateTag}
                      >
                        Add New Tag
                      </Button>
                    </div>
                  )}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const mapStateToProps = (
  state: RootState,
  ownProps: ComponentProps
): MappedStateProps => ({
  options: state.tags.options,
  loading: state.tags.optionsLoading,
  selectedTags: getCurrentTagsForPerson(ownProps.personId, [])(state),
  createPermission: createTagsPermission(state)
});

const mapDispatchToProps: DispatchProps = {
  fetchOptions: requestTagOptions.request,
  addTag: addTagToPerson.request,
  createTag: createAndAddTagToPerson.request
};

export const TagManagerButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TagManagerButtonComponent));
