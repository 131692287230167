import { CustomSortBy, CustomSortByConstructor } from "@h1eng/interfaces";
import * as uniqid from "uniqid";
import { ENDPOINTS } from "../api-endpoints";

export const fetchSortOptions = async (
  userId: string
): Promise<CustomSortBy[]> => {
  const res = await fetch(
    `${ENDPOINTS.searchFilters.customSorting}?userId=${userId}`,
    {
      method: "GET",
      ...createCustomSortingPayload(userId)
    }
  );

  return res.json();
};

export const createSortOption = async (
  newOption: CustomSortByConstructor,
  options: CustomSortBy[],
  userId: string
): Promise<CustomSortBy[]> => {
  const customSorting = [
    {
      ...newOption,
      id: uniqid.time()
    },
    ...options
  ];

  const res = await postCustomSorting(
    createCustomSortingPayload(userId, customSorting)
  );

  return res;
};

export const editSortOption = async (
  newOption: CustomSortBy,
  options: CustomSortBy[],
  userId: string
): Promise<CustomSortBy[]> => {
  const untouched = options.filter(i => i.id !== newOption.id);

  const customSorting = [newOption, ...untouched];

  const res = await postCustomSorting(
    createCustomSortingPayload(userId, customSorting)
  );

  return res;
};

export const deleteSortOption = async (
  toDelete: string,
  options: CustomSortBy[],
  userId: string
): Promise<CustomSortBy[]> => {
  const customSorting = options.filter(i => i.id !== toDelete);

  const res = await postCustomSorting(
    createCustomSortingPayload(userId, customSorting)
  );

  return res;
};

function createCustomSortingPayload(
  userId: string,
  customSorting?: CustomSortBy[]
) {
  const res: Partial<RequestInit> = {
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include"
  };

  if (customSorting) {
    res.body = JSON.stringify({
      userId,
      customSorting
    });
  }

  return res;
}

async function postCustomSorting(payload: Partial<RequestInit>) {
  const res = await fetch(ENDPOINTS.searchFilters.customSorting, {
    method: "POST",
    ...payload
  });

  return res.json();
}
