/* tslint:disable:cyclomatic-complexity */
import { Middleware } from "redux";
import { getType } from "typesafe-actions";
// import { RootState } from "../../reducers/index";
import { sendAnalytics } from "./analytics";
import { search, setSearchView } from "./analyticsPayloadBuilders";
import {
  userSearchElastic,
  userSetSearchView,
  userSetPageNum,
  userClearLoadingMessage,
  userSetQuery,
  userSearchSubDocumentsElastic
} from "../../actions/userInteractionActions";

export const AnalyticsMiddleware: Middleware = store => next => action => {
  // const state: RootState = store.getState();
  switch (action.type) {
    case getType(userSearchElastic): {
      sendAnalytics(action.type, search(action));
      break;
    }

    case getType(userSetSearchView): {
      sendAnalytics(action.type, setSearchView(action));
      break;
    }

    case getType(userSetPageNum): {
      sendAnalytics(action.type, { pageNumber: action.payload });
      break;
    }

    case getType(userClearLoadingMessage): {
      sendAnalytics(action.type, { clearMessage: "true" });
      break;
    }

    case getType(userSetQuery): {
      sendAnalytics(action.type, { query: action.payload });
      break;
    }
  }

  return next(action);
};
