import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import SortIcon from "@material-ui/icons/Sort";
import { connect } from "react-redux";
import { setCustomSortModalOpen } from "../../../../../store/actions";
import { RootState } from "../../../../../store/reducers";
import { SearchTypes } from "@h1eng/interfaces";
import ThemedTooltip from "../../../common/ThemedTooltip";

interface MappedStateProps {
  loaded: boolean;
  searchType: SearchTypes;
}

interface DispatchProps {
  setModalOpen: (open: boolean) => void;
}

const CustomSortButtonComponent: React.FunctionComponent<
  MappedStateProps & DispatchProps
> = props => {
  const disabled = !props.loaded; // || props.searchType === SearchTypes.NAME;

  return (
    <ThemedTooltip title="Manage Custom Sorting">
      <div>
        <IconButton
          disabled={disabled}
          onClick={() => props.setModalOpen(true)}
        >
          <SortIcon color="inherit" fontSize="small" />
        </IconButton>
      </div>
    </ThemedTooltip>
  );
};

const mapStateToProps = (state: RootState): MappedStateProps => ({
  loaded: state.searchFilters.customSortingOptionsLoaded,
  searchType: state.searchFilters.searchType
});

const mapDispatchToProps: DispatchProps = {
  setModalOpen: setCustomSortModalOpen
};

export const CustomSortButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomSortButtonComponent);
