import { createStandardAction } from "typesafe-actions";
import {
  FilterSearchInterface,
  SearchView,
  GenericSearchInterface
} from "@h1eng/interfaces";

/**
 * Explicit user interactions; these should only be dispatched in components.
 *
 * Naming convention should be `user.${reduxStoreKey}.${...action}`
 */

// Search Actions
export const userSearchElastic = createStandardAction("user.search.search")<
  FilterSearchInterface
>();

export const userSetSearchView = createStandardAction(
  "user.search.setSearchView"
)<SearchView>();

export const userSetPageNum = createStandardAction(
  "user.search.results.setPageNum"
)<number>();

export const userClearLoadingMessage = createStandardAction(
  "user.search.loadingMessage.dismiss"
)<never>();

export const userSearchSubDocumentsElastic = createStandardAction(
  "user.search.subDocuments.search"
)<GenericSearchInterface>();

export const userSetQuery = createStandardAction("user.search.query.set")<
  string
>();
