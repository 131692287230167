import {
  createAsyncAction,
  ActionType,
  createStandardAction
} from "typesafe-actions";
import {
  FilterSearchInterface,
  GenericSearchInterface,
  GenericSearchResultInterface,
  ScoredDocumentResults,
  SearchView
} from "@h1eng/interfaces";

export const searchElastic = createAsyncAction(
  "search/request",
  "search/success",
  "search/failure"
)<
  FilterSearchInterface,
  {
    totalHits: number;
    pageNum: number;
    pageSize: number;
    scoredDocuments: ScoredDocumentResults;
  },
  Error
>();

export const searchSubDocumentsElastic = createAsyncAction(
  "searchDocuments/request",
  "searchDocuments/success",
  "searchDocuments/failure"
)<GenericSearchInterface, GenericSearchResultInterface[], Error>();

export const setPageNum = createStandardAction("search/setPageNum")<number>();

export const setPageNumWithoutSideEffects = createStandardAction(
  "search/setPageNum/noSideEffects"
)<number>();

export const setSearchLoading = createStandardAction("search/setLoading")<
  boolean
>();
export const setSearchView = createStandardAction("search/setSearchView")<
  SearchView
>();

export const displayLoadingMessage = createStandardAction(
  "search/loadingMessage.display"
)<never>();

export const clearLoadingMessage = createStandardAction(
  "search/loadingMessage.clear"
)<never>();

export type SearchAction =
  | ActionType<typeof searchElastic>
  | ActionType<typeof setSearchView>
  | ActionType<typeof searchSubDocumentsElastic>
  | ActionType<typeof setPageNum>
  | ActionType<typeof setPageNumWithoutSideEffects>
  | ActionType<typeof setSearchLoading>
  | ActionType<typeof displayLoadingMessage>
  | ActionType<typeof clearLoadingMessage>;
