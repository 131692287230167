import { RootState } from "../reducers";
import { createSelector } from "reselect";
import { getProjectFeatures } from "./projectSelectors";

const getState = (state: RootState) => state;

export const getCurrentTagsForPerson = (
  personId: string,
  nullValue: any = null
) =>
  createSelector(
    getState,
    state => state.tags.currentTags[personId] || nullValue
  );

export const createTagsPermission = createSelector(
  getState,
  state => {
    const feature = getProjectFeatures(state);
    if (!feature) return false;

    return feature.createTags;
  }
);
