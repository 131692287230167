import { combineReducers } from "redux";
import { persistReducer, PersistPartial, PersistState } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { merge } from "lodash";
import { reducersObj, RootState } from "./reducers";
import { searchInitialState, SearchState } from "./reducers/search";
import {
  searchFilterInitialState,
  SearchFilterState
} from "./reducers/searchFilterReducer";
import {
  userInfoRequestsInitialState,
  UserInfoRequestState
} from "./reducers/userInfoRequestReducer";
import {
  dataExportsInitialState,
  DataExportsState
} from "./reducers/dataExportsReducer";

const STORAGE_KEY = process.env.STORAGE_KEY || "saved";

const stateReconciler = (
  initialState:
    | SearchState
    | SearchFilterState
    | UserInfoRequestState
    | DataExportsState
) => (inboundState: any) => merge(initialState, inboundState);

const rootPersistConfig = {
  key: STORAGE_KEY,
  storage,
  blacklist: [
    "config",
    "kolCards",
    "searchResults",
    "searchFilters",
    "growlers",
    "userInfoRequest",
    "dataExports",
    "tags",
    "documentSearchBar"
  ]
};

const searchResultsPersistConfig = {
  key: "searchResults",
  storage,
  whitelist: ["documentSearchResults"],
  stateReconciler: stateReconciler(searchInitialState)
};

const searchFiltersPersistConfig = {
  key: "searchFilters",
  storage,
  blacklist: [
    "initialOptions",
    "initialOptionsLoaded",
    "customSortingOptions",
    "customSortingOptionsLoaded",
    "customSortModalOpen",
    "sortBy",
    "filterCompletionQueryLoading"
  ],
  stateReconciler: stateReconciler(searchFilterInitialState)
};

const userInfoRequestPersistConfig = {
  key: "userInfoRequest",
  storage,
  blacklist: ["requestedKol", "requestKolData"],
  stateReconciler: stateReconciler(userInfoRequestsInitialState)
};

export type PersistedRootState = RootState & {
  _persist: PersistState;
  searchResults: RootState["searchResults"] & PersistPartial;
  searchFilters: RootState["searchFilters"] & PersistPartial;
  userInfoRequest: RootState["userInfoRequest"] & PersistPartial;
  dataExports: RootState["dataExports"] & PersistPartial;
};

const dataExportsPersistConfig = {
  key: "dataExports",
  storage,
  blacklist: ["kols"],
  stateReconciler: stateReconciler(dataExportsInitialState)
};

export default persistReducer(
  rootPersistConfig,
  combineReducers({
    ...reducersObj,
    searchResults: persistReducer(
      searchResultsPersistConfig,
      reducersObj.searchResults
    ),
    searchFilters: persistReducer(
      searchFiltersPersistConfig,
      reducersObj.searchFilters
    ),
    userInfoRequest: persistReducer(
      userInfoRequestPersistConfig,
      reducersObj.userInfoRequest
    ),
    dataExports: persistReducer(
      dataExportsPersistConfig,
      reducersObj.dataExports
    )
  })
);
