import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    root: {}
  });

interface ComponentProps {
  pageNum: number;
  lastPage: number;
  handlePageChange: (
    pageNum: number
  ) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

type Props = ComponentProps & WithStyles<typeof styles>;

const NavigationButtonsComponent: React.FunctionComponent<Props> = ({
  pageNum,
  lastPage,
  handlePageChange,
  classes
}) => {
  return (
    <div className={classes.root}>
      <IconButton
        size="small"
        onClick={handlePageChange(0)}
        disabled={pageNum === 0}
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handlePageChange(pageNum - 1)}
        disabled={pageNum === 0}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handlePageChange(pageNum + 1)}
        disabled={pageNum === lastPage}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handlePageChange(lastPage)}
        disabled={pageNum === lastPage}
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

export const NavigationButtons = withStyles(styles)(NavigationButtonsComponent);

export default NavigationButtons;
