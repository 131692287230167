export const getItemRangeInPage = ({
  pageNum,
  pageSize,
  count
}: {
  pageNum: number;
  pageSize: number;
  count: number;
}): [number, number] => {
  const start = pageNum * pageSize + 1;

  let end = start + pageSize - 1;

  if (end >= count) end = count;

  return [start, end];
};
