import * as React from "react";
import { connect } from "react-redux";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import { getInitialOptionsLoaded } from "../../../store/selectors";
import { RootState } from "../../../store/reducers";
import { Autosuggest, AutosuggestComponentProps } from "../common/Autosuggest";

interface MappedStateProps {
  disabled: boolean;
  loading: boolean;
}

const styles = createStyles({
  inputLabel: {
    fontSize: 14,
    color: "#435d6b"
  }
});

interface ComponentProps {
  /**
   * Allows for overriding of the loading prop retrieved from Redux
   */
  customLoading?: boolean;
}

type Props = ComponentProps &
  MappedStateProps &
  AutosuggestComponentProps &
  WithStyles<typeof styles>;

type FilterSet = [string, string];

export const filteredCharacters: FilterSet[] = [["â€“", "—"]];

const optionTextFormatter = (text: string) => {
  let res: string = text;
  let regex: RegExp;

  for (const [match, replace] of filteredCharacters) {
    regex = new RegExp(match);

    res = res.replace(regex, replace);
  }

  return res;
};

const FilterAutosuggestComponent: React.FunctionComponent<Props> = ({
  classes,
  inputLabel,
  loading,
  customLoading,
  ...props
}: Props) => {
  const [initialLoading, setInitialLoading] = React.useState(true);

  const loadTimeout = setTimeout(() => {
    setInitialLoading(false);
  }, 15000);

  React.useEffect(() => {
    if (initialLoading && !props.disabled) {
      clearTimeout(loadTimeout);
      setInitialLoading(false);
    }

    return () => {
      clearTimeout(loadTimeout);
    };
  });

  const isLoading = customLoading !== undefined ? customLoading : loading;

  return (
    <Autosuggest
      {...props}
      initialLoading={initialLoading}
      loading={isLoading}
      inputLabel={
        typeof inputLabel === "string" ? (
          <span className={classes.inputLabel}>{inputLabel}</span>
        ) : (
          inputLabel
        )
      }
      optionTextFormatter={optionTextFormatter}
    />
  );
};

const mapStateToProps = (state: RootState): MappedStateProps => ({
  disabled: !getInitialOptionsLoaded(state),
  loading: state.searchFilters.filterCompletionQueryLoading
});

export const FilterAutosuggest = connect(mapStateToProps)(
  withStyles(styles)(FilterAutosuggestComponent)
);
