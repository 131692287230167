import { TitlesAndAssociationInterface } from "./profileInterfaces";
import { ScoredDocumentData } from "./documentScores";

export type KOLType =
  | "Institutional Pedigree"
  | "Industry Partner"
  | "Rising Star"
  | "Research Scholar";

export interface WorksCount {
  searched: number;
  total: number;
}

export interface Works {
  publications: WorksCount;
  trials: WorksCount;
  congresses: WorksCount;
  /**
   * The count of grants; not the monetary amount
   */
  grants: WorksCount;
}

export interface ResultCardAffiliation {
  titles: string[];
  department: string | null;
  school: string | null;
  institution: {
    id: string;
    name: string;
  };
  address: {
    id: string;
    street1: string | null;
    country: string | null;
    street2: string | null;
    street3: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
  } | null;
}

export interface ResultCardData {
  personId: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  affiliations: ResultCardAffiliation[];
  score: number;
  trendHistory: number[];
  trendPresent: string;
  countPublications: number;
  countClinicalTrials: number;
  sumPayments: number;
  congresses: number;
  grants: number;
  sumGrants: number;
  specialty: string[];
  grantDates?: number[];
  congressesDates: number[];
  paymentDates: number[];
  publicationDates: number[];
  trailDates: number[];
  citationCount: number;
  citationCountAvg: number;
  kolDataRequests: KOLDataRequest[];
  infoRequestsResolved: boolean | null;
  tags: TagInterface[];
}

export interface TagInterface {
  id: string;
  name: string;
  isH1: boolean;
  created: Date;
  updated: Date;
  active: boolean;
  userEmail: string | null;
}

export interface KOLDataRequest {
  userId: string;
  projectId: string;
  personId: string;
  created?: Date;
}

export interface PersonKOLDataRequests {
  personId: string;
  infoRequestsResolved: boolean | null;
  requests: KOLDataRequest[];
}

export type ScoredResultCardData = ResultCardData & {
  scoredDocuments: ScoredDocumentData;
};

export interface LiteProfiles {
  payload: LiteProfile[];
}
export interface LiteProfile {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumbers: string[];
  city: string;
  state: string;
  titles: string[];
  email: string[];
  designations: string[];
  titleAndAssociations: TitlesAndAssociationInterface[];
}

export enum CardViewOpt {
  SEARCH,
  SAVED_LISTS
}
