/* tslint:disable:cyclomatic-complexity*/
// tslint:disable-next-line:no-var-requires
import * as React from "react";
import * as _ from "lodash";
import {
  CombinedDocumentsType,
  PublicationInterface,
  CongressInterface,
  ClinicalTrialInterface,
  GenericSearchResultInterface,
  GrantInterface,
  OpenPaymentInterface
} from "@h1eng/interfaces";
import { GenericSearchEnum } from "@h1eng/interfaces";
import { CONGRESSCARD } from "./ProfileCongress";
import { PUBLICATION } from "./ProfilePublications";
import { CLINICALTRIALCARD } from "./ClinicalTrials/ClinicalTrials";
import { styled, H5 } from "@h1eng/ui-components";
import {
  getDocumentSearch,
  getDocumentSearchBarState,
  getDocumentSearchBarFilterDateDisplayText,
  getDocumentSearchLoading
} from "../../../store/selectors";
import { connect } from "react-redux";
import { GRANTCARD } from "./ProfileGrants";
import { LoadingState } from "./Loading";
import { NoResultsFound } from "./NoResultsFound";
import { Pagination } from "../common/Pagination";

const SORTCARD = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 2px solid rgb(246, 246, 246);
  padding-right: 20px;
  padding-left: 20px;
  height: 50px;
  align-items: center;
`;
const LAST5CARD = styled.div`
  display: flex;
  border-top: 2px solid rgb(246, 246, 246);
  padding-left: 20px;
  height: 50px;
  align-items: center;
`;

interface MultiRenderProps {
  docs: CombinedDocumentsType[];
  dateFilter: number;
  types?: GenericSearchEnum[];
  loading: boolean;
  personId: string;
}
interface MultiRenderStateInterface {
  page: number;
  listSize: number;
  order: string;
}
interface MultiRenderPropsFromDispatch {
  documentSearch: GenericSearchResultInterface[];
  searchBarState: { query: string[] };
  limitNoSearch: boolean;
  filterDateText: string;
}

export const switchDocumentDateSort = (doc: any) => {
  switch (doc.kind) {
    case GenericSearchEnum.PAYMENTS: {
      const payment = doc as OpenPaymentInterface;

      return new Date(payment.date);
    }
    case GenericSearchEnum.CONGRESS:
      return new Date(doc.presentedDate);
    case GenericSearchEnum.GRANTS: {
      const date = new Date();
      date.setFullYear(Number.parseInt(doc.fundingYear, 10), 10);
      return date;
    }
    case GenericSearchEnum.CLINICALTRIAL:
      return doc.startDate;
    case GenericSearchEnum.PUBLICATION: {
      if (!!doc.datePublished) {
        return new Date(doc.datePublished);
      }
      try {
        const date =
          doc.completionDate || doc.presentedDate || doc.datePublished;
        if (!date) return null;
        const d = new Date(date);
        return d;
      } catch (e) {
        return null;
      }
    }
    default:
      return null;
  }
};
type Props = MultiRenderPropsFromDispatch & MultiRenderProps;

export class MultiRenderClass extends React.Component<
  Props,
  MultiRenderStateInterface
> {
  private scrollRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      order: "Descending",
      page: 0,
      listSize: 15
    };
    this.scrollRef = React.createRef();
  }

  renderNoSearchBar() {
    return this.props.limitNoSearch ? (
      <LAST5CARD>
        <H5 deEmphasized>MOST RECENT ACTIVITIES</H5>
      </LAST5CARD>
    ) : (
      <div style={{ paddingRight: "20px" }}>
        {/* <ProfileSortBy
          sortOrder={(e: string) => {
            this.setState({ order: e });
          }}
        /> */}
      </div>
    );
  }

  renderSearchBar() {
    return (
      <>
        <H5 deEmphasized>ACTIVITIES WITH SEARCHED KEYWORDS</H5>
      </>
    );
  }

  filterActiveDocs(filterId = false) {
    const filterDate = new Date(this.props.dateFilter);

    let docs = this.props.docs.filter(
      i => i.kind !== GenericSearchEnum.PAYMENTS
    );

    if (!filterId) {
      docs = docs.filter(e => {
        const date = switchDocumentDateSort(e);
        if (this.props.filterDateText === "All time") return true;

        if (!date) return false;

        const docDate = new Date(date);
        return docDate.getTime() - filterDate.getTime() > 0;
      });
    }

    if (this.props.types) {
      docs = docs.filter(e => this.props.types!.includes(e.kind));
    }

    if (this.props.searchBarState.query.length) {
      const idsToInclude = this.props.documentSearch.map(e => e.id);
      docs = docs.filter(e => {
        return idsToInclude.includes(e.id);
      });
    }

    const sortBy = this.state.order === "Ascending" ? "asc" : "desc";

    docs = _.orderBy(
      docs,
      e => {
        const date = switchDocumentDateSort(e);
        if (!date) {
          const fakeDate = new Date();

          this.state.order === "Ascending"
            ? fakeDate.setFullYear(fakeDate.getFullYear() + 1)
            : fakeDate.setFullYear(fakeDate.getFullYear() - 100);

          return fakeDate;
        }
        return date;
      },
      sortBy
    );

    return docs;
  }

  getStart(): number {
    const { page, listSize } = this.state;
    return page * listSize + 1;
  }

  getEnd(filterId: boolean): number {
    const { page, listSize } = this.state;
    const size = page * listSize + listSize;
    if (this.filterActiveDocs().length < size) {
      return this.filterActiveDocs().length;
    }

    return size;
  }

  renderCongressCard(doc: CongressInterface, keyAppendString?: string) {
    return (
      <CONGRESSCARD
        {...doc}
        key={`congress-card-${doc.id}-${keyAppendString}`}
      />
    );
  }

  renderPublicationCard(doc: PublicationInterface, keyAppendString?: string) {
    return (
      <PUBLICATION
        {...doc}
        personId={this.props.personId}
        key={`publication-card-${doc.id}-${keyAppendString}`}
      />
    );
  }

  renderTrialCard(doc: ClinicalTrialInterface, keyAppendString?: string) {
    return (
      <CLINICALTRIALCARD
        {...doc}
        personId={this.props.personId}
        key={`trials-card-${doc.id}-${keyAppendString}`}
      />
    );
  }

  renderGrantCard(doc: GrantInterface, keyAppendString?: string) {
    return (
      <GRANTCARD {...doc} key={`grant-card-${doc.id}-${keyAppendString}`} />
    );
  }

  switchDocument(doc: any, keyAppendString?: string) {
    switch (doc.kind) {
      case GenericSearchEnum.CONGRESS:
        return this.renderCongressCard(
          doc as CongressInterface,
          keyAppendString
        );
      case GenericSearchEnum.CLINICALTRIAL:
        return this.renderTrialCard(
          doc as ClinicalTrialInterface,
          keyAppendString
        );
      case GenericSearchEnum.PUBLICATION:
        return this.renderPublicationCard(
          doc as PublicationInterface,
          keyAppendString
        );
      case GenericSearchEnum.GRANTS:
        return this.renderGrantCard(doc as GrantInterface, keyAppendString);
      default:
        return <></>;
    }
  }

  get pagination() {
    return (
      <div style={{ backgroundColor: "rgb(246, 246, 246)" }}>
        <Pagination
          pageNum={this.state.page}
          pageSize={this.state.listSize}
          total={this.filterActiveDocs().length}
          loadPage={(page: number) => {
            try {
              window.scrollTo(0, this.scrollRef.current!.offsetTop);
            } catch (e) {}
            this.setState({ page });
          }}
        />
      </div>
    );
  }

  renderSearch() {
    const renderSearchBardOrMessage = (
      <SORTCARD>{this.renderSearchBar()}</SORTCARD>
    );

    const docs = this.filterActiveDocs()
      .slice(this.getStart() - 1, this.getEnd(this.props.limitNoSearch))
      .map((e: any) => this.switchDocument(e, "search"));

    const selector = docs.length ? this.pagination : <></>;

    return (
      <div style={{ width: "100%" }}>
        {renderSearchBardOrMessage}
        {docs.length ? docs : <NoResultsFound />}
        {selector}
      </div>
    );
  }

  renderNoSearch() {
    const docs = this.filterActiveDocs();
    if (docs.length === 0) {
      return (
        <div style={{ width: "100%" }}>
          {this.renderNoSearchBar()}
          <NoResultsFound />
          {this.pagination}
        </div>
      );
    }

    const setOfDocs = docs.slice(
      this.getStart() - 1,
      this.getEnd(this.props.limitNoSearch)
    );

    return this.props.limitNoSearch ? (
      <div style={{ width: "100%" }}>
        {this.renderNoSearchBar()}
        {docs.slice(0, 5).map(e => this.switchDocument(e, "limit-no-search"))}
      </div>
    ) : (
      <div style={{ width: "100%" }}>
        {this.renderNoSearchBar()}
        {setOfDocs.map(e => this.switchDocument(e, "no-search"))}
        {setOfDocs.length > 0 && this.pagination}
      </div>
    );
  }

  renderLoading() {
    return <LoadingState />;
  }

  render() {
    const { loading } = this.props;

    // if (this.filterActiveDocs().length === 0) {
    //   return null;
    // }

    if (this.props.searchBarState.query.length) {
      const content = loading ? this.renderLoading() : this.renderSearch();
      return <div ref={this.scrollRef}>{content}</div>;
    }

    return <div ref={this.scrollRef}>{this.renderNoSearch()}</div>;
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.filterDateText !== this.props.filterDateText) {
      this.setState({
        page: 0
      });
    }

    if (nextProps.searchBarState.query !== this.props.searchBarState.query) {
      this.setState({ page: 0 });
    }
  }
}

const mapStateToProps = (state: any) => ({
  documentSearch: getDocumentSearch(state),
  searchBarState: getDocumentSearchBarState(state),
  filterDateText: getDocumentSearchBarFilterDateDisplayText(state),
  loading: getDocumentSearchLoading(state)
});

const mapDispatchToProps = {};

export const MultiRender = connect<
  MultiRenderPropsFromDispatch,
  {},
  MultiRenderProps
>(
  mapStateToProps,
  mapDispatchToProps
)(MultiRenderClass as any);
