import {
  searchElastic,
  searchSubDocumentsElastic,
  setDocumentSearchBarQuery,
  kolCardsActionGet,
  setPageNum,
  setPageNumWithoutSideEffects,
  createGrowler,
  setSearchLoading,
  displayLoadingMessage,
  clearLoadingMessage
} from "../actions";
import { getAnalyticsPayload } from "../selectors";
import { isActionOf } from "typesafe-actions";
import {
  Epic,
  StateObservable,
  ofType,
  ActionsObservable
} from "redux-observable";
import {
  filter,
  switchMap,
  mergeMap,
  map,
  catchError,
  takeUntil,
  delay,
  tap
} from "rxjs/operators";
import { from, of, pipe, concat } from "rxjs";
import { searchPeople, searchDocuments } from "../../apis/search";
import { RootState } from "../reducers";
import { paginate } from "@h1eng/pagination";
import { GrowlerTypes } from "@h1eng/interfaces";

export const setPageNumberFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf([setPageNum])),
    mergeMap(({ payload }) =>
      concat(
        of(
          kolCardsActionGet.request({
            personIds: paginate({
              items: state$.value.searchResults.scoredDocuments.persons,
              pageNum: payload,
              pageSize: state$.value.searchResults.pageSize
            }).map(i => i.personId),
            projectId: state$.value.projects.selectedId as string,
            userId: state$.value.user.user!.id
          })
        )
      )
    )
  );

export const searchFailureAlertFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(searchElastic.failure)),
    mergeMap(() =>
      concat(
        of(setSearchLoading(false)),
        of(
          createGrowler({
            title: "Error",
            description: "There was an error performing your search",
            titleIcon: "warning",
            growler: GrowlerTypes.fail
          })
        ),
        of(clearLoadingMessage())
      )
    )
  );

export const fetchSearchFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) => {
  return action$.pipe(
    filter(isActionOf(searchElastic.request)),
    switchMap(({ payload }) =>
      concat(
        of(setPageNumWithoutSideEffects(0)),
        from(searchPeople(payload)).pipe(
          mergeMap((data: any) => {
            const pageNum = payload.pageNum || 0;
            const pageSize = payload.pageSize || 15;

            const personIds = paginate({
              items: data.persons,
              pageNum,
              pageSize
            }).map(i => i.personId);

            return concat(
              // of(setSearchLoading(false)),
              of(
                searchElastic.success({
                  totalHits: data.totalHits,
                  pageNum,
                  pageSize,
                  scoredDocuments: data
                })
              ),
              of(
                kolCardsActionGet.request({
                  personIds,
                  userId: payload.userId,
                  projectId: payload.projectId
                })
              )
            );
          }),
          catchError(
            pipe(
              searchElastic.failure,
              of
            )
          )
        )
      )
    )
  );
};

export const fetchSearchDocumentsFlow: Epic<any, any, any> = action$ => {
  return action$.pipe(
    filter(isActionOf(searchSubDocumentsElastic.request)),
    switchMap(({ payload }) =>
      from(searchDocuments(payload)).pipe(
        map(searchSubDocumentsElastic.success),
        catchError(
          pipe(
            searchSubDocumentsElastic.failure,
            of
          )
        )
      )
    )
  );
};

export const refetchDocuments: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf([setDocumentSearchBarQuery])),
    mergeMap(({ payload: { personId, query } }) =>
      concat(
        of(
          searchSubDocumentsElastic.request({
            terms: query,
            personId
          })
        )
      )
    )
  );

export const searchLoadingMessageFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(searchElastic.request),
    switchMap(() =>
      of(displayLoadingMessage()).pipe(
        delay(5000),
        takeUntil(action$.pipe(ofType(clearLoadingMessage)))
      )
    )
  );

export const handleHeapAnalyticsFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(searchElastic.success),
    switchMap(() => of(sendAnalytics(state$.value)))
  );

function sendAnalytics(state: RootState) {
  const defaultResponse = { type: "", payload: "" };
  try {
    if (!window.heap) return defaultResponse;
    const payload = getAnalyticsPayload(state);

    if (payload.query !== "") {
      window.heap.track("Search", payload);
    }
  } catch (e) {
    // This is for analytics tracking; should not kill app on failure
  }

  return defaultResponse;
}
