import * as React from "react";
import { ResultCardAffiliation } from "@h1eng/interfaces";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import { HoverTooltip } from "../../../../Tooltip";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      cursor: "default",
      position: "relative",
      display: "inline-block",
      marginLeft: 10
    },
    ul: {
      marginBlockStart: 0,
      marginBlockEnd: 0,
      marginInlineEnd: 0,
      marginInlineStart: 0,
      paddingLeft: 0,
      listStyle: "none"
    },
    li: {
      whiteSpace: "nowrap",
      color: "#fff",
      fontSize: 14
    },
    label: {
      color: "#435D6B",
      fontWeight: 600,
      fontSize: 12
    }
  });

interface ComponentProps {
  affiliations: ResultCardAffiliation[];
}

type Props = ComponentProps & WithStyles<typeof styles>;

const OtherInstitutionsTooltipComponent: React.FunctionComponent<Props> = ({
  affiliations,
  classes
}) => {
  if (!affiliations || affiliations.length === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      <HoverTooltip
        style={{ display: "block", width: "fit-content", bottom: 25 }}
        content={
          <ul className={classes.ul}>
            <li style={{ fontWeight: "bold" }} className={classes.li}>
              Associated Institutions
            </li>
            {affiliations.map(affiliation => (
              <li key={affiliation.institution.id} className={classes.li}>
                {affiliation.institution.name}
              </li>
            ))}
          </ul>
        }
      >
        <div className={classes.label}>
          <span className={classes.label} style={{ width: "fit-content" }}>
            + {affiliations.length} more{" "}
            {affiliations.length === 1 ? "institution" : "institutions"}
          </span>
        </div>
      </HoverTooltip>
    </div>
  );
};

export const OtherInstitutionsTooltip = withStyles(styles)(
  OtherInstitutionsTooltipComponent
);
