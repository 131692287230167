import * as React from "react";
import { connect } from "react-redux";
import { getSearchFilters } from "../../../../store/selectors";
import { setTagNameValues, setTagNameQuery } from "../../../../store/actions";
import { RootState } from "../../../../store/reducers";
import { FilterAutosuggest as Autosuggest } from "../FilterAutosuggest";

interface MappedStateProps {
  options: string[];
  values: string[];
  inputValue: string;
  allOptionsLoading: boolean;
  tagsLoading: boolean;
}

interface DispatchProps {
  setValues: (values: string[]) => void;
  handleInputChange: (inputVal: string) => void;
}

type Props = MappedStateProps & DispatchProps;

class TagFiltersComponent extends React.Component<Props> {
  render() {
    const {
      options,
      values,
      setValues,
      allOptionsLoading,
      tagsLoading
    } = this.props;

    return (
      <Autosuggest
        values={values}
        setValues={setValues}
        suggestions={options}
        inputLabel="Tags"
        inputValue={this.props.inputValue}
        setInputValue={this.props.handleInputChange}
        customLoading={tagsLoading || allOptionsLoading}
      />
    );
  }
}

const mapStateToProps = (state: RootState): MappedStateProps => {
  const {
    tags: { name }
  } = getSearchFilters(state);

  return {
    options: name.options,
    values: name.values,
    inputValue: name.query,
    allOptionsLoading: state.searchFilters.filterCompletionQueryLoading,
    tagsLoading: state.searchFilters.filterOptionsKeysLoading.includes("tags")
  };
};

const mapDispatchToProps: DispatchProps = {
  setValues: setTagNameValues,
  handleInputChange: setTagNameQuery
};

export const TagFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(TagFiltersComponent);
