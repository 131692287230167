import { isActionOf } from "typesafe-actions";
import {
  Epic,
  StateObservable,
  ofType,
  ActionsObservable
} from "redux-observable";
import {
  requestCongressNameOptions,
  setCongressNameQuery,
  requestCongressOrganizerNameOptions,
  setCongressOrganizerNameQuery,
  requestCongressSessionTypeOptions,
  setCongressSessionTypeQuery,
  requestCongressTypeOptions,
  resetInitialOptions,
  SearchFilterAction
} from "../../actions/searchFilters";
import { RootState } from "../../reducers";
import {
  debounceTime,
  switchMap,
  takeUntil,
  skip,
  map,
  filter,
  catchError
} from "rxjs/operators";
import { from, of, pipe } from "rxjs";
import { getOptions } from "../../../apis/searchFilters";
import { DEBOUNCE_TIME } from ".";

const setCongressNameQueryFlow: Epic<SearchFilterAction, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(ofType(setCongressNameQuery)).pipe(
    debounceTime(DEBOUNCE_TIME),
    switchMap(({ payload }) =>
      !!payload
        ? of(
            requestCongressNameOptions.request(
              state$.value.searchFilters.congresses.name
            )
          ).pipe(takeUntil(action$.pipe(skip(1))))
        : of(resetInitialOptions())
    )
  );

const getCongressNameOptionsFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(requestCongressNameOptions.request)),
    switchMap(({ payload }) =>
      from(
        getOptions(
          payload
          // state$.value.searchFilters.initialOptions.congresses.name
        )
      ).pipe(
        map(requestCongressNameOptions.success),
        catchError(
          pipe(
            requestCongressNameOptions.failure,
            of
          )
        )
      )
    )
  );

const getCongressTypeOptionsFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(requestCongressTypeOptions.request)),
    switchMap(({ payload }) =>
      from(
        /**
         * Treated like TextFilter so API functionality can be the same
         */
        getOptions(
          { ...state$.value.searchFilters.congresses.type, query: "" }
          // state$.value.searchFilters.initialOptions.congresses.type
        )
      ).pipe(
        map(requestCongressTypeOptions.success),
        catchError(
          pipe(
            requestCongressTypeOptions.failure,
            of
          )
        )
      )
    )
  );

const setCongressOrganizerNameQueryFlow: Epic<SearchFilterAction, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(ofType(setCongressOrganizerNameQuery)).pipe(
    debounceTime(DEBOUNCE_TIME),
    switchMap(({ payload }) =>
      !!payload
        ? of(
            requestCongressOrganizerNameOptions.request(
              state$.value.searchFilters.congresses.organizerName
            )
          ).pipe(takeUntil(action$.pipe(skip(1))))
        : of(resetInitialOptions())
    )
  );

const getCongressOrganizerNameOptionsFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(requestCongressOrganizerNameOptions.request)),
    switchMap(({ payload }) =>
      from(
        getOptions(
          payload
          // state$.value.searchFilters.initialOptions.congresses.name
        )
      ).pipe(
        map(requestCongressOrganizerNameOptions.success),
        catchError(
          pipe(
            requestCongressOrganizerNameOptions.failure,
            of
          )
        )
      )
    )
  );

const setCongressSessionTypeQueryFlow: Epic<SearchFilterAction, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(ofType(setCongressSessionTypeQuery)).pipe(
    debounceTime(DEBOUNCE_TIME),
    switchMap(({ payload }) =>
      !!payload
        ? of(
            requestCongressSessionTypeOptions.request(
              state$.value.searchFilters.congresses.sessionType
            )
          ).pipe(takeUntil(action$.pipe(skip(1))))
        : of(resetInitialOptions())
    )
  );

const getCongressSessionTypeOptionsFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(requestCongressSessionTypeOptions.request)),
    switchMap(({ payload }) =>
      from(
        getOptions(
          payload
          // state$.value.searchFilters.initialOptions.congresses.name
        )
      ).pipe(
        map(requestCongressSessionTypeOptions.success),
        catchError(
          pipe(
            requestCongressSessionTypeOptions.failure,
            of
          )
        )
      )
    )
  );
export default [
  setCongressNameQueryFlow,
  getCongressNameOptionsFlow,
  getCongressTypeOptionsFlow,
  setCongressOrganizerNameQueryFlow,
  getCongressOrganizerNameOptionsFlow,
  setCongressSessionTypeQueryFlow,
  getCongressSessionTypeOptionsFlow
];
