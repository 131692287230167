import * as React from "react";
import {
  styled,
  B2,
  B1,
  PRIMARY_BUTTON,
  SECONDARY_INPUT
} from "@h1eng/ui-components";
import { ClickableTooltip } from "../../Tooltip";
import isNode from "../../../utils/isNode";
import { IconButton } from "../../IconButton";
import { connect } from "react-redux";
import {
  createList,
  addToList,
  ListManipulationInterface,
  removePeopleFromList
} from "../../../store/actions";
import { RootState } from "../../../store/reducers";
import {
  currentUser,
  getSelectedProject,
  getSelectedListId,
  getAvailableLists,
  getLists
} from "../../../store/selectors";
import * as _ from "lodash";
import { UserVO } from "../../../models/UserVO";
import { ListVO } from "../../../models/ListVO";
import { createGrowler } from "../../../store/actions/growler";
import { GrowlerTypes } from "../../growler/GrowlerTypes";
import { ListMeta } from "./ListManagementInterfaces";

const LIST_MANAGER_CONTAINER = styled.div`
  position: relative;
  display: inline-block;
`;

interface ListManagerProps {
  personId: string;
  active: boolean;
  firstName?: string;
  lastName?: string;
  //getList: (projectId: number, userId: string, personIds: string[]) => void;
}

interface ListManagerState {
  listName: string;
  tooltipOpen: boolean;
  showNewListForm: boolean;
  showNewListSuccess: boolean;
}

const images = isNode
  ? {}
  : {
      availableList:
        "https://d10ba5vkyum61s.cloudfront.net/img/list-management/available-list.svg",
      newList:
        "https://d10ba5vkyum61s.cloudfront.net/img/list-management/new-list.svg",
      saveSuccess:
        "https://d10ba5vkyum61s.cloudfront.net/img/list-management/save-success.svg",
      saveToList:
        "https://d10ba5vkyum61s.cloudfront.net/img/list-management/save-to-list.svg"
    };

const LIST_ACTION_BTN = styled.div`
  display: flex;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
`;

const DIVIDER = styled.hr`
  border: 0;
  height: 1px;
  background: #ccc;
`;

interface ListManagerProps {
  user: UserVO;
  projectId: string;
  selectedListId: string | null;
  availableLists: ListMeta[];
}

interface ListMangerPropsFromDispatch {
  createList: (list: ListVO) => void;
  addToList: (opts: ListManipulationInterface) => void;
}

type listMangerProsComp = ListManagerProps & ListMangerPropsFromDispatch;

export class ListManagerClass extends React.Component<
  listMangerProsComp,
  ListManagerState
> {
  state = {
    listName: "",
    tooltipOpen: false,
    showNewListForm: false,
    showNewListSuccess: false
  };
  handleListNameFormChange = (e: any) => {
    this.setState({ listName: e.currentTarget.value });
  };

  handleCreateList = () => {
    if (this.state.listName.trim() === "") {
      alert("Please enter a valid list name.");
    } else {
      const projectId = this.props.projectId;
      const params: ListVO = {
        personIds: [this.props.personId],
        listName: this.state.listName,
        projectId,
        userId: this.props.user.id,
        firstName: this.props.firstName,
        lastName: this.props.lastName
      };
      this.props.createList(params);
    }

    this.setState({ showNewListForm: false, showNewListSuccess: true });
  };

  render() {
    const lists = this.props.listAvailable.map((list: any) => {
      try {
        const personIds = _.flatMap(list.listPersonJoins, e => e.personId);
        if (_.includes(personIds, this.props.personId)) {
          return <></>;
        }
      } catch (e) {}

      return (
        <div style={{ width: "100%" }} key={list.id}>
          <div
            onClick={() => {
              this.props.addToList({
                personIds: [this.props.personId],
                listId: list.id
              });
            }}
            style={{ lineHeight: "18px" }}
          >
            <B2 small hover>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ marginRight: "5px" }}
                  src={images.availableList}
                />
                <span>{list.title}</span>
              </div>
            </B2>
          </div>
        </div>
      );
    });

    const newListForm = (
      <>
        <SECONDARY_INPUT
          type="text"
          placeholder="List Name"
          onChange={this.handleListNameFormChange}
          style={{ paddingLeft: "0px" }}
        />
        <br />
        <br />
        <PRIMARY_BUTTON block small onClick={this.handleCreateList}>
          Create New List
        </PRIMARY_BUTTON>
      </>
    );

    const newListPrompt = (
      <>
        <DIVIDER />
        <LIST_ACTION_BTN
          onClick={() => this.setState({ showNewListForm: true })}
        >
          <img style={{ marginRight: "5px" }} src={images.newList} />
          <B1 hover>New List</B1>
        </LIST_ACTION_BTN>
      </>
    );

    const listCreatedConfirmation = () => {
      _.delay(() => {
        this.setState({
          tooltipOpen: false,
          showNewListForm: false,
          showNewListSuccess: false
        });
      }, 5000);
      return (
        <>
          <B2>Saved to "{this.state.listName}"</B2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "20px"
            }}
          >
            <img style={{ marginRight: "5px" }} src={images.saveSuccess} />
            <B1>Saved!</B1>
          </div>
        </>
      );
    };

    const removeFromListBtn = (
      <>
        <DIVIDER />
        {/* <h1>Delete</h1> */}
        <LIST_ACTION_BTN
          onClick={() => {
            this.props.createNewGrowler({
              title: `Profile Removed`,
              description: `The profile was removed`,
              growler: GrowlerTypes.success,
              titleIcon: "check"
            });
            this.setState(
              {
                tooltipOpen: false
              },
              () => {
                this.props.removeFromList({
                  personIds: [this.props.personId],
                  listId: this.props.selectedListId
                });
              }
            );
          }}
        >
          <B1 hover>Remove from List</B1>
        </LIST_ACTION_BTN>
      </>
    );

    const availableListMenu = (
      <>
        <B2>Select list to save to:</B2>
        <br />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: 50,
            overflowY: "scroll"
          }}
        >
          {lists}
        </div>
        {newListPrompt}
        {this.props.selectedListId ? removeFromListBtn : null}
        {/* {newListPrompt} */}
      </>
    );
    const tooltipContent = (
      <div style={{ minWidth: "150px" }}>
        {this.state.showNewListForm
          ? newListForm
          : this.state.showNewListSuccess
          ? listCreatedConfirmation()
          : availableListMenu}
      </div>
    );

    return (
      <LIST_MANAGER_CONTAINER>
        <ClickableTooltip
          content={tooltipContent}
          open={this.state.tooltipOpen}
          handleClose={() =>
            this.setState({
              tooltipOpen: false,
              showNewListForm: false,
              showNewListSuccess: false
            })
          }
        >
          <IconButton
            onClick={() => {
              if (true) {
                this.setState({ tooltipOpen: true });
              }
            }}
            active={this.props.active !== undefined ? this.props.active : true}
            // @ts-ignore
            img={images.saveToList}
          />
        </ClickableTooltip>
      </LIST_MANAGER_CONTAINER>
    );
  }
}

export const mapStateToProps = (state: RootState) => ({
  user: currentUser(state)!,
  projectId: getSelectedProject(state)!,
  listAvailable: getAvailableLists(state),
  selectedListId: getSelectedListId(state),
  getLists: getLists(state)
});

export const mapDispatchToProps = {
  createList: createList.request,
  addToList: addToList.request,
  createNewGrowler: createGrowler,
  removeFromList: removePeopleFromList.request
};

export const ListManager = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListManagerClass);
