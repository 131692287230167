import { ENDPOINTS } from "./api-endpoints";

export const requestKOLExport = (ids: string[]) =>
  fetch(ENDPOINTS.exportKols, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({ ids })
  }).then(res => res.json());
