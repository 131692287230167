import { persistStore } from "redux-persist";

import persistedReducer, { PersistedRootState } from "./persistedReducer";
import { rootEpics } from "./epics";
import { GrowlerAutoClose } from "./middleware/growler";
import { RefetchPageOnConfigChange } from "./middleware/refetchConfig";
import { AnalyticsMiddleware } from "./middleware/analyticsMiddleware";
import { Action, logout, searchElastic } from "./actions";
import { getType } from "typesafe-actions";
import { RootState } from "./reducers";

const reducers = (state: PersistedRootState | undefined, action: Action) => {
  switch (action.type) {
    case getType(logout):
      return persistedReducer(undefined, action);
    default:
      return persistedReducer(state, action);
  }
};
// @ts-ignore
const freeze = require("redux-freeze");

// @ts-ignore
const Intercom = require("react-intercom");

import { createStore, applyMiddleware, compose } from "redux";
import { createEpicMiddleware } from "redux-observable";

function getComposeEnhancers() {
  const filler = "<<LONG_DATA>>";

  const actionSanitizer = (action: Action) => {
    switch (action.type) {
      case getType(searchElastic.success):
        // @ts-ignore
        action.payload.scoredDocuments.persons = filler;

        return action;
      default:
        return action;
    }
  };

  const stateSanitizer = (state: RootState) => {
    let { persons }: any = state.searchResults.scoredDocuments;

    if (persons.length > 100) {
      persons = filler;
    }
    return {
      ...state,
      searchResults: {
        ...state.searchResults,
        scoredDocuments: { ...state.searchResults.scoredDocuments, persons }
      }
    };
  };

  const sanitizerArgs = { actionSanitizer, stateSanitizer };

  const reduxDevtoolsCompose = (window as any)
    .__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

  if (
    reduxDevtoolsCompose &&
    (process.env.NODE_ENV === "development" ||
      process.env.NODE_ENV === "develop")
  ) {
    return reduxDevtoolsCompose({ ...sanitizerArgs, trace: true });
  }

  return compose;
}

export const configureStore = () => {
  const epicMiddleware = createEpicMiddleware();

  const composeEnhancers = getComposeEnhancers();

  const store = createStore(
    reducers,
    composeEnhancers(
      applyMiddleware(
        epicMiddleware,
        freeze,
        AnalyticsMiddleware,
        GrowlerAutoClose,
        RefetchPageOnConfigChange
      )
    )
  );

  epicMiddleware.run(rootEpics);
  const persistor = persistStore(store);
  return { store, persistor };
};
