import * as React from "react";
import { connect } from "react-redux";
import { getSearchFilters } from "../../../../store/selectors";
import { setStateValues, setStateQuery } from "../../../../store/actions";
import { RootState } from "../../../../store/reducers";
import { FilterAutosuggest as Autosuggest } from "../FilterAutosuggest";

interface MappedStateProps {
  options: string[];
  values: string[];
  inputValue: string;
}

interface DispatchProps {
  setValues: (values: string[]) => void;
  handleInputChange: (inputVal: string) => void;
}

type Props = MappedStateProps & DispatchProps;

class StateFiltersComponent extends React.Component<Props> {
  /**
   * Temporary fix due to data issue
   */
  get options() {
    return this.props.options.filter(i => i !== "--" && i !== "Illinlos");
  }

  render() {
    const { values, setValues } = this.props;

    return (
      <Autosuggest
        values={values}
        setValues={setValues}
        suggestions={this.options}
        inputLabel="State / Region"
        inputValue={this.props.inputValue}
        setInputValue={this.props.handleInputChange}
        SuggestionProps={{
          style: { textTransform: "capitalize" }
        }}
      />
    );
  }
}

const mapStateToProps = (rootState: RootState): MappedStateProps => {
  const { state } = getSearchFilters(rootState);

  return {
    options: state.options,
    values: state.values,
    inputValue: state.query
  };
};

const mapDispatchToProps: DispatchProps = {
  setValues: setStateValues,
  handleInputChange: setStateQuery
};

export const StateFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(StateFiltersComponent);
