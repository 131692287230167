const Fuse = require("fuse.js");

export interface TagOption {
  id: string;
  name: string;
  isH1: boolean;
}

export function filterTags(query: string, options: TagOption[]): TagOption[] {
  const fuseConfig = {
    shouldSort: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ["name"]
  };

  if (!query || !options || options.length < 1) {
    return [];
  }

  try {
    const fuse = new Fuse(options, fuseConfig);

    return fuse.search(query);
  } catch (e) {
    console.log(e);
    return [];
  }
}
