/* tslint:disable:member-ordering */
import * as React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import { RootState } from "../../../../store/reducers";
import { setShowAdvancedFilters } from "../../../../store/actions";
import {
  showAdvancedFilters,
  getAdvancedSearchFiltersCount
} from "../../../../store/selectors";
import { Publications } from "./Publications";
import { Trials } from "./Trials";
import { Congresses } from "./Congresses";
// import { Grants } from "./Grants";
import { Payments } from "./Payments";

const styles = (theme: Theme) =>
  createStyles({
    button: {
      textTransform: "unset",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex"
    },
    openButton: {
      width: "fit-content",
      border: "2px solid #060464",
      borderRadius: "4px",
      "&:hover": {
        border: "2px solid #060464"
      }
    },
    marginTop: {
      marginTop: 15
    },
    buttonWrapper: {
      borderTop: "1px solid #979797"
    }
  });

interface MappedStateProps {
  open: boolean;
  advancedSearchFilterCount: number;
}

interface DispatchProps {
  setAdvancedFiltersOpen: (open: boolean) => void;
}

type Props = MappedStateProps & DispatchProps & WithStyles<typeof styles>;

class AdvancedFiltersComponent extends React.Component<Props> {
  private setOpen = (open: boolean) => () => {
    this.props.setAdvancedFiltersOpen(open);
  };

  render() {
    const { classes, open, advancedSearchFilterCount } = this.props;

    return (
      <div className={classes.marginTop} id="Advanced-Search">
        <Collapse in={!open}>
          <Button
            className={classNames(classes.button, classes.openButton)}
            variant="outlined"
            color="primary"
            onClick={this.setOpen(true)}
          >
            Advanced Search{" "}
            {advancedSearchFilterCount > 0
              ? `(${advancedSearchFilterCount})`
              : ""}
          </Button>
        </Collapse>
        <Collapse in={open}>
          <Publications />
          <Trials />
          <Congresses />
          {/* <Grants /> */}
          <Payments />
          <div className={classes.buttonWrapper}>
            <Button
              className={classNames(classes.button, classes.marginTop)}
              onClick={this.setOpen(false)}
            >
              Hide Advanced Filters
            </Button>
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): MappedStateProps => ({
  open: showAdvancedFilters(state),
  advancedSearchFilterCount: getAdvancedSearchFiltersCount(state)
});

const mapDispatchToProps: DispatchProps = {
  setAdvancedFiltersOpen: setShowAdvancedFilters
};

export const AdvancedFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AdvancedFiltersComponent));
