import * as React from "react";
import {
  PRIMARY_BUTTON as PrimaryButton,
  PRIMARY_INPUT as PrimaryInput
} from "@h1eng/ui-components";
import SearchIcon from "@material-ui/icons/Search";

export interface SearchBarProps {
  handleChange: (e: React.ChangeEvent<HTMLElement>) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLElement>) => void;
  handleSearch: (e: any) => void;
  value: string;
  placeholderText?: string;
  borderTopLeftRadius?: number;
}

export default class SearchBar extends React.Component<SearchBarProps> {
  static defaultProps = {
    placeholderText: "Search People, Drugs, Indications, Targets, ... etc",
    borderTopLeftRadius: 4
  };
  // TODO: make this a form, so an enter press submits

  render() {
    const { borderTopLeftRadius } = this.props;

    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "82% 18%",
          alignItems: "center"
        }}
      >
        <div
          style={{
            borderTop: "2px solid #E8E6E1",
            borderLeft: "2px solid #E8E6E1",
            borderBottom: "2px solid #E8E6E1",
            borderTopLeftRadius,
            borderBottomLeftRadius: "4px"
          }}
        >
          <PrimaryInput
            id="search-primary-input"
            block
            onChange={this.props.handleChange}
            onKeyDown={this.props.handleKeyDown}
            value={this.props.value}
            placeholder={this.props.placeholderText}
          />
        </div>
        <div>
          <PrimaryButton block onClick={this.props.handleSearch}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div id={"search-button"} style={{ paddingLeft: "13px" }}>
                Search
              </div>
              <SearchIcon
                style={{
                  color: "#ffffff",
                  paddingLeft: "15px",
                  opacity: 0.54,
                  marginTop: "3px",
                  marginLeft: "-7px"
                }}
              />
            </div>
          </PrimaryButton>
        </div>
      </div>
    );
  }
}
