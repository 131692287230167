import { isActionOf } from "typesafe-actions";
import {
  Epic,
  StateObservable,
  ofType,
  ActionsObservable
} from "redux-observable";
import {
  requestPublicationJournalOptions,
  setPublicationJournalQuery,
  requestPublicationTypeOptions,
  setPublicationTypeQuery,
  resetInitialOptions,
  SearchFilterAction
} from "../../actions/searchFilters";
import { RootState } from "../../reducers";
import {
  debounceTime,
  switchMap,
  takeUntil,
  skip,
  map,
  filter,
  catchError
} from "rxjs/operators";
import { from, of, pipe } from "rxjs";
import { getOptions } from "../../../apis/searchFilters";
import { DEBOUNCE_TIME } from ".";

const setPublicationJournalQueryFlow: Epic<SearchFilterAction, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(ofType(setPublicationJournalQuery)).pipe(
    debounceTime(DEBOUNCE_TIME),
    switchMap(({ payload }) =>
      !!payload
        ? of(
            requestPublicationJournalOptions.request(
              state$.value.searchFilters.publications.journal
            )
          ).pipe(takeUntil(action$.pipe(skip(1))))
        : of(resetInitialOptions())
    )
  );

const getPublicationJournalOptionsFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(requestPublicationJournalOptions.request)),
    switchMap(({ payload }) =>
      from(
        getOptions(
          payload
          // state$.value.searchFilters.initialOptions.publications.journal
        )
      ).pipe(
        map(requestPublicationJournalOptions.success),
        catchError(
          pipe(
            requestPublicationJournalOptions.failure,
            of
          )
        )
      )
    )
  );

const setPublicationTypeQueryFlow: Epic<SearchFilterAction, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(ofType(setPublicationTypeQuery)).pipe(
    debounceTime(DEBOUNCE_TIME),
    switchMap(({ payload }) =>
      !!payload
        ? of(
            requestPublicationTypeOptions.request(
              state$.value.searchFilters.publications.type
            )
          ).pipe(takeUntil(action$.pipe(skip(1))))
        : of(resetInitialOptions())
    )
  );

const getPublicationTypeOptionsFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(requestPublicationTypeOptions.request)),
    switchMap(({ payload }) =>
      from(
        getOptions(
          payload
          // state$.value.searchFilters.initialOptions.publications.type
        )
      ).pipe(
        map(requestPublicationTypeOptions.success),
        catchError(
          pipe(
            requestPublicationTypeOptions.failure,
            of
          )
        )
      )
    )
  );

export default [
  setPublicationJournalQueryFlow,
  getPublicationJournalOptionsFlow,
  setPublicationTypeQueryFlow,
  getPublicationTypeOptionsFlow
];
