/* tslint:disable:cyclomatic-complexity */
import { getType, ActionType } from "typesafe-actions";
import { TagInterface } from "@h1eng/interfaces";
import * as actions from "../actions/tags";
import { CurrentTags } from "../actions/tags";

export interface TagsState {
  options: TagInterface[];
  optionsLoading: boolean;
  currentTags: CurrentTags;
}

export const tagsInitialState: TagsState = {
  options: [],
  optionsLoading: false,
  currentTags: {}
};

export const tagsReducer = (
  state: TagsState = tagsInitialState,
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case getType(actions.setTagOptionsLoading): {
      return {
        ...state,
        optionsLoading: action.payload
      };
    }

    case getType(actions.requestTagOptions.success): {
      return {
        ...state,
        options: action.payload
      };
    }

    case getType(actions.requestCurrentTags.success): {
      return {
        ...state,
        currentTags: action.payload
      };
    }

    case getType(actions.appendCurrentTagsForPeople.success): {
      return {
        ...state,
        currentTags: {
          ...state.currentTags,
          ...action.payload
        }
      };
    }
  }

  return state;
};
