import * as React from "react";
import { connect } from "react-redux";
import { styled, css } from "@h1eng/ui-components";
import { SearchTypes } from "@h1eng/interfaces";
import SearchBar, { SearchBarProps } from "./SearchBar";
import { RootState } from "../../../../store/reducers";
import { getSearchType } from "../../../../store/selectors";
import { setSearchType } from "../../../../store/actions";

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionButton = styled.div<{ active: boolean }>`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  cursor: pointer;
  padding-left: 25px;
  padding-right: 25px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  user-select: none;
  border: 2px solid transparent;
  ${props =>
    props.active &&
    css`
      z-index: 2;
      cursor: default;
      border-radius: 8px 8px 0 0;
      border: 2px solid #e8e6e1;
      border-bottom-color: #fff;
      background-color: #ffffff;
    `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: -2px;
`;

interface MappedStateProps {
  searchType: SearchTypes;
}

interface DispatchProps {
  setType: (searchType: SearchTypes) => void;
}

type Props = MappedStateProps & DispatchProps & SearchBarProps;

const options = [
  {
    id: "key-word-search-toggle",
    type: SearchTypes.KEYWORD,
    placeholderText:
      "Search for drugs, indications, targets, and other scientific keywords"
  },
  {
    id: "name-search-toggle",
    type: SearchTypes.NAME,
    placeholderText: "Search for people by name"
  }
];

const TypedSearchBarComponent: React.FunctionComponent<Props> = ({
  searchType,
  setType,
  ...props
}) => {
  let placeholderText;
  const selectedOption: any = options.find(i => i.type === searchType);

  if (selectedOption) {
    placeholderText = selectedOption.placeholderText;
  }

  const handleClick = (type: SearchTypes) => () => {
    if (selectedOption && selectedOption.type === type) return;
    setType(type);
  };

  return (
    <Root>
      <ButtonWrapper>
        {options.map(option => (
          <OptionButton
            id={option.id}
            key={option.type}
            active={option.type === searchType}
            onClick={handleClick(option.type)}
          >
            {option.type}
          </OptionButton>
        ))}
      </ButtonWrapper>
      <SearchBar
        {...props}
        placeholderText={placeholderText}
        borderTopLeftRadius={searchType === SearchTypes.KEYWORD ? 0 : undefined}
      />
    </Root>
  );
};

const mapStateToProps = (state: RootState): MappedStateProps => ({
  searchType: getSearchType(state)
});

const mapDispatchToProps: DispatchProps = {
  setType: setSearchType
};

export const TypedSearchBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(TypedSearchBarComponent);
