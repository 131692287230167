/* tslint:disable:cyclomatic-complexity */
import { getType } from "typesafe-actions";
import {
  setRequestedKolLoading,
  setRequestedKolData,
  setRequestedKolErrors,
  setRequestedKolModalOpen,
  setRequestedKolFormStarted,
  resetRequestedKolData,
  setKolDataRequestPersonId,
  fetchSelectedPersonRequests,
  setRequestKolDataModalOpen,
  setPeopleRequestsLoading,
  UserInfoRequestAction
} from "../actions";
import {
  SlackKOLRequestPayload,
  SlackKOLRequestPayloadValidationErrors,
  PersonKOLDataRequests
} from "@h1eng/interfaces";

export interface UserInfoRequestState {
  requestedKol: {
    formStarted: boolean;
    loading: boolean;
    modalOpen: boolean;
    errors: SlackKOLRequestPayloadValidationErrors | false;
    kol: SlackKOLRequestPayload;
  };
  requestKolData: {
    personId: string | null;
    selectedPersonRequests: PersonKOLDataRequests | null;
    modalOpen: boolean;
    requestsLoading: boolean;
  };
}

export const userInfoRequestsInitialState: UserInfoRequestState = {
  requestedKol: {
    formStarted: false,
    loading: false,
    modalOpen: false,
    errors: false,
    kol: {
      firstName: "",
      lastName: "",
      jobTitle: "",
      institution: ""
    }
  },
  requestKolData: {
    personId: null,
    selectedPersonRequests: null,
    modalOpen: false,
    requestsLoading: false
  }
};

export const userInfoRequestReducer = (
  state: UserInfoRequestState = userInfoRequestsInitialState,
  action: UserInfoRequestAction
): UserInfoRequestState => {
  switch (action.type) {
    case getType(setRequestedKolLoading): {
      return {
        ...state,
        requestedKol: {
          ...state.requestedKol,
          loading: action.payload
        }
      };
    }

    case getType(resetRequestedKolData): {
      return {
        ...state,
        requestedKol: {
          ...state.requestedKol,
          kol: {
            firstName: "",
            lastName: "",
            jobTitle: "",
            institution: ""
          }
        }
      };
    }

    case getType(setRequestedKolData): {
      return {
        ...state,
        requestedKol: {
          ...state.requestedKol,
          kol: action.payload
        }
      };
    }

    case getType(setRequestedKolErrors): {
      return {
        ...state,
        requestedKol: {
          ...state.requestedKol,
          errors: action.payload
        }
      };
    }

    case getType(setRequestedKolModalOpen): {
      return {
        ...state,
        requestedKol: {
          ...state.requestedKol,
          modalOpen: action.payload
        }
      };
    }

    case getType(setRequestedKolFormStarted): {
      return {
        ...state,
        requestedKol: {
          ...state.requestedKol,
          formStarted: action.payload
        }
      };
    }

    // KOL Data Requests
    case getType(setKolDataRequestPersonId): {
      return {
        ...state,
        requestKolData: {
          ...state.requestKolData,
          personId: action.payload
        }
      };
    }

    case getType(fetchSelectedPersonRequests.success): {
      return {
        ...state,
        requestKolData: {
          ...state.requestKolData,
          selectedPersonRequests: action.payload
        }
      };
    }

    case getType(setRequestKolDataModalOpen): {
      return {
        ...state,
        requestKolData: {
          ...state.requestKolData,
          modalOpen: action.payload
        }
      };
    }

    case getType(setPeopleRequestsLoading): {
      return {
        ...state,
        requestKolData: {
          ...state.requestKolData,
          requestsLoading: action.payload
        }
      };
    }

    default:
      return state;
  }
};
