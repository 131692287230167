// @ts-nocheck
/* tslint:disable:cyclomatic-complexity array-type */
import * as React from "react";
import {
  ProfileInterface,
  CombinedDocumentsType,
  ClinicalTrialInterface,
  PublicationInterface
} from "@h1eng/interfaces";
import { styled, H5, themeColors } from "@h1eng/ui-components";
import { personCard } from "./ProfileHeader";
import { OVERVIEWSECTION } from "./ProfileOverView";
import { PublicationSection } from "./ProfilePublications";
import { PaymentsSection } from "./ProfileOpenPayments";
import { ClinicalTrialsSection } from "./ClinicalTrials/ClinicalTrials";
import { ClinicalTrial } from "../DocumentViews/ClinicalTrial";
import { Publication } from "../DocumentViews/Publication";
import * as _ from "lodash";
import { trackProfileView } from "../../../analytics/track";
import { ProfileCongressCard } from "./ProfileCongress";
import { GrantsSection } from "./ProfileGrants";
import {
  createAndSortAffiliation,
  AffiliationsSortedInterface
} from "./ProfileAffiliationsHelper";
import { ProfileAffiliationsSorted } from "./ProfileAffiliationsSorted";
import { ProfileAffiliationsAwardsSorted } from "./ProfileAwardsSorted";
import { ProfileSectionSelector } from "./ProfileSelector";
import { Collaborators } from "./Collaborators/Collaborators";
import withBackLink from "../DocumentViews/lib/withBackLink";
import { RequestInfo } from "./RequestInfo";

export enum SubViews {
  OVERVIEW,
  CLINICAL,
  PAYMENTS,
  PUBLICATIONS,
  CONGRESSES,
  GRANTS,
  EXPERIENCE,
  AWARDS,
  COLLABORATORS
}
export const subviewPaths = {
  [SubViews.OVERVIEW]: { path: "overview", title: "Overview" },
  [SubViews.PUBLICATIONS]: { path: "publications", title: "Publications" },
  [SubViews.COLLABORATORS]: { path: "collaborators", title: "Collaborators" },
  [SubViews.CLINICAL]: { path: "clinical-trials", title: "Clinical Trials" },
  [SubViews.CONGRESSES]: { path: "congresses", title: "Congresses" },
  [SubViews.PAYMENTS]: { path: "payments", title: "Payments" },
  [SubViews.EXPERIENCE]: { path: "experience", title: "Experience" },
  [SubViews.GRANTS]: { path: "grants", title: "Grants" },
  [SubViews.AWARDS]: { path: "awards", title: "Awards" }
};

export function pathForSubview(s: SubViews): string {
  return subviewPaths[s].path;
}

export function subviewForPath(p: string): SubViews {
  // @ts-ignore
  return parseInt(_.findKey(subviewPaths, { path: p }), 10);
}

export class Profile extends React.Component<
  ProfileInterface & {
    subView: SubViews;
    handleSubviewChange: (s: SubViews) => void;
    docId?: string;
    hasCollaborators: boolean;
  }
  > {
  constructor(props: any) {
    super(props);
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    trackProfileView(
      `${this.props.header!.firstName} ${this.props.header!.lastName}`,
      this.props.header!.id || ""
    );
  }

  handleSubViewChange = (subView: any) => {
    this.setState({ subView });
  };

  getDocById(
    docs: CombinedDocumentsType[],
    id: string,
    docProp: string = "id"
  ) {
    return docs.find(i => i[docProp] === id);
  }

  getSubView() {
    const documents = [] as CombinedDocumentsType[];
    if (this.props.congressess) {
      documents.push(...this.props.congressess.congressess);
    }
    if (this.props.payments) {
      documents.push(...this.props.payments.payments);
    }
    if (this.props.publication) {
      documents.push(...this.props.publication.publications);
    }

    if (this.props.clinicalTrials) {
      documents.push(...this.props.clinicalTrials.trials);
    }

    if (this.props.grants) {
      documents.push(...this.props.grants.grants);
    }

    const requestInfo = <RequestInfo personId={this.props.overview!.id} />;

    const { subView } = this.props;

    if (subView === SubViews.COLLABORATORS) {
      return <Collaborators personId={this.props.overview!.id} />;
    }

    if (subView === SubViews.OVERVIEW) {
      const propsOverview = { ...this.props.overview, documents };
      return documents.length === 0 ? (
        requestInfo
      ) : (
          <OVERVIEWSECTION {...propsOverview} />
        );
    }
    if (subView === SubViews.CLINICAL && this.props.clinicalTrials) {
      return (
        <ClinicalTrialsSection
          {...this.props.clinicalTrials}
          personId={this.props.overview!.id}
        />
      );
    }

    if (subView === SubViews.PAYMENTS) {
      return <PaymentsSection {...this.props.payments} />;
    }

    if (subView === SubViews.PUBLICATIONS) {
      return (
        <PublicationSection
          {...this.props.publication}
          personId={this.props.overview!.id}
        />
      );
    }
    if (subView === SubViews.CONGRESSES && this.props.congressess) {
      return (
        <ProfileCongressCard
          {...this.props.congressess}
          personId={this.props.overview!.id}
        />
      );
    }

    if (subView === SubViews.GRANTS && this.props.grants) {
      return (
        <GrantsSection
          {...this.props.grants}
          personId={this.props.overview!.id}
        />
      );
    }
    if (subView === SubViews.EXPERIENCE) {
      if (this.props.header && this.props.header.titleAndAssociations) {
        const affiliations = createAndSortAffiliation(
          this.props.header.titleAndAssociations
        );
        if (affiliations.sections.experience.length > 0) {
          return ProfileAffiliationsSorted(3, affiliations.sections.experience);
        }
      }
      return requestInfo;
    }
    if (subView === SubViews.AWARDS) {
      if (this.props.header && this.props.header.titleAndAssociations) {
        const affiliations = createAndSortAffiliation(
          this.props.header.titleAndAssociations
        );
        if (affiliations.sections.awards.length > 0) {
          return ProfileAffiliationsAwardsSorted(affiliations.sections.awards);
        }
      }
      return requestInfo;
    }
    return <> </>;
  }

  getView(docId: string) {
    let res = null;
    let doc;
    const personId = this.props.overview!.id;
    const { subView } = this.props;
    if (subView === SubViews.CLINICAL && this.props.clinicalTrials) {
      doc = this.getDocById(this.props.clinicalTrials.trials, docId);

      if (doc) {
        const ClinicalTrialView = withBackLink(ClinicalTrial, {
          to: `/curie/person/${personId}/clinical-trials`
        });

        res = <ClinicalTrialView trial={doc as ClinicalTrialInterface} />;
      }
    } else if (subView === SubViews.PUBLICATIONS && this.props.publication) {
      doc = this.getDocById(this.props.publication.publications || [], docId);

      if (doc) {
        const PublicationView = withBackLink(Publication, {
          to: `/curie/person/${personId}/publications`
        });
        res = <PublicationView publication={doc as PublicationInterface} />;
      }
    }

    return res;
  }

  render() {
    const { docId } = this.props;

    if (docId) {
      const view = this.getView(docId);
      if (view) return view;
    }
    return (
      <div id={"profile"} style={{ minWidth: "1024px" }}>
        {this.props.header &&
          personCard(this.props.header, this.props.handleSubviewChange)}
        <ProfileDetailsSelector
          hasCollaborators={this.props.hasCollaborators}
          profileInterface={this.props}
          selected={this.props.subView}
          handleChange={this.props.handleSubviewChange}
        />
        {this.getSubView()}
      </div>
    );
  }
}

const ProfileSectionNavigator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
`;
const SectionLink = styled.div`
  cursor: pointer;
  height: 20px;
  padding: 0px 15px;
`;
const SelectedOption = styled.div`
  border-bottom: solid 2px #80d0e1;
  height: 25px;
  color: ${themeColors.themeHeadingsDefualt};
`;

export const subViewSwitch = (
  selected: SubViews,
  type: SubViews,
  content: any
) => {
  if (selected === type) {
    return (
      <SelectedOption>
        <H5>{content}</H5>
      </SelectedOption>
    );
  }
  return <H5 deEmphasized>{content}</H5>;
};

interface ProfileDetailsSelectorPropInterface {
  profileInterface: ProfileInterface;
  handleChange: (arg: any) => void;
  selected: SubViews;
  hasCollaborators: boolean;
}
interface ProfileDetailsSelectorStateInterface {
  renderClinicalTrials: boolean;
  renderPayment: boolean;
  renderPublictions: boolean;
  renderCongress: boolean;
  renderGrants: boolean;
  renderAwards: boolean;
  renderExperince: boolean;
  renderCollaborators: boolean;
}
export class ProfileDetailsSelector extends React.Component<
  ProfileDetailsSelectorPropInterface,
  ProfileDetailsSelectorStateInterface
  > {
  constructor(props: ProfileDetailsSelectorPropInterface) {
    super(props);
    let affliationData: AffiliationsSortedInterface = {
      sections: { awards: [], experience: [] }
    } as AffiliationsSortedInterface;
    if (
      props.profileInterface.header &&
      props.profileInterface.header.titleAndAssociations
    ) {
      affliationData = createAndSortAffiliation(
        props.profileInterface.header.titleAndAssociations
      );
    }

    const renderAwards =
      affliationData && affliationData.sections.awards.length ? true : false;

    const renderExperince =
      affliationData && affliationData.sections.experience.length
        ? true
        : false;

    const renderClinicalTrials =
      this.props.profileInterface.clinicalTrials &&
        this.props.profileInterface.clinicalTrials.trials &&
        this.props.profileInterface.clinicalTrials.trials.length > 0
        ? true
        : false;

    const renderPayment =
      this.props.profileInterface.payments &&
        this.props.profileInterface.payments.payments &&
        this.props.profileInterface.payments.payments.length > 0
        ? true
        : false;

    const renderPublictions =
      this.props.profileInterface.publication &&
        this.props.profileInterface.publication.publications &&
        this.props.profileInterface.publication.publications.length > 0
        ? true
        : false;

    const renderCongress =
      this.props.profileInterface.congressess &&
        this.props.profileInterface.congressess.congressess &&
        this.props.profileInterface.congressess.congressess.length > 0
        ? true
        : false;

    const renderGrants =
      this.props.profileInterface.grants &&
        this.props.profileInterface.grants.grants &&
        this.props.profileInterface.grants.grants.length > 0
        ? true
        : false;
    const renderCollaborators = this.props.hasCollaborators;

    this.state = {
      renderGrants,
      renderAwards,
      renderExperince,
      renderClinicalTrials,
      renderPayment,
      renderPublictions,
      renderCongress,
      renderCollaborators
    };
  }

  handleClick = (subview: SubViews) => (e: any) => {
    this.props.handleChange(subview);
  };

  render() {
    const { selected } = this.props;
    const {
      renderCongress,
      renderClinicalTrials,
      renderPayment,
      renderPublictions,
      renderGrants,
      renderExperince,
      renderAwards,
      renderCollaborators
    } = this.state;
    const dropRender = [] as { title: string; path: SubViews }[];
    dropRender.push({ title: "OVERVIEW", path: SubViews.OVERVIEW });

    if (renderPublictions) {
      dropRender.push({ title: "PUBLICATIONS", path: SubViews.PUBLICATIONS });
    }

    if (renderCollaborators) {
      dropRender.push({ title: "COLLABORATORS", path: SubViews.COLLABORATORS });
    }

    if (renderClinicalTrials) {
      dropRender.push({ title: "CLINICAL TRIALS", path: SubViews.CLINICAL });
    }
    if (renderCongress) {
      dropRender.push({ title: "CONGRESS", path: SubViews.CONGRESSES });
    }
    if (renderPayment) {
      dropRender.push({ title: "PAYMENTS", path: SubViews.PAYMENTS });
    }
    if (renderAwards) {
      dropRender.push({ title: "AWARDS", path: SubViews.AWARDS });
    }
    if (renderExperince) {
      dropRender.push({ title: "EXPERIENCE", path: SubViews.EXPERIENCE });
    }
    if (renderGrants) {
      dropRender.push({ title: "GRANTS", path: SubViews.GRANTS });
    }

    if (dropRender.length > 6) {
      return (
        <ProfileSectionSelector
          selected={selected}
          onChoose={this.props.handleChange}
          paths={dropRender}
        />
      );
    }

    return (
      <ProfileSectionNavigator>
        <SectionLink onClick={this.handleClick(SubViews.OVERVIEW)}>
          {subViewSwitch(selected, SubViews.OVERVIEW, "OVERVIEW")}
        </SectionLink>
        {renderPublictions && (
          <SectionLink onClick={this.handleClick(SubViews.PUBLICATIONS)}>
            {subViewSwitch(selected, SubViews.PUBLICATIONS, "PUBLICATIONS")}
          </SectionLink>
        )}
        {renderCollaborators && (
          <SectionLink onClick={this.handleClick(SubViews.COLLABORATORS)}>
            {subViewSwitch(selected, SubViews.COLLABORATORS, "COLLABORATORS")}
          </SectionLink>
        )}
        {renderClinicalTrials && (
          <SectionLink onClick={this.handleClick(SubViews.CLINICAL)}>
            {subViewSwitch(selected, SubViews.CLINICAL, "CLINICAL TRIALS")}
          </SectionLink>
        )}
        {renderCongress && (
          <SectionLink onClick={this.handleClick(SubViews.CONGRESSES)}>
            {subViewSwitch(selected, SubViews.CONGRESSES, "CONGRESSES")}
          </SectionLink>
        )}
        {renderPayment && (
          <SectionLink onClick={this.handleClick(SubViews.PAYMENTS)}>
            {subViewSwitch(selected, SubViews.PAYMENTS, "PAYMENTS")}
          </SectionLink>
        )}
        {renderGrants && (
          <SectionLink onClick={this.handleClick(SubViews.GRANTS)}>
            {subViewSwitch(selected, SubViews.GRANTS, "GRANTS")}
          </SectionLink>
        )}
        {renderExperince && (
          <SectionLink onClick={this.handleClick(SubViews.EXPERIENCE)}>
            {subViewSwitch(selected, SubViews.EXPERIENCE, "EXPERIENCE")}
          </SectionLink>
        )}
        {renderAwards && (
          <SectionLink onClick={this.handleClick(SubViews.AWARDS)}>
            {subViewSwitch(selected, SubViews.AWARDS, "AWARDS")}
          </SectionLink>
        )}
      </ProfileSectionNavigator>
    );
  }
}
