import * as React from "react";
import { ListMenu } from "./ListMenu";
import { styled } from "@h1eng/ui-components";
import { ListMeta } from "../ListManagement/ListManagementInterfaces";
import { UserVO } from "../../../models/UserVO";
import { RootState } from "../../../store/reducers";
import { LoggedInShell } from "../../templates/LoggedInShell";
import { withRouter, RouteComponentProps } from "react-router";
import {
  createGrowler,
  GrowlerInterface
} from "../../../store/actions/growler";
import {
  getListOptions,
  kolSavedListsCardsActionGet,
  selectListId,
  deleteList
} from "../../../store/actions";
import { connect } from "react-redux";
import { SavedListCards } from "./SavedListCards/SavedListCards";
import TagOptionFetcher from "../common/TagManager/TagOptionFetcher";
import {
  currentUser,
  getSelectedProject,
  getAvailableLists,
  getSelectedListId,
  getSelectedListPersonIds
} from "../../../store/selectors";

export enum BulkActions {
  NEWEST,
  OLDEST
}

interface MappedStateProps {
  user: UserVO;
  projectId: string;
  listsAvailable: ListMeta[];
  selectedListId: string | null;
  selectedListPersonIds: string[];
}

interface MappedDispatchProps {
  getListOption: (opts: { projectId: string; userId: string }) => void;
  getKolCards: (opts: {
    projectId: string;
    userId: string;
    personIds: string[];
  }) => void;
  setSelectedList: (id: string | null) => void;
  deleteList: (opts: { listId: string }) => void;
  createNewGrowler: (props: GrowlerInterface) => void;
}

type SavedListsPageProps = MappedDispatchProps &
  MappedStateProps &
  RouteComponentProps;

const Content = styled.div`
  padding: 20px;
  background-color: #f6f6f6;
  min-width: 1024px;
  min-height: 100vh;
`;

const Column = styled.div<{ width: string }>`
  width: ${props => props.width};
  display: inline-block;
  vertical-align: top;
`;

const LockedWidth = styled.div`
  max-width: 1280px;
  margin: 0px auto;
  display: grid;
  grid-template-columns: 271px auto;
  grid-column-gap: 20px;
`;

interface MatchParams {
  listId: string | null;
}

function getMatchParams(props: SavedListsPageProps): MatchParams {
  const params: any = props.match.params;

  return {
    ...params,
    listId: params.listId || null
  };
}

class ListPage extends React.Component<SavedListsPageProps> {
  handleDeleteList = (listId?: string | null) => {
    if (!!listId) {
      this.props.deleteList({ listId });
      const otherLists = this.props.listsAvailable.filter(i => i.id !== listId);

      if (otherLists.length) {
        return this.props.history.push(`/saved-lists/${otherLists[0].id}`);
      }

      this.props.history.push("/saved-lists");
    }
  };

  componentDidMount() {
    const { listId } = getMatchParams(this.props);

    if (listId === null && this.props.listsAvailable.length > 0) {
      return this.props.history.push(
        `/saved-lists/${this.props.listsAvailable[0].id}`
      );
    }

    this.props.setSelectedList(listId);
  }

  componentDidUpdate(prevProps: SavedListsPageProps) {
    const { listId: prevListId } = getMatchParams(prevProps);
    const { listId } = getMatchParams(this.props);

    if (listId !== prevListId) {
      this.props.setSelectedList(listId);
    }
  }

  render() {
    return (
      <LoggedInShell>
        <Content>
          <LockedWidth>
            <div>
              <ListMenu deleteList={this.handleDeleteList} />
            </div>

            <div>
              <TagOptionFetcher />
              <SavedListCards />
              <br />
              <br />
            </div>
          </LockedWidth>
        </Content>
      </LoggedInShell>
    );
  }
}

const mapStateToProps = (state: RootState): MappedStateProps => ({
  user: currentUser(state)!,
  projectId: getSelectedProject(state)!,
  listsAvailable: getAvailableLists(state)!,
  selectedListId: getSelectedListId(state)!,
  selectedListPersonIds: getSelectedListPersonIds(state)
});

const mapDispatchToProps: MappedDispatchProps = {
  getListOption: getListOptions.request,
  getKolCards: kolSavedListsCardsActionGet.request,
  setSelectedList: selectListId,
  deleteList: deleteList.request,
  createNewGrowler: createGrowler
};

export const SavedListsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ListPage));
