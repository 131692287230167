/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import {
  ScoredResultCardData,
  ScoredDocumentResults,
  SortBy,
  SearchView
} from "@h1eng/interfaces";
import * as _ from "lodash";
import { connect } from "react-redux";
import { RootState } from "../../../../store/reducers";
import {
  getListForUser,
  getListOptions,
  setSortBy,
  setKOLExportIds
} from "../../../../store/actions";
import { userSetSearchView } from "../../../../store/actions/userInteractionActions";
import { withRouter, RouteComponentProps } from "react-router";
import {
  currentUser,
  getSelectedProject,
  getLists,
  getAvailableLists,
  getCachedCardsInPageRange,
  getSelectedListId,
  isLoadingPeople,
  getSearchBarState,
  getCardsLoadingStatus,
  getHasQuery,
  getTotalHits,
  getSortBy,
  getCurrentScoredDocuments,
  getSearchView,
  getIsSearchLoading,
  getTotalAppliedFiltersCount
} from "../../../../store/selectors";
import { UserVO } from "../../../../models/UserVO";
import { ListMeta } from "../../ListManagement/ListManagementInterfaces";
import { SortMode } from "../ResultSortBy";
import { LoadingState } from "../Loading";
import { CardWrapper } from "../../common/Card";
import { ResultCard } from "./ResultCard";
import { ListButton } from "../../ListManagement/ListButton";
import { BulkActions } from "../../SavedListsPage/SavedListCards/BulkActions";
import { styled } from "@h1eng/ui-components";
import { Pagination } from "../Pagination";
import { NoActiveQuery } from "./NoActiveQuery";
import { SortSearchResults } from "../../KOLSearch/components/SortSearchResults";
import { ViewSelector } from "../ViewSelector";
import { SelectAllKolCheckbox } from "../../common/SelectAllKolCheckbox";
import TagOptionFetcher from "../../common/TagManager/TagOptionFetcher";
import {
  Filters,
  FilterCard,
  FILTER_CARD_WIDTH,
  FILTER_CARD_RIGHT_MARGIN
} from "../../Filters";

interface ResultCardsProps {
  nullState: React.ReactElement<any>;
}

interface ResultCardsState {
  selectAll: boolean;
  selected: string[];
  sortMode: SortMode;
}

interface MappedStateProps {
  user: UserVO;
  projectId: string;
  searchBarState: { query: string[] };
  isLoadingPeople: boolean;
  listsAvailable: ListMeta[];
  lists: {
    [key: string]: {
      personLists: ListMeta[];
    };
  };
  hasQuery: boolean;
  selectedListId: string | null;
  persons: ScoredResultCardData[];
  cardsLoading: boolean;
  totalHits: number;
  getSortBy: SortBy;
  searchView: SearchView;
  currentScoredDocuments: ScoredDocumentResults;
  checkboxDisabled: boolean;
  getTotalAppliedFiltersCount: number;
  pageNum: number;
  pageSize: number;
  selectedKols: string[];
  allPeopleIds: string[];
}

interface DispatchProps {
  setSearchView: (s: SearchView) => void;
  getList: (opts: {
    projectId: string;
    userId: string;
    personIds: string[];
  }) => void;
  getListOption: (opts: { projectId: string; userId: string }) => void;
  setSortBy: (sortBy: SortBy) => void;
  setSelectedKols: (kols: string[]) => void;
}

type Props = MappedStateProps &
  DispatchProps &
  ResultCardsProps &
  RouteComponentProps;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  margin-left: ${FILTER_CARD_WIDTH + FILTER_CARD_RIGHT_MARGIN}px;
  flex-wrap: wrap;
`;

const ContentRoot = styled.div`
  display: flex;
  flex-direction: row;
`;

class ResultCardsClass extends React.Component<Props, ResultCardsState> {
  state: ResultCardsState = {
    selectAll: false,
    selected: [],
    sortMode: SortMode.KOLSCORES
  };

  get selectAllIndeterminateState(): boolean {
    return (
      this.state.selected.length > 0 &&
      this.state.selected.length < this.props.persons.length
    );
  }

  componentWillMount() {
    this.props.getListOption({
      projectId: this.props.projectId,
      userId: this.props.user.id
    });

    this.props.getList({
      projectId: this.props.projectId,
      userId: this.props.user.id,
      personIds: this.props.persons.map(e => e.personId)
    });

    this.props.history.listen((location: any, action: any) => {
      this.setState({ selected: [], selectAll: false });
    });
  }

  componentWillReceiveProps(newProps: Props) {
    if (
      this.props.checkboxDisabled === false &&
      newProps.checkboxDisabled === true
    ) {
      this.setState({ selectAll: false, selected: [] });
    }
    if (newProps.searchBarState) {
      this.setState({
        sortMode:
          newProps.searchBarState.query.length === 0
            ? SortMode.KOLSCORES
            : SortMode.RELEVANCE
      });
    }
  }

  /**
   * Handles the "Select All" logic
   */
  handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    let selected: string[];
    let selectAll: boolean;

    if (checked && !this.selectAllIndeterminateState) {
      selected = this.props.persons.map(p => p.personId);
      selectAll = true;
    } else {
      selected = [];
      selectAll = false;
    }

    this.setState({ selectAll, selected });
  };

  /**
   * Handles the selection of a single KOL card checkbox
   */
  handleCheckedChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const { selectedKols, setSelectedKols } = this.props;
    const { value } = event.target;

    if (checked) {
      setSelectedKols([...new Set([...selectedKols, value])]);
    } else {
      setSelectedKols([...new Set(selectedKols.filter(i => i !== value))]);
    }
  };

  renderCards = () => {
    return (
      <>
        <TagOptionFetcher />
        {this.props.persons.map((person, i) => {
          const selected = _.includes(this.props.selectedKols, person.personId);

          let lists: any[] = [];
          if (this.props.lists && this.props.lists[person.personId]) {
            lists = this.props.lists[person.personId] as any;
          }

          return (
            <ResultCard
              id={`person-${i}`}
              person={person}
              searchView={this.props.searchView}
              selected={selected}
              lists={lists}
              listsAvailable={this.props.listsAvailable}
              selectedListId={this.props.selectedListId}
              onSelect={this.handleCheckedChange}
              listButton={
                <ListButton
                  personId={person.personId}
                  firstName={person.firstName}
                  lastName={person.lastName}
                />
              }
              key={`person-${person.firstName}-${person.lastName}-${i}`}
            />
          );
        })}
      </>
    );
  };

  render() {
    const { selectAll } = this.state;
    const { hasQuery, checkboxDisabled } = this.props;

    return (
      <div>
        <ActionsWrapper>
          <div
            style={{
              marginRight: "auto",
              minWidth: 390,
              flexWrap: "wrap",
              display: "flex",
              flexDirection: "row"
            }}
          >
            <SelectAllKolCheckbox
              ids={this.props.allPeopleIds}
              pageNum={this.props.pageNum}
              pageSize={this.props.pageSize}
              disabled={checkboxDisabled}
            />
            <BulkActions selectedIds={this.props.selectedKols} />
            <SortSearchResults />
          </div>
          <ViewSelector
            searchView={this.props.searchView}
            setSearchView={this.props.setSearchView}
          />
        </ActionsWrapper>
        <ContentRoot>
          <FilterCard>
            <Filters />
          </FilterCard>
          {hasQuery ||
          (this.props.getTotalAppliedFiltersCount > 0 ||
            this.props.isLoadingPeople) ? (
            <div
              style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            >
              <CardWrapper>
                {this.props.isLoadingPeople || this.props.cardsLoading ? (
                  <LoadingState />
                ) : (
                  (this.props.persons.length > 0 && this.renderCards()) ||
                  this.props.nullState
                )}
              </CardWrapper>
              {this.props.persons.length > 0 && <Pagination />}
            </div>
          ) : (
            <NoActiveQuery />
          )}
        </ContentRoot>
      </div>
    );
  }
}

export const mapStateToProps = (state: RootState): MappedStateProps => {
  const persons = getCachedCardsInPageRange(state);

  return {
    user: currentUser(state)!,
    lists: getLists(state)!,
    listsAvailable: getAvailableLists(state)!,
    projectId: getSelectedProject(state)!,
    persons,
    cardsLoading: getCardsLoadingStatus(state),
    selectedListId: getSelectedListId(state),
    isLoadingPeople: isLoadingPeople(state),
    searchBarState: getSearchBarState(state),
    getTotalAppliedFiltersCount: getTotalAppliedFiltersCount(state),
    hasQuery: getHasQuery(state),
    getSortBy: getSortBy(state),
    totalHits: getTotalHits(state),
    currentScoredDocuments: getCurrentScoredDocuments(state),
    searchView: getSearchView(state),
    checkboxDisabled: getIsSearchLoading(state) || persons.length === 0,
    pageNum: state.searchResults.pageNum,
    pageSize: state.searchResults.pageSize,
    selectedKols: state.dataExports.kols,
    allPeopleIds: state.searchResults.scoredDocuments.persons.map(
      i => i.personId
    )
  };
};

export const mapDispatchToProps: DispatchProps = {
  getList: getListForUser.request,
  getListOption: getListOptions.request,
  setSortBy,
  setSearchView: userSetSearchView,
  setSelectedKols: setKOLExportIds
};

export const ResultCards = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ResultCardsClass));
