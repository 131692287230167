/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../../store/reducers";
import {
  getSelectedProject,
  currentUser,
  getSelectedListId,
  getAvailableLists,
  getDocumentSearchBarState,
  getDocumentSearchBarFilterDateDisplayText,
  getDocumentSearchBarFilterDate
} from "../../../../store/selectors";
import { UserVO } from "../../../../models/UserVO";
import { ListMeta } from "../../ListManagement/ListManagementInterfaces";
import {
  ListManipulationInterface,
  addToList,
  setPage,
  searchCollaborators
} from "../../../../store/actions";
import { LiteProfile } from "@h1eng/interfaces";
import { Card } from "../ProfileElements";
import { CollaboratorCard, CollaboratorsCardProps } from "./CollaboratorsCard";
import { ProfileDocumentSearch } from "../ProfileDocumentSearch";
import {
  SearchBarQureyHelper,
  SearchBarQureyDisplay
} from "../SearchHelpFunctions";
import { NoResultsFound } from "../NoResultsFound";
import { DateRangeDropdown } from "../../common/DateRangeDropdown";
import { Pagination } from "../../common/Pagination";
import {
  getPersonCollaboratorsCountMap,
  getPersonCollaborationsDocIds,
  getCollaborators,
  getPage,
  getSize,
  getLiteProfiles,
  getLoading,
  getSortedIds
} from "../../../../store/selectors/collaborators";

interface CollaboratorsProps {
  personId: string;
}
interface RootStateProps {
  user: UserVO;
  projectId: string;
  selectedListId: string | null;
  listsAvailable: ListMeta[];
  sortedIds: string[];
  searchBarState: { query: string[]; personId: string; types: any[] };
  filterDate: number;
  filterDateText: string;
  loading: boolean;
  collaborations: string[];
  collaborators: {
    personId: string;
    docs: { [number]: string[] };
  }[];
  personCollaboratorsCountMap: { [number]: number };
  liteProfiles: LiteProfile[];
  page: number;
  listSize: number;
  setPage: (page: number) => void;
  searchCollaborators: (obj: GetCollaboratorsInterface) => void;
}

type Props = RootStateProps & DispatchFromProps & CollaboratorsProps;

export class CollaboratorsClass extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.scrollRef = React.createRef();
    const terms =
      this.props.searchBarState.query.length > 0
        ? this.props.searchBarState.query
        : undefined;
  }

  componentWillReceiveProps(nextProps: Props) {}

  query(personId: string, terms: string[]) {}

  render() {
    const ordered =
      this.props.loading === true
        ? []
        : this.props.collaborators.filter(
            e => e.personId !== this.props.personId
          );

    return (
      <>
        <Card>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: "20px",
              paddingLeft: "20px",
              paddingTop: "15px"
            }}
            ref={this.scrollRef}
          >
            <div style={{ paddingBottom: "15px" }}>
              <ProfileDocumentSearch
                searchHint={"Search for this researcher’s collaborators"}
                personId={this.props.personId}
              />
            </div>
            <div style={{ paddingBottom: "15px", width: "100%" }}>
              <DateRangeDropdown />
            </div>
          </div>
          {this.props.searchBarState.query.length > 0 && (
            <div style={{ paddingBottom: "15px" }}>
              <SearchBarQureyHelper />
              <SearchBarQureyDisplay />
            </div>
          )}

          {this.props.loading && (
            <div
              style={{
                marginTop: "40px",
                textAlign: "center",
                height: "1000px"
              }}
            >
              <h3 style={{ fontFamily: "Montserrat" }}>{`Loading...`}</h3>
            </div>
          )}

          {!this.props.loading &&
            ordered.length > 0 &&
            ordered.map((p, i) => {
              const e = this.props.liteProfiles.find(a => a.id === p.personId);
              const set = {
                firstName: e.firstName,
                personId: e.id,
                email: e.email,
                lastName: e.lastName,
                phone: e.phoneNumbers[0] || null,
                collaboratorsDocs: this.props.collaborations,
                docs: p.docs,
                designations: e.designations,
                payments: e.payments,
                titleAndAssociations: e.titleAndAssociations
              } as CollaboratorsCardProps;
              return <CollaboratorCard key={`${i}-${e.id}`} {...set} />;
            })}

          {ordered.length === 0 && this.props.loading === false && (
            <NoResultsFound />
          )}
        </Card>
        {ordered.length > 0 && (
          <div style={{ width: "100%" }}>
            <Pagination
              pageNum={this.props.page}
              pageSize={this.props.listSize}
              total={this.props.sortedIds.length}
              loadPage={(pageNum: number) => {
                try {
                  window.scrollTo(0, this.scrollRef.current.offsetTop);
                } catch (e) {}
                this.props.setPage(pageNum);
              }}
            />
          </div>
        )}
      </>
    );
  }
}

interface DispatchFromProps {
  addToList: (opts: ListManipulationInterface) => void;
}

export const mapStateToProps = (state: RootState): RootStateProps => ({
  projectId: getSelectedProject(state)!,
  user: currentUser(state)!,
  selectedListId: getSelectedListId(state),
  listsAvailable: getAvailableLists(state),
  searchBarState: getDocumentSearchBarState(state),
  filterDate: getDocumentSearchBarFilterDate(state),
  filterDateText: getDocumentSearchBarFilterDateDisplayText(state),
  personCollaboratorsCountMap: getPersonCollaboratorsCountMap(state),
  collaborations: getPersonCollaborationsDocIds(state),
  collaborators: getCollaborators(state),
  liteProfiles: getLiteProfiles(state),
  page: getPage(state),
  listSize: getSize(state),
  loading: getLoading(state),
  sortedIds: getSortedIds(state)
});

export const mapDispatchToProps = {
  addToList: addToList.request,
  searchCollaborators: searchCollaborators.request,
  setPage
};

export const Collaborators = connect(
  mapStateToProps,
  mapDispatchToProps
)(CollaboratorsClass);
