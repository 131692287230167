import * as React from "react";
import { styled, css } from "@h1eng/ui-components";
import ClickOutsideListener from "./ClickOutsideListener";

interface TooltipProps {
  open?: boolean; // default is false
  content: React.Component | any;
  backgroundColor: string;
  style?: object;
  caretSize?: number;
}

const ToolTipContent = styled.div<{
  backgroundColor: string;
  caretSize: number;
}>`
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 10;
  width: auto;
  bottom: 40px;
  padding: 20px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.backgroundColor};
  &:after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    border-style: solid;

    ${props => css`
      border-color: ${props.backgroundColor} transparent transparent transparent;
      margin-left: -${props.caretSize}px;
      border-width: ${props.caretSize}px ${props.caretSize}px;
    `}
  }
`;

class Tooltip extends React.Component<TooltipProps, {}> {
  static defaultProps = {
    style: undefined,
    caretSize: 7
  };

  render() {
    return (
      <>
        {this.props.open === true ? (
          <ToolTipContent
            style={this.props.style}
            backgroundColor={this.props.backgroundColor}
            caretSize={this.props.caretSize as number}
          >
            {this.props.content}
          </ToolTipContent>
        ) : null}

        <div>{this.props.children}</div>
      </>
    );
  }
}

interface ClickableTooltipProps {
  open: boolean;
  content: React.Component | any;
  handleClose: () => void;
  style?: React.CSSProperties;
  caretSize?: number;
}

export class ClickableTooltip extends React.Component<ClickableTooltipProps> {
  render() {
    return (
      <ClickOutsideListener
        onClickOutside={() => {
          if (this.props.open) this.props.handleClose();
        }}
      >
        <Tooltip
          backgroundColor="white"
          content={this.props.content}
          open={this.props.open}
          style={this.props.style}
          caretSize={this.props.caretSize}
        >
          {this.props.children}
        </Tooltip>
      </ClickOutsideListener>
    );
  }
}

export interface HoverTooltipProps {
  content?: React.ReactChild;
  style?: React.CSSProperties;
  backgroundColor?: string;
  caretSize?: number;
}

export class HoverTooltip extends React.Component<HoverTooltipProps> {
  static defaultProps = {
    backgroundColor: "#060464"
  };

  state = { open: false };

  setTooltipOpen = (open: boolean) => () => {
    if (!this.props.content) return;

    this.setState({ open });
  };

  render() {
    return (
      <div
        onMouseEnter={this.setTooltipOpen(true)}
        onMouseLeave={this.setTooltipOpen(false)}
      >
        <Tooltip
          backgroundColor={
            this.props.backgroundColor ||
            HoverTooltip.defaultProps.backgroundColor
          }
          content={this.props.content}
          open={this.state.open}
          style={this.props.style}
          caretSize={this.props.caretSize}
        >
          {this.props.children}
        </Tooltip>
      </div>
    );
  }
}

export class TooltipDemo extends React.Component {
  state = { open: false };

  render() {
    const content = (
      <div style={{ position: "absolute", margin: "20px" }}>
        i am the content
        <button
          onClick={() => {
            this.setState({ open: false });
          }}
        >
          click me to close
        </button>
      </div>
    );

    return (
      <>
        <ClickableTooltip
          content={content}
          open={this.state.open}
          handleClose={() => this.setState({ open: false })}
        >
          <i onClick={() => this.setState({ open: true })}>
            click to learn more
          </i>
        </ClickableTooltip>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <HoverTooltip content={<div>hi</div>}>
          <i>hover to learn more</i>
        </HoverTooltip>
      </>
    );
  }
}
