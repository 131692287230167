/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { ResultCardData } from "@h1eng/interfaces";
import { styled } from "@h1eng/ui-components";
import * as _ from "lodash";
import { Card } from "../../common/Card";
import {
  Root as ContentRoot,
  TitleLink,
  PersonDataText,
  InfoWrapper,
  InfoColumn,
  InfoColumnLabel,
  Text,
  BoldText
} from "../../KOLResult/ResultCards/ResultCard/ResultCardContent";
import { ListButton } from "../../ListManagement/ListButton";
import { Checkbox } from "../../common/Checkbox";
import { WorksLink } from "./WorksLink";
import { formatStats } from "@h1eng/format-util";
import {
  getTotalWorksForKOL,
  kolCanRequestInfo
} from "../../../../lib/getTotalWorksForKOL";
import { RequestKOLDataButton } from "../../KOLResult/ResultCards/ResultCard/RequestKOLDataButton";
import { formatLocation } from "@h1eng/format-util";
import { TagManager } from "../../common/TagManager/index";
import { OtherInstitutionsTooltip } from "../../KOLResult/ResultCards/ResultCard/OtherInstitutionsTooltip";

interface Props {
  person: ResultCardData;
  selected: boolean;
  onSelect: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

type SavedListCardProps = Props;

const CardRoot = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: -15px;
  margin-left: -24px;
`;

const WorksRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
`;

const SavedListCardComponent: React.FunctionComponent<SavedListCardProps> = ({
  person,
  selected,
  onSelect
}) => {
  const worksCount = getTotalWorksForKOL(person);

  const { affiliations } = person;

  const activeAffiliation =
    affiliations === null || affiliations.length === 0 ? null : affiliations[0];

  const locationString =
    activeAffiliation !== null && activeAffiliation.address !== null
      ? formatLocation(activeAffiliation.address)
      : "";
  return (
    <Card>
      <CardRoot>
        <CheckboxWrapper>
          <Checkbox
            value={person.personId}
            checked={selected}
            onChange={onSelect}
            color="primary"
          />
        </CheckboxWrapper>
        <ContentRoot>
          <TitleLink to={`/curie/person/${person.personId}`}>{`${
            person.firstName
          } ${(!!person.middleName && `${person.middleName} `) || ""}${
            person.lastName
          }`}</TitleLink>
          {activeAffiliation !== null && (
            <div key={`affiliation-${activeAffiliation.id}`}>
              <PersonDataText>
                {activeAffiliation.titles.join(", ")}
              </PersonDataText>
              <PersonDataText>
                {activeAffiliation.institution.name}{" "}
                <OtherInstitutionsTooltip
                  affiliations={_.uniqBy(
                    affiliations.filter(
                      i =>
                        i.institution.name !==
                        activeAffiliation.institution.name
                    ),
                    i => i.institution.name
                  )}
                />
              </PersonDataText>
            </div>
          )}
          {locationString !== "" && (
            <PersonDataText
              key={`${person.personId}-locationString-${locationString}`}
            >
              {locationString}
            </PersonDataText>
          )}
          <InfoWrapper>
            {person.specialty.length > 0 && (
              <InfoColumn>
                <InfoColumnLabel>Specialty</InfoColumnLabel>
                <BoldText>{person.specialty.join(", ")}</BoldText>
              </InfoColumn>
            )}
            {worksCount > 0 && (
              <InfoColumn>
                <InfoColumnLabel>Works</InfoColumnLabel>
                <div>
                  <Text>{worksCount}</Text>
                </div>
              </InfoColumn>
            )}
            <InfoColumn>
              <ListButton
                personId={person.personId}
                firstName={person.firstName}
                lastName={person.lastName}
              />
            </InfoColumn>
          </InfoWrapper>
          <TagManager personId={person.personId} />
        </ContentRoot>
        <WorksRoot>
          <>
            {kolCanRequestInfo(person) && (
              <div
                style={{
                  display: "flex",
                  alignSelf: "flex-end",
                  marginBottom: 20,
                  width: "100%"
                }}
              >
                <RequestKOLDataButton
                  personId={person.personId}
                  kolDataRequests={person.kolDataRequests}
                />
              </div>
            )}
            {person.countPublications > 0 && (
              <WorksLink
                to={`/curie/person/${person.personId}/publications`}
                label="Publications"
                value={formatStats(person.countPublications)}
                color="publications"
              />
            )}
            {person.countClinicalTrials > 0 && (
              <WorksLink
                to={`/curie/person/${person.personId}/clinical-trials`}
                label="Clinical Trials"
                value={formatStats(person.countClinicalTrials)}
                color="trials"
              />
            )}
            {person.congresses > 0 && (
              <WorksLink
                to={`/curie/person/${person.personId}/congresses`}
                label="Congresses"
                value={formatStats(person.congresses)}
                color="congresses"
              />
            )}
            {person.sumPayments > 0 && (
              <WorksLink
                to={`/curie/person/${person.personId}/payments`}
                label="Payments"
                value={`$${formatStats(person.sumPayments)}`}
                color="payments"
              />
            )}
            {person.sumGrants > 0 && (
              <WorksLink
                to={`/curie/person/${person.personId}/grants`}
                label="Grants"
                value={`$${formatStats(person.sumGrants)}`}
                color="grants"
              />
            )}
          </>
        </WorksRoot>
      </CardRoot>
    </Card>
  );
};

export const SavedListCard = SavedListCardComponent;
