/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { connect } from "react-redux";
import { pageCount, getItemRangeInPage } from "@h1eng/pagination";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Checkbox from "@material-ui/core/Checkbox";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import { RootState } from "../../../store/reducers";
import {
  setKOLExportIds,
  clearKOLExportIds
} from "../../../store/actions/dataExports";

const styles = (theme: Theme) =>
  createStyles({
    snackbarContentRoot: {
      backgroundColor: theme.palette.primary.main,
      flexWrap: "inherit"
    },
    snackbarContentMessage: {
      color: theme.palette.primary.contrastText,
      fontWeight: 500
    },
    selectButton: {
      backgroundColor: "#7061FF",
      color: "inherit"
    },
    clearButton: {
      color: "inherit",
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "underline"
      }
    }
  });

interface MappedStateProps {
  /**
   * The currently selected IDs
   */
  selectedIds: string[];
}

interface DispatchProps {
  /**
   * Set the selected IDs
   */
  setSelectedIds: (selectedIds: string[]) => void;
  clearSelectedIds: () => void;
}

interface ComponentProps {
  /**
   * The list of all possible IDs
   */
  ids: string[];
  /**
   * The current page
   */
  pageNum: number;
  /**
   * The results per page
   */
  pageSize: number;
  disabled?: boolean;
}

/**
 * Handles the pagination and returns an array that is a subset of the total ids
 */
function getCurrentIds(
  ids: string[],
  {
    pageNum,
    pageSize
  }: {
    pageNum: number;
    pageSize: number;
  }
) {
  const [start, end] = getItemRangeInPage({
    pageNum,
    pageSize,
    count: ids.length
  });

  return ids.slice(start - 1, end);
}

type Props = MappedStateProps &
  DispatchProps &
  ComponentProps &
  WithStyles<typeof styles>;

const SelectAllKolCheckboxComponent: React.FunctionComponent<Props> = ({
  ids,
  selectedIds,
  setSelectedIds,
  clearSelectedIds,
  pageNum,
  pageSize,
  disabled = false,
  classes
}: Props) => {
  const currentIds = getCurrentIds(ids, { pageNum, pageSize });
  React.useEffect(() => {
    return () => {
      clearSelectedIds();
    };
  }, []);

  const allUniqueIds = [...new Set(ids)];

  const followingIds = allUniqueIds.slice(
    pageNum * pageSize,
    pageNum * pageSize + 500
  );

  const allSelected =
    selectedIds.length > 0 &&
    followingIds.length === [...new Set(selectedIds)].length;

  const allCurrentSelected =
    selectedIds.length > 0 &&
    currentIds.every(i => selectedIds.indexOf(i) > -1);

  const indeterminate =
    selectedIds.length > 0 &&
    !allCurrentSelected &&
    currentIds.some(i => selectedIds.indexOf(i) > -1);

  const actionButton = allSelected ? (
    <Button
      key="clear-selection"
      // color="secondary"
      className={classes.clearButton}
      size="small"
      onClick={() => {
        clearSelectedIds();
      }}
    >
      Clear selection
    </Button>
  ) : (
    <Button
      variant="contained"
      className={classes.selectButton}
      key="set-selection"
      color="primary"
      size="small"
      onClick={() => {
        setSelectedIds(followingIds);
      }}
    >
      Select following {followingIds.length} profile results
    </Button>
  );

  function handleCheckboxChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    if (checked && !indeterminate) {
      setSelectedIds(currentIds);
    } else {
      clearSelectedIds();
    }
  }

  const lastPage = pageCount({ count: ids.length, pageSize }) - 1;

  const snackbarOpen = !disabled && allCurrentSelected && pageNum < lastPage;

  return (
    <>
      <Snackbar
        key="select-all-kol-snackbar"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={snackbarOpen}
        message={
          <span id="message-id">
            {allSelected
              ? `The following ${followingIds.length} profiles are selected`
              : `All ${currentIds.length} profiles on this page selected`}
          </span>
        }
        action={[actionButton]}
        ContentProps={{
          classes: {
            root: classes.snackbarContentRoot,
            message: classes.snackbarContentMessage
          }
        }}
      />

      <Checkbox
        checked={allCurrentSelected}
        indeterminate={indeterminate}
        color="primary"
        disabled={disabled}
        onChange={handleCheckboxChange}
      />
    </>
  );
};

const mapStateToProps = (state: RootState): MappedStateProps => ({
  selectedIds: state.dataExports.kols
});

const mapDispatchToProps: DispatchProps = {
  setSelectedIds: setKOLExportIds,
  clearSelectedIds: clearKOLExportIds
};

export const SelectAllKolCheckbox = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SelectAllKolCheckboxComponent));
