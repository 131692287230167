/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { KOLDataRequest } from "@h1eng/interfaces";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RequestKOLDataModal } from "../../../common/RequestKOLDataModal";
import {
  setKolDataRequestPersonId,
  setRequestKolDataModalOpen
} from "../../../../../store/actions";
import { HoverTooltip } from "../../../../Tooltip";
import { RootState } from "../../../../../store/reducers/index";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "fit-content",
      width: "100%",
      maxWidth: 225
    },
    tooltipWrapper: {
      position: "relative",
      width: "100%"
    },
    tooltipContent: {
      color: "#fff",
      fontFamily: theme.typography.fontFamily,
      fontSize: 14,
      lineHeight: "18px"
    },
    userHasRequestedButton: {
      cursor: "default",
      width: "100%",
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    containedRequestedButton: {
      boxShadow: "none",
      width: "100%",
      "&:hover": {
        backgroundColor: "#e0e0e0"
      },
      "&:active": {
        boxShadow: "none"
      }
    },
    progress: {
      marginRight: theme.spacing.unit
    }
  });

interface ComponentProps {
  personId: string;
  kolDataRequests: KOLDataRequest[];
  variant?: "outlined" | "contained";
  onConfirm?: () => void;
  onCancel?: () => void;
}

interface MappedStateProps {
  userId: string;
  selectedPersonId: string | null;
  modalOpen: boolean;
  requestsLoading: boolean;
}

interface DispatchProps {
  setRequestDataPersonId: (personId: string | null) => void;
  setModalOpen: (open: boolean) => void;
}

type Props = ComponentProps &
  MappedStateProps &
  DispatchProps &
  WithStyles<typeof styles>;

function getUserHasRequested(requests: KOLDataRequest[], userId: string) {
  return Boolean(requests.find(i => i.userId === userId));
}

function getTooltipContent(userHasRequested: boolean) {
  let tooltipContent = "A full profile creation is in progress; ";

  if (userHasRequested) {
    tooltipContent += "you will be ";
  } else {
    tooltipContent += "click here to be ";
  }
  tooltipContent += "notified via email when the profile is completed.";

  return tooltipContent;
}

const RequestKOLDataButtonComponent: React.FunctionComponent<Props> = ({
  personId,
  kolDataRequests,
  setRequestDataPersonId,
  userId,
  classes,
  selectedPersonId,
  setModalOpen,
  requestsLoading,
  variant = "outlined",
  onConfirm = () => {},
  onCancel = () => {}
}) => {
  const isLoading = requestsLoading && personId === selectedPersonId;

  const [userHasRequested, setUserHasRequested] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);

  React.useEffect(() => {
    const requested = getUserHasRequested(kolDataRequests, userId);
    setUserHasRequested(requested);
  });

  const tooltipContent = getTooltipContent(userHasRequested);

  const handleOpenModalButtonClick = () => {
    if (clicked) return;
    setRequestDataPersonId(personId);
    setModalOpen(true);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleConfirm = () => {
    onConfirm();
    setClicked(true);
  };

  const progress = isLoading ? (
    <CircularProgress
      style={{ width: 16, height: 16 }}
      className={classes.progress}
      color="inherit"
    />
  ) : null;

  return (
    <div className={classes.root}>
      {kolDataRequests.length > 0 ? (
        <div className={classes.tooltipWrapper}>
          <HoverTooltip
            style={{
              width: 209
            }}
            content={
              <div className={classes.tooltipContent}>{tooltipContent}</div>
            }
          >
            <Button
              onClick={
                userHasRequested ? undefined : handleOpenModalButtonClick
              }
              disableRipple={userHasRequested}
              className={classNames({
                [classes.userHasRequestedButton]: userHasRequested,
                [classes.containedRequestedButton]: variant === "contained"
              })}
              variant={variant}
            >
              {progress} Info Requested
            </Button>
          </HoverTooltip>
        </div>
      ) : (
        <Button
          style={{ width: "100%" }}
          color="primary"
          variant={variant}
          onClick={handleOpenModalButtonClick}
        >
          {progress} Request Profile Info
        </Button>
      )}
      {selectedPersonId === personId && (
        <RequestKOLDataModal
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState): MappedStateProps => ({
  userId: state.user.user!.id,
  selectedPersonId: state.userInfoRequest.requestKolData.personId,
  modalOpen: state.userInfoRequest.requestKolData.modalOpen,
  requestsLoading: state.userInfoRequest.requestKolData.requestsLoading
});

const mapDispatchToProps: DispatchProps = {
  setRequestDataPersonId: setKolDataRequestPersonId,
  setModalOpen: setRequestKolDataModalOpen
};

export const RequestKOLDataButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RequestKOLDataButtonComponent));
