import * as React from "react";
import { themeColors, styled } from "@h1eng/ui-components";

import { withRouter, RouteComponentProps } from "react-router";
import { UserVO } from "../../models/UserVO";
import { NavBarVariant } from "./NavBarVariant";
import { NavbarDropdown } from "./NavbarDropdown";

import { H1Logo } from "./H1Logo";
import { PrimaryNavLink } from "./PrimaryNavLink";
import { DropDownLink } from "./DropDownLink";

const Background = styled.div`
  background-color: ${(props: any) => props.color};
  min-width: 1024px;
`;

const LockedWidthContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0px 20px;
`;

const CONTENT = styled.div<{
  underlineColor?: string;
  underline?: boolean;
}>`
  position: relative;
  display: flex;
  height: 65px;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${(props: any) =>
    props.underline ? `0px 1px 0px ${props.underlineColor}` : "none"};
  z-index: 1;
`;

const LOGGED_IN_RIGHT_CONTENT = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const LOGGED_IN_LEFT_CONTENT = styled.div`
  display: flex;
  position: absolute;
  left: 100px;
`;

interface NavBarProps {
  variant?: NavBarVariant;
  user: UserVO;
  underline?: boolean;
}

interface NavBarState {
  productDropdownOpen: boolean;
}

const backgroundColors = {
  [NavBarVariant.DEFAULT]: "white",
  [NavBarVariant.GRAY]: themeColors.themeBackDropColor,
  [NavBarVariant.DARK]: themeColors.themeBackgroundPurple
};

const underlineColors = {
  [NavBarVariant.DEFAULT]: themeColors.themeBackgroundPurple,
  [NavBarVariant.GRAY]: "#d8d8d8",
  [NavBarVariant.DARK]: "#ecf0f1"
};

// const AVATAR = styled.img`
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
// `;

const HELP_LINK = styled.a`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 0px;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #737373;
  border-radius: 50%;
  &:hover {
    color: #333;
  }
`;

class NavBarClass extends React.Component<
  NavBarProps & RouteComponentProps<any>,
  NavBarState
> {
  state = {
    productDropdownOpen: true
  };

  toggleProductDropdown = () => {
    this.setState({ productDropdownOpen: !this.state.productDropdownOpen });
  };

  render() {
    const { location } = this.props;
    const variant = this.props.variant || NavBarVariant.DEFAULT;

    const leftContent = (
      <LOGGED_IN_LEFT_CONTENT>
        {[["/curie/search", "Results"], ["/saved-lists", "Saved Lists"]].map(
          (routes: string[], index: number) => (
            <PrimaryNavLink
              key={index}
              active={location.pathname.includes(routes[0])}
              variant={variant}
              to={routes[0]}
            >
              {routes[1]}
            </PrimaryNavLink>
          )
        )}
      </LOGGED_IN_LEFT_CONTENT>
    );
    //    const handleLogout = () => {};
    const rightContent = (
      <LOGGED_IN_RIGHT_CONTENT>
        {/* <AVATAR
          style={{ marginRight: "15px" }}
          src={this.props.user.imageUrl}
        /> */}
        <NavbarDropdown
          variant={variant}
          title={this.props.user.firstName + " " + this.props.user.lastName}
        >
          <HELP_LINK
            target="_blank"
            href="https://h1insights.zendesk.com/hc/en-us"
          >
            Help Center
          </HELP_LINK>
          <DropDownLink
            className="log-out-dropdown-link"
            variant={variant}
            to="/logout"
          >
            Log Out
          </DropDownLink>
        </NavbarDropdown>
      </LOGGED_IN_RIGHT_CONTENT>
    );

    return (
      <Background color={backgroundColors[variant]}>
        <LockedWidthContainer>
          <CONTENT
            underline={this.props.underline}
            underlineColor={underlineColors[variant]}
          >
            <H1Logo to="/" variant={variant} />

            {leftContent}
            {rightContent}
          </CONTENT>
        </LockedWidthContainer>
      </Background>
    );
  }
}

export const NavBar = withRouter(NavBarClass);
