import * as React from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#060464",
      dark: "#060464"
    },
    secondary: {
      main: "#0aaacd"
    },
    text: {
      primary: "#333"
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: "Montserrat"
  },
  overrides: {
    MuiSelect: {
      selectMenu: {
        borderBottom: "2px solid #060464",
        fontSize: 14,
        fontWeight: 500,
        "&:focus": {
          backgroundColor: "inherit"
        }
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: 14,
        height: 18,
        fontWeight: 500
      },
      gutters: {
        paddingLeft: 12,
        paddingRight: 12
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        color: "#060464"
      }
    },
    MuiButton: {
      root: {
        textTransform: "unset"
      },
      containedPrimary: {
        "&:hover": {
          backgroundColor: "#9190cf"
        }
      },
      outlined: {
        borderWidth: 2,
        "&:hover": {
          borderWidth: 2
        }
      },
      outlinedPrimary: {
        borderWidth: 2,
        "&:hover": {
          borderWidth: 2
        }
      }
    },
    MuiTab: {
      root: {
        textTransform: "unset"
      }
    },
    MuiDialogActions: {
      root: {
        margin: "8px 24px",
        flexDirection: "column"
      },
      action: {
        "&:last-child": {
          marginRight: 0
        }
      }
    },
    MuiTypography: {
      h4: {
        fontSize: 18,
        fontWeight: "bold",
        lineHeight: "22px"
      }
    }
  }
});

export const ThemeProvider: React.FunctionComponent = props => (
  <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
);
