/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { styled } from "@h1eng/ui-components";
import { ClickableTooltip } from "../../../Tooltip";
import { connect } from "react-redux";
import {
  createList,
  addToList,
  ListManipulationInterface,
  removePeopleFromList
} from "../../../../store/actions";
import { RootState } from "../../../../store/reducers";
import {
  currentUser,
  getSelectedProject,
  getSelectedListId,
  getAvailableLists,
  getLists
} from "../../../../store/selectors";
import { UserVO } from "../../../../models/UserVO";
import { ListVO } from "../../../../models/ListVO";
import {
  createGrowler,
  GrowlerInterface
} from "../../../../store/actions/growler";
import { GrowlerTypes } from "../../../growler/GrowlerTypes";
import { ListMeta } from "../ListManagementInterfaces";
import IconButton from "@material-ui/core/IconButton";
import Bookmark from "@material-ui/icons/BookmarkBorderOutlined";
import { AvailableListsMenu, NewListForm } from "./ListTooltip";
import { ListCount } from "./ListCount";

interface ListButtonProps {
  personId: string;
  firstName: string;
  lastName: string;
}

interface ListButtonDispatchProps {
  createList: (list: ListVO) => void;
  addToList: (opts: ListManipulationInterface) => void;
  createNewGrowler: (opts: GrowlerInterface) => void;
  removeFromList: (opts: ListManipulationInterface) => void;
  getLists: { [key: string]: { personLists: ListMeta[] } };
  listsAvailable: ListMeta[];
  user: UserVO;
  projectId: string | null;
  selectedListId: string | null;
}

interface ListButtonState {
  listName: string;
  tooltipOpen: boolean;
  showNewListForm: boolean;
  showNewListSuccess: boolean;
}

type Props = ListButtonProps & ListButtonDispatchProps;

const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonRoot = styled.div`
  position: relative;
  display: inline-block;
`;

export class ListButtonClass extends React.Component<Props, ListButtonState> {
  state = {
    listName: "",
    tooltipOpen: false,
    showNewListForm: false,
    showNewListSuccess: false
  };

  /**
   * Handles the name change in the new list form
   */
  handleListNameFormChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({ listName: e.currentTarget.value });
  };

  /**
   * Handles creating a new list
   */
  handleCreateList = (e?: React.SyntheticEvent<HTMLElement>) => {
    if (this.state.listName.trim() === "") {
      alert("Please enter a valid list name.");
    } else {
      this.props.createNewGrowler({
        title: "Saved to new List",
        description: "This person was saved to a list",
        growler: GrowlerTypes.success,
        titleIcon: "check"
      });
      const projectId = this.props.projectId as string;
      const params: ListVO = {
        personIds: [this.props.personId],
        listName: this.state.listName,
        projectId,
        userId: this.props.user.id,
        firstName: this.props.firstName,
        lastName: this.props.lastName
      };
      this.props.createList(params);
    }

    this.setState({ showNewListForm: false, showNewListSuccess: true });
  };

  setTooltipOpen = (tooltipOpen: boolean) => (
    event?: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    this.setState({ tooltipOpen });
  };

  /***
   * Handles setting of whether or not to render the new list form in the tooltip
   */
  setShowNewListForm = (showNewListForm: boolean) => (
    event?: React.SyntheticEvent<HTMLElement>
  ) => {
    this.setState({ showNewListForm });
  };

  /**
   * Handles the removal of a list
   */
  removeList = (event?: React.SyntheticEvent<HTMLElement>) => {
    this.props.createNewGrowler({
      title: `Profile Removed`,
      description: `The profile was removed`,
      growler: GrowlerTypes.success,
      titleIcon: "check"
    });

    this.setState(
      {
        tooltipOpen: false
      },
      () => {
        if (!this.props.selectedListId) return;

        this.props.removeFromList({
          personIds: [this.props.personId],
          listId: this.props.selectedListId
        });
      }
    );
  };

  /**
   * The component to render in the tooltip when a new list is created
   */
  getListCreatedConfirmation = () => {
    this.setState({
      tooltipOpen: false,
      showNewListForm: false,
      showNewListSuccess: false
    });

    return <></>;
  };

  addToList = (opts: ListManipulationInterface) => {
    this.setState({ tooltipOpen: false }, () => {
      this.props.createNewGrowler({
        title: "Saved to List",
        description: "This person was saved to a list",
        growler: GrowlerTypes.success,
        titleIcon: "check"
      });
      this.props.addToList(opts);
    });
  };

  /**
   * Getter for tooltip content based on state
   */
  get tooltipContent() {
    let content: React.ReactChild;

    if (this.state.showNewListForm) {
      content = (
        <NewListForm
          createList={this.handleCreateList}
          onListNameChange={this.handleListNameFormChange}
          listName={this.state.listName}
        />
      );
    } else if (this.state.showNewListSuccess) {
      content = this.getListCreatedConfirmation();
    } else {
      content = (
        <AvailableListsMenu
          listsAvailable={this.props.listsAvailable}
          personId={this.props.personId}
          selectedListId={this.props.selectedListId}
          removeList={this.removeList}
          addToList={this.addToList}
          showNewListForm={this.setShowNewListForm(true)}
        />
      );
    }

    return <div style={{ minWidth: "150px" }}>{content}</div>;
  }

  render() {
    const { tooltipContent, lists } = this;

    return (
      <Root>
        <ButtonRoot>
          <ClickableTooltip
            content={tooltipContent}
            open={this.state.tooltipOpen}
            style={{
              backgroundColor: "#FAFAFA",
              boxShadow:
                "0 0 8px 0 rgba(0,0,0,0.12), 0 8px 8px 0 rgba(0,0,0,0.24)",
              zIndex: 5000
            }}
            handleClose={() =>
              this.setState({
                tooltipOpen: false,
                showNewListForm: false,
                showNewListSuccess: false
              })
            }
          >
            <div style={{ color: "#0aaacd" }}>
              <IconButton onClick={this.setTooltipOpen(true)} color="inherit">
                <Bookmark />
              </IconButton>
            </div>
          </ClickableTooltip>
        </ButtonRoot>
        <ListCount personId={this.props.personId} />
      </Root>
    );
  }
}

export const mapStateToProps = (
  state: RootState
): Partial<ListButtonDispatchProps> => ({
  user: currentUser(state)!,
  projectId: getSelectedProject(state)!,
  listsAvailable: getAvailableLists(state),
  selectedListId: getSelectedListId(state),
  getLists: getLists(state)
});

export const mapDispatchToProps: Partial<ListButtonDispatchProps> = {
  createList: createList.request,
  addToList: addToList.request,
  createNewGrowler: createGrowler,
  removeFromList: removePeopleFromList.request
};

export const ListButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListButtonClass);
