/* tslint:disable:react-unused-props-and-state cyclomatic-complexity */
import * as React from "react";
import { styled } from "@h1eng/ui-components";

interface Props {
  label: string;
  total: number;
  value: number;
  statDescriptor: string;
  percentile: number;
  formatter?: (num: number) => string;
  valueFootnote?: string;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
  white-space: nowrap;
`;

const StatsWrapper = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 6px 10px;
`;

const StatValue = styled.span`
  color: #333333;
  font-family: Montserrat;
  font-size: 12px;
  line-height: 15px;
`;

const StatLabel = styled(StatValue)`
  font-weight: 600;
`;

const Footnote = styled.div`
  font-family: Montserrat;
  color: rgba(0, 0, 0, 0.87);
  font-size: 10px;
  padding-top: 12px;
  position: relative;
  margin-left: 5px;
  &:before {
    content: "*";
    position: absolute;
    left: -5px;
  }
`;

export const TooltipContent: React.SFC<Props> = ({
  label,
  total,
  value,
  formatter,
  statDescriptor,
  valueFootnote
  // percentile
}) => {
  return (
    <Root>
      <Title>
        {label}
        {Boolean(valueFootnote) && " *"}
      </Title>
      <StatsWrapper>
        <StatLabel>{(!!formatter && formatter(value)) || value}</StatLabel>
        <StatValue>This researcher's {statDescriptor}</StatValue>
        <StatLabel>{(!!formatter && formatter(total)) || total}</StatLabel>
        <StatValue>Top researcher's {statDescriptor}</StatValue>
        {/* <StatLabel>{ordinalNumber(percentile)}</StatLabel>
        <StatValue>percentile</StatValue> */}
      </StatsWrapper>
      {Boolean(valueFootnote) && <Footnote>{valueFootnote}</Footnote>}
    </Root>
  );
};
