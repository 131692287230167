/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { ResultCardData } from "@h1eng/interfaces";
import { H5, B2, B1, styled, L2 } from "@h1eng/ui-components";
import { TABLEPARENT } from "./TableLayout";
import { ListManager } from "../ListManagement/ListManager";
import { HoverTooltip } from "../../Tooltip";
import { SubViews, pathForSubview } from "../Profile/Profile";
import { formatStats } from "@h1eng/format-util";
import * as _ from "lodash";
import { Checkbox } from "@material-ui/core";
import { ExcelExportButton } from "../common/ExcelExportButton";

const RESULT_CONTAINER = styled(TABLEPARENT)`
  border: solid 0.5px #ecf0f1;
  background-color: white;
  padding: 10px;
`;

const RESULT_COL = styled.div`
  grid-column-start: auto;
  grid-column-end: auto;
  position: relative;
  min-height: 125px;
`;

const ARTIFACTS_COUNT_CONTAINER = styled.div`
  bottom: -5px;
  position: absolute;
  display: inline-block;
  min-width: 700px;
`;

const ARTIFACT_ITEM = styled.div`
  display: inline-block;
  margin-right: 20px;
`;

const MEMBER_LIST_WRAPPER = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

interface ResultCardProps extends ResultCardData {
  selected: boolean;
  countPublications: number;
  personId: string;
  lists: any[];
  selectedListId?: string;
  listsAvailable: any[];
  onSelect: (personId: string) => void;
  children?: any;
}

const images: any = {
  saved: "https://d10ba5vkyum61s.cloudfront.net/img/list-management/saved.svg",
  download: "https://d10ba5vkyum61s.cloudfront.net/img/download.svg",
  trendGraph: "https://d10ba5vkyum61s.cloudfront.net/img/trend-graph.png"
};

// const TREND_GRAPH_HELPER = (props: any) => {
//   if (props && props.scores && props.scores.length) {
//     return (
//       <TrendGraph personId={props.personId} renderSearch={props.isInList} />
//     );
//   }

//   return <> </>;
// };

export const ListsForPersonIndicator = (props: any) => {
  const { listsForPerson } = props;

  const filteredList = listsForPerson.filter((e: any) => {
    const isMe = _.includes(
      _.flatMap(e.listPersonJoins, p => p.personId),
      props.personId
    );

    if (!props.selectedListId && isMe) {
      return true;
    }
    return e.id !== props.selectedListId && isMe;
  });

  const check = (filteredList || []).some((e: any) => {
    if (!props.selectedListId) {
      return false;
    }
    return e.id === props.selectedListId;
  });

  if (check || filteredList.length === 0) {
    return <></>;
  }

  const content = (
    <div style={{ width: "150px" }}>
      <B1 darkBackground>Also saved to:</B1>
      {filteredList.map((list: any) => {
        return (
          <B2 darkBackground key={list.id}>
            {list.title}
          </B2>
        );
      })}
    </div>
  );

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <HoverTooltip content={content}>
        <MEMBER_LIST_WRAPPER>
          <B1 deEmphasized small>
            <img style={{ marginRight: "5px" }} src={images.saved} />
            <span>{filteredList.length} lists</span>
          </B1>
        </MEMBER_LIST_WRAPPER>
      </HoverTooltip>
    </div>
  );
};

export class KOLResultCard extends React.PureComponent<ResultCardProps, any> {
  render() {
    const scoreExplanation = (
      <div style={{ width: "250px", zIndex: 100000 }}>
        <B2 darkBackground>Influencing the person's score:</B2>
        <br />
        <B2 darkBackground small>
          <b>{this.props.countClinicalTrials}</b> Clinical trials
        </B2>
        <B2 darkBackground small>
          <b>{this.props.countPublications}</b> Journal Articles
        </B2>
      </div>
    );

    return (
      <RESULT_CONTAINER>
        <RESULT_COL>
          <Checkbox
            style={{ color: "black", paddingLeft: "10px", marginTop: "-10px" }}
            type="checkbox"
            checked={this.props.selected}
            onChange={() => this.props.onSelect(this.props.personId)}
          />
        </RESULT_COL>
        <RESULT_COL>
          <L2 to={`/curie/person/${this.props.personId}`} size={"18px"}>
            {this.props.firstName} {this.props.middleName} {this.props.lastName}
          </L2>
          <B2>{this.props.title}</B2>
          <B2>{this.props.organization}</B2>

          <ARTIFACTS_COUNT_CONTAINER>
            {this.props.countClinicalTrials > 0 && (
              <ARTIFACT_ITEM>
                <L2
                  to={`/curie/person/${this.props.personId}/${pathForSubview(
                    SubViews.CLINICAL
                  )}`}
                  weight={300}
                  size={"12px"}
                >
                  <b>{this.props.countClinicalTrials.toLocaleString()}</b>{" "}
                  Clinical Trials
                </L2>
              </ARTIFACT_ITEM>
            )}
            {this.props.countPublications > 0 && (
              <ARTIFACT_ITEM>
                <L2
                  to={`/curie/person/${this.props.personId}/${pathForSubview(
                    SubViews.PUBLICATIONS
                  )}`}
                  weight={300}
                  size={"12px"}
                >
                  <b>{this.props.countPublications}</b> Publications
                </L2>
              </ARTIFACT_ITEM>
            )}
            {this.props.sumPayments > 0 && (
              <ARTIFACT_ITEM>
                <L2
                  to={`/curie/person/${this.props.personId}/${pathForSubview(
                    SubViews.PAYMENTS
                  )}`}
                  weight={300}
                  size={"12px"}
                >
                  <b>{`$${formatStats(this.props.sumPayments)}`}</b> Payments
                </L2>
              </ARTIFACT_ITEM>
            )}
            {this.props.grants > 0 && (
              <ARTIFACT_ITEM>
                <L2
                  to={`/curie/person/${this.props.personId}/${pathForSubview(
                    SubViews.GRANTS
                  )}`}
                  weight={300}
                  size={"12px"}
                >
                  <b>{`${this.props.grants}`}</b> Grants
                </L2>
              </ARTIFACT_ITEM>
            )}
            {this.props.congresses > 0 && (
              <ARTIFACT_ITEM>
                <L2
                  to={`/curie/person/${this.props.personId}/${pathForSubview(
                    SubViews.CONGRESSES
                  )}`}
                  weight={300}
                  size={"12px"}
                >
                  <b>{`${this.props.congresses}`}</b> Congresses
                </L2>
              </ARTIFACT_ITEM>
            )}
          </ARTIFACTS_COUNT_CONTAINER>
        </RESULT_COL>
        <RESULT_COL>
          <H5>{this.props.location}</H5>
        </RESULT_COL>
        <RESULT_COL>
          <div
            style={{
              position: "relative",
              display: "inline-block"
            }}
          >
            <HoverTooltip content={scoreExplanation}>
              <H5>{this.props.score}</H5>
            </HoverTooltip>
          </div>
        </RESULT_COL>
        {/* <RESULT_COL>{TREND_GRAPH_HELPER(this.props)}</RESULT_COL> */}
        <RESULT_COL>
          <div style={{ display: "flex", justifyItems: "center" }}>
            <ExcelExportButton ids={[this.props.personId]} />
            <ListManager
              personId={this.props.personId}
              active
              firstName={this.props.firstName}
              lastName={this.props.lastName}
            />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "0",
              left: "10px"
            }}
          >
            <ListsForPersonIndicator
              personId={this.props.personId}
              listsForPerson={this.props.listsAvailable}
              selectedListId={this.props.selectedListId}
            />
          </div>
        </RESULT_COL>
      </RESULT_CONTAINER>
    );
  }
}
