import { SortBy, ScoredDocumentData } from "@h1eng/interfaces";

export const sortOptionEnabled = ({
  persons,
  sortBy
}: {
  persons: ScoredDocumentData[];
  sortBy: SortBy;
}) => {
  if (typeof sortBy !== "string") return true;

  return persons.length > 0 && persons.some(person => person[sortBy].value > 0);
};
