/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import {
  SlackKOLRequestPayload,
  SlackKOLRequestPayloadValidationErrors
} from "@h1eng/interfaces";
import { DialogTitle } from "@material-ui/core";
import { RootState } from "../../../../store/reducers";
import {
  setRequestedKolModalOpen,
  setRequestedKolData,
  requestKol
} from "../../../../store/actions";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    openButton: {
      width: 225,
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex"
    },
    dialogPaper: {
      [theme.breakpoints.up("sm")]: {
        maxWidth: 472,
        flexGrow: 1
      }
    },
    dialogTitle: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      fontFamily: "Montserrat",
      fontSize: 20,
      lineHeight: "24px",
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
      paddingLeft: 27,
      paddingRight: 20,
      paddingTop: 0,
      paddingBottom: 0,
      height: 51
    },
    closeIconButton: {
      marginLeft: "auto"
    },
    dialogContent: {
      paddingTop: 35,
      textAlign: "center"
    },
    contentTextWrapper: {
      maxWidth: 348,
      marginLeft: "auto",
      marginRight: "auto"
    },
    contentText: {
      color: "#333",
      fontFamily: theme.typography.fontFamily,
      fontSize: 14,
      lineHeight: "18px",
      textAlign: "center"
    },
    form: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "auto",
      marginRight: "auto",
      textAlign: "unset",
      maxWidth: 220
    },
    textField: {
      marginTop: 9
    },
    submitButton: {
      fontWeight: 600
    },
    disabledSubmit: {
      opacity: 0.5
    },
    cancelButton: {
      fontWeight: "bold"
    }
  });

interface MappedStateProps {
  modalOpen: boolean;
  requestedKol: SlackKOLRequestPayload;
  errors: SlackKOLRequestPayloadValidationErrors | false;
  requestLoading: boolean;
}

interface DispatchProps {
  setModalOpen: (modalOpen: boolean) => void;
  setKolData: (kol: SlackKOLRequestPayload) => void;
  postKolRequest: (kol: SlackKOLRequestPayload) => void;
}

type Props = MappedStateProps & DispatchProps & WithStyles<typeof styles>;

// const newRequestedKol: SlackKOLRequestPayload = {
//   firstName: "",
//   lastName: "",
//   jobTitle: "",
//   institution: ""
// };

// const defaultErrors: SlackKOLRequestPayloadValidationErrors | false = false;
function getDisabled(loading: boolean, kol: SlackKOLRequestPayload) {
  return (
    // loading ||
    kol.firstName === "" ||
    kol.lastName === "" ||
    kol.jobTitle === "" ||
    kol.institution === ""
  );
}
const RequestKOLModalComponent: React.FunctionComponent<Props> = ({
  modalOpen,
  setModalOpen,
  errors,
  requestedKol,
  requestLoading,
  setKolData,
  postKolRequest,
  classes
}) => {
  const [disabled, setDisabled] = React.useState(
    getDisabled(requestLoading, requestedKol)
  );

  React.useEffect(() => {
    setDisabled(getDisabled(requestLoading, requestedKol));
  });

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleSubmit = (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (requestLoading) return;

    postKolRequest(requestedKol);
  };

  const handleKolPropChange = (key: keyof SlackKOLRequestPayload) => (
    event: React.ChangeEvent<
      HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
    >
  ) => {
    setKolData({
      ...requestedKol,
      [key]: event.target.value
    });
  };

  const hasError = (key: keyof SlackKOLRequestPayload) => {
    if (errors === false) return false;

    return errors.indexOf(key) !== -1;
  };

  return (
    <>
      <Button
        onClick={toggleModal}
        className={classes.openButton}
        variant="outlined"
        color="primary"
      >
        Request a New Individual
      </Button>
      {modalOpen && (
        <Dialog open={modalOpen} classes={{ paper: classes.dialogPaper }}>
          <DialogTitle className={classes.dialogTitle} disableTypography>
            Request a New Individual
            <IconButton
              onClick={toggleModal}
              color="inherit"
              className={classes.closeIconButton}
            >
              <CloseIcon color="inherit" />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <div className={classes.contentTextWrapper}>
              <span className={classes.contentText}>
                Thank you for taking the time to Request a New Individual. Our
                team will investigate why this individual is not currently in H1
                Curie. We will be in touch with an explanation over the next
                24hrs after the investigation takes place.
              </span>
            </div>
            <form onSubmit={handleSubmit} className={classes.form}>
              <TextField
                autoFocus
                fullWidth
                className={classes.textField}
                value={requestedKol.firstName}
                onChange={handleKolPropChange("firstName")}
                label="First name"
                error={hasError("firstName")}
                helperText={
                  hasError("firstName") ? "First name is required" : undefined
                }
              />
              <TextField
                fullWidth
                className={classes.textField}
                value={requestedKol.lastName}
                onChange={handleKolPropChange("lastName")}
                label="Last name"
                error={hasError("lastName")}
                helperText={
                  hasError("lastName") ? "Last name is required" : undefined
                }
              />
              <TextField
                fullWidth
                className={classes.textField}
                value={requestedKol.jobTitle}
                onChange={handleKolPropChange("jobTitle")}
                label="Job title"
                error={hasError("jobTitle")}
                helperText={
                  hasError("jobTitle") ? "Job title is required" : undefined
                }
              />
              <TextField
                fullWidth
                className={classes.textField}
                value={requestedKol.institution}
                onChange={handleKolPropChange("institution")}
                label="Institution name"
                error={hasError("institution")}
                helperText={
                  hasError("institution")
                    ? "Institution name is required"
                    : undefined
                }
              />
              <input type="submit" style={{ display: "none" }} />
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              className={classNames(classes.submitButton, {
                [classes.disabledSubmit]: disabled
              })}
            >
              Request a New Individual
            </Button>
            <Button
              className={classes.cancelButton}
              role="button"
              onClick={toggleModal}
              color="secondary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState): MappedStateProps => ({
  modalOpen: state.userInfoRequest.requestedKol.modalOpen,
  requestedKol: state.userInfoRequest.requestedKol.kol,
  errors: state.userInfoRequest.requestedKol.errors,
  requestLoading: state.userInfoRequest.requestedKol.loading
});

const mapDispatchToProps: DispatchProps = {
  setModalOpen: setRequestedKolModalOpen,
  setKolData: setRequestedKolData,
  postKolRequest: requestKol.request
};

export const RequestKOLModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RequestKOLModalComponent));
