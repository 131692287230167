import * as React from "react";
import ThemedTooltip, { ThemedTooltipProps } from "./ThemedTooltip";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import HelpIcon from "@material-ui/icons/HelpOutlineRounded";

interface InfoTooltipIconProps {
  iconProps?: SvgIconProps;
  tooltipProps?: ThemedTooltipProps;
  title: React.ReactNode;
}

type Props = InfoTooltipIconProps;

export const InfoTooltipIcon: React.FunctionComponent<Props> = ({
  title,
  iconProps,
  tooltipProps
}) => {
  return (
    <ThemedTooltip title={title} {...tooltipProps}>
      <HelpIcon color="action" {...iconProps} />
    </ThemedTooltip>
  );
};
