import { createAsyncAction, createStandardAction } from "typesafe-actions";

export const setKOLExportIds = createStandardAction("dataExports.kol.setIds")<
  string[]
>();

export const clearKOLExportIds = createStandardAction(
  "dataExports.kol.clearIds"
)<never>();

export const exportKOLs = createAsyncAction(
  "dataExports.kol.export.request",
  "dataExports.kol.export.success",
  "dataExports.kol.export.failure"
)<never, any, Error>();
