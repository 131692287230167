import {
  kolCardsActionGet,
  kolSavedListsCardsActionGet,
  setKolCardsLoading,
  clearLoadingMessage,
  appendKOLDataRequestToCard,
  updateAllKOLCards,
  requestCurrentTags
} from "../actions";
import { isActionOf } from "typesafe-actions";
import {
  Epic,
  StateObservable,
  ActionsObservable,
  ofType
} from "redux-observable";
import { filter, switchMap, map, catchError } from "rxjs/operators";
import { from, of, pipe, concat, empty } from "rxjs";
import { getPersons } from "../../apis/kolCards";
import { RootState } from "../reducers";
import { KOLDataRequest, ResultCardData } from "@h1eng/interfaces";

export const fetchKolCardFlow: Epic<any, any, any> = action$ => {
  return action$.pipe(
    filter(isActionOf(kolCardsActionGet.request)),
    switchMap(action =>
      concat(
        action.payload.personIds.length > 0
          ? of(requestCurrentTags.request(action.payload.personIds))
          : empty(),
        from(
          getPersons(
            action.payload.projectId,
            action.payload.userId,
            action.payload.personIds
          )
        ).pipe(
          map(kolCardsActionGet.success),
          catchError(
            pipe(
              kolCardsActionGet.failure,
              of
            )
          )
        )
      )
    )
  );
};

export const clearLoadingMessageFlow: Epic<any, any, any> = action$ =>
  action$.pipe(
    filter(
      isActionOf([
        kolSavedListsCardsActionGet.success,
        kolCardsActionGet.success
      ])
    ),
    switchMap(() => of(clearLoadingMessage()))
  );

export const fetchKolSavedListsCardFlow: Epic<any, RootState, any> = (
  action$: any,
  state$: StateObservable<RootState>
) => {
  return action$.pipe(
    filter(isActionOf(kolSavedListsCardsActionGet.request)),
    switchMap(({ payload }) =>
      concat(
        of(requestCurrentTags.request(payload.personIds)),
        of(setKolCardsLoading(true)),
        from(
          getPersons(payload.projectId, payload.userId, payload.personIds)
        ).pipe(
          map(kolSavedListsCardsActionGet.success),
          catchError(
            pipe(
              kolSavedListsCardsActionGet.failure,
              of
            )
          )
        )
      )
    )
  );
};

export const setKOLDataRequestFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(appendKOLDataRequestToCard),
    switchMap(({ payload }: { payload: KOLDataRequest }) =>
      concat(
        of(
          updateAllKOLCards({
            cards: addKOLDataRequest(payload, state$.value.kolCards.cards),
            savedListsKolCards: addKOLDataRequest(
              payload,
              state$.value.kolCards.savedListsKolCards
            ),
            allCards: addKOLDataRequest(payload, state$.value.kolCards.allCards)
          })
        )
      )
    )
  );

function addKOLDataRequest(
  dataRequest: KOLDataRequest,
  cards: ResultCardData[]
): ResultCardData[] {
  return [...cards].map(card => {
    if (card.personId !== dataRequest.personId) return card;

    return {
      ...card,
      kolDataRequests: [...card.kolDataRequests, dataRequest]
    };
  });
}
