import * as React from "react";
import { connect } from "react-redux";
import { getPublicationsFilters } from "../../../../../store/selectors";
import {
  setPublicationTypeValues,
  setPublicationTypeQuery
} from "../../../../../store/actions";
import { RootState } from "../../../../../store/reducers";
import { FilterAutosuggest as Autosuggest } from "../../FilterAutosuggest";

interface MappedStateProps {
  options: string[];
  values: string[];
  inputValue: string;
}

interface DispatchProps {
  setValues: (values: string[]) => void;
  handleInputChange: (inputVal: string) => void;
}

type Props = MappedStateProps & DispatchProps;

class TypeFiltersComponent extends React.Component<Props> {
  render() {
    const { options, values, setValues } = this.props;

    return (
      <Autosuggest
        values={values}
        setValues={setValues}
        suggestions={options}
        inputLabel="Type"
        inputValue={this.props.inputValue}
        setInputValue={this.props.handleInputChange}
      />
    );
  }
}

const mapStateToProps = (state: RootState): MappedStateProps => {
  const { type } = getPublicationsFilters(state);

  return {
    options: type.options,
    values: type.values,
    inputValue: type.query
  };
};

const mapDispatchToProps: DispatchProps = {
  setValues: setPublicationTypeValues,
  handleInputChange: setPublicationTypeQuery
};

export const TypeFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(TypeFiltersComponent);
