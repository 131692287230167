import * as React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";

interface ComponentProps {
  pageCount: number;
  goToPage: (pageNum: number) => void;
}

type Props = ComponentProps & TextFieldProps;

export const GoToPageInput: React.FunctionComponent<Props> = ({
  pageCount,
  goToPage
}) => {
  const [pageNum, setPageNum] = React.useState("");

  const inputEl: React.MutableRefObject<null | HTMLInputElement> = React.useRef(
    null
  );

  const max = pageCount;

  const parsedNum = parseInt(pageNum, 10);

  const error = pageNum === "" ? false : parsedNum < 1 || parsedNum > max;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { current } = inputEl;
    const num = parseInt(pageNum, 10) - 1;
    if (current) {
      current.blur();
    }
    if (isNaN(num)) return;
    goToPage(num);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPageNum(value);
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <TextField
        error={error}
        helperText={error ? "Out of range" : ""}
        inputRef={inputEl}
        type="number"
        InputProps={{ inputProps: { min: 1, max } }}
        value={pageNum}
        onChange={handleInputChange}
        label="Jump to page"
        onBlur={() => {
          setPageNum("");
        }}
        fullWidth
      />
    </form>
  );
};
