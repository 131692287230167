import { connect } from "react-redux";
import {
  CheckboxFilterGroup,
  CheckboxFilterGroupMappedStateProps,
  CheckboxFilterGroupDispatchProps,
  CheckboxFilterGroupProps
} from "../../components/CheckboxFilterGroup";
import { setCongressTypeValues } from "../../../../../store/actions";
import { RootState } from "../../../../../store/reducers";

const mapStateToProps = (
  state: RootState
): CheckboxFilterGroupMappedStateProps => {
  const { options, values } = state.searchFilters.congresses.type;

  return {
    options,
    values
  };
};

const mapDispatchToProps: CheckboxFilterGroupDispatchProps = {
  setValues: setCongressTypeValues
};

export const TypeCheckboxFilters: React.ComponentType<
  CheckboxFilterGroupProps
> = connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckboxFilterGroup);
