/**
 * @TODO: remove when done with refactor
 */
// tslint:disable:class-name
export interface DEPRECATED_FilterInterface {
  type: FilterTypes;
  filterId: FilterTypesEnum;
  title: string;
  options?: any[];
  selected?: any[];
  range?: number[];
  min?: number;
  max?: number;
  maxRange?: number; // UI ONLY
  minRange?: number; // UI ONLY
  hint?: string; // e.g. placeholder
  selectedBoolean?: boolean;
  active: boolean;
}
export interface DEPRECATED_FilterSearchInterface {
  userId: string;
  query: string[];
  projectId: string;
  filters: DEPRECATED_FilterInterface;
  pageSize?: number;
  pageNum?: number;
  sortBy: SortBy;
}
// tslint:enable:class-name
export enum FilterTypes {
  DATERANGE,
  MONEYRANGE,
  MULTI,
  SINGLE,
  BOOL,
  MIN
}
export enum InputState {
  REST,
  CLICKED,
  TYPE,
  ENTER
}

export enum FilterTypesEnum {
  clinicalCurrentPhase,
  clinicalPastStages,
  clinicalCurrentStatus,
  clinicalPastStatus,
  clinicalStudyStartDate,
  clinicalPrimaryCompletionDate,
  clinicalFunderType,
  clinincalSponsor,
  institution,
  institutionType,
  therapeuticArea,
  locationRegion,
  locationCity,
  title,
  paymentNature,
  paymentCompany,
  paymentSize,
  paymentDate,
  paymentMinAmount,
  paymentDrugOrDevice,
  publicationYear,
  publicationJournal,
  publicationPmid,
  publicationType,
  publicationCitationCount,
  publicationPublisher,
  clinicalStudyInterventional,
  clinicalStudyObservational,
  clinicalPrinicipleInvestigator,
  locationState,
  publicationPublisherType,
  publicationMinCount,
  locationCountry,
  kolType,
  trialMinCount,
  trialPhase,
  trialStatus,
  trialStudyType,
  trialFunderType,
  trialSponsor,
  congressMinCount,
  congressName,
  congressType,
  congressOrganizerName,
  congressSessionType,
  grantMinAmount,
  grantFunder,
  tagName
}

// export interface FilterInterface {
//   type: FilterTypes;
//   filterId: FilterTypesEnum;
//   title: string;
//   options?: any[];
//   selected?: any[];
//   range?: number[];
//   min?: number;
//   max?: number;
//   maxRange?: number; // UI ONLY
//   minRange?: number; // UI ONLY
//   hint?: string; // e.g. placeholder
//   selectedBoolean?: boolean;
//   active: boolean;
//   // onUpdate?: (update: any) => any; // UI ONLY
// }

export interface FilterInterface {
  specialty: TextFilter;
  country: TextFilter;
  state: TextFilter;
  institution: TextFilter;
  institutionType: CheckboxFilter;
  dateRangePicker: DateRangeFilter;
  tags: {
    name: TextFilter;
  };
  publications: {
    minCount: NumberFilter;
    journal: TextFilter;
    type: TextFilter;
  };
  trials: {
    minCount: NumberFilter;
    status: TextFilter;
    phase: TextFilter;
    studyType: TextFilter;
    funderType: TextFilter;
    sponsor: TextFilter;
  };
  congresses: {
    minCount: NumberFilter;
    name: TextFilter;
    type: CheckboxFilter;
    organizerName: TextFilter;
    sessionType: TextFilter;
  };
  grants: {
    minAmount: NumberFilter;
    funder: TextFilter;
  };
  payments: {
    minAmount: NumberFilter;
    company: TextFilter;
    drugOrDevice: TextFilter;
    fundingType: TextFilter;
  };
}

export interface WeightedSortBy {
  publication: number;
  citation: number;
  trial: number;
  payment: number;
  congress: number;
  grant: number;
}

export interface CustomSortByConstructor extends WeightedSortBy {
  name: string;
}

export interface CustomSortBy extends CustomSortByConstructor {
  id: string;
  created?: number;
}
export interface DateRangeFilter {
  min: number;
  max: number;
  active: boolean | null;
}
/**
 * The weights of document types
 *
 * @TODO: grants
 */
export const DefaultScoreWeights: WeightedSortBy = {
  publication: 0.3,
  citation: 0.1,
  trial: 0.3,
  payment: 0.1,
  congress: 0.2,
  grant: 0.1
};

export type NamedSortBy =
  | "publications"
  | "trials"
  | "citations"
  | "grants"
  | "payments"
  | "congresses"
  | "collaborators";

export type SortBy = null | CustomSortBy | NamedSortBy;

export interface FilterSearchInterface {
  userId: string;
  query: string[];
  projectId: string;
  filters: FilterInterface;
  pageSize?: number;
  pageNum?: number;
  sortBy: SortBy;
  searchType: SearchTypes;
  activeFilters: ActiveFilterBreakDown;
}

export interface ActiveFilterBreakDown {
  people: boolean;
  trails: boolean;
  pubs: boolean;
  payments: boolean;
  congress: boolean;
  tags: boolean;
}

export interface FiltersPropsInterface {
  title?: string;
  filters?: FilterInterface[];
  // updateFilter?: (filters: FilterInterface[]) => void;
}

export interface FilterViewInterface {
  filters: FilterInterface[];
  title: string;
}
// @ts-ignore
export interface GenericSearchInterface {
  terms: string[];
  personId: string;
}

export interface PersonsDocumentSummarySearchInterface {
  personIds: string[];
}
export interface GetCollaboratorsInterface {
  personId: string;
  terms?: string[];
  date?: string | number | any;
}

export interface GetLiteProfileInterface {
  personIds: string[];
}
export interface GenericSearchResultInterface {
  id: string;
  type: number;
}

export enum SearchView {
  TotalWork,
  SearchedWork,
  WorkOverTime
}

export enum IndexTypes {
  person,
  publication,
  trial,
  congress,
  grant,
  payment,
  tag
}

export interface GenericFilterInterface {
  type: FilterTypesEnum;
  /**
   * The dot-delimited path within the elastic document
   */
  path: string;
  /**
   * The name of the index
   */
  index: IndexTypes;
}

export interface CheckboxFilter extends GenericFilterInterface {
  /**
   * The available options
   */
  options: string[];
  /**
   * The selected values: a subset of options
   */
  values: string[];

  /**
   * The dot-delimited path in the redux store
   */
  keyName: string;

  /**
   * The value that, if selected, signifies searching with a "must-not"
   */
  mustNotValue?: string;
}

export interface TextFilter extends CheckboxFilter {
  query: string;
}

export interface NumberFilter extends GenericFilterInterface {
  value: number | null;
}

/**
 * TextFilter or CheckboxFilter options
 * i.e. anything that has options to be selected
 */
export interface SearchFilterOptions {
  specialty: string[];
  country: string[];
  state: string[];
  institution: string[];
  institutionType?: string[];
  tags: {
    name: string[];
  };
  publications: {
    journal: string[];
    type: string[];
  };
  trials: {
    status: string[];
    phase: string[];
    studyType: string[];
    funderType: string[];
    sponsor: string[];
  };
  congresses: {
    name: string[];
    type: string[];
    organizerName: string[];
    sessionType: string[];
  };
  grants: {
    funder: string[];
  };
  payments: {
    company: string[];
    drugOrDevice: string[];
    fundingType: string[];
  };
}

export enum SearchTypes {
  KEYWORD = "Keyword",
  NAME = "Name"
}
