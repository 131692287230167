import * as React from "react";
import routes from "./routes";
import { Growlers } from "./components/growler/Growler";
import { Provider } from "react-redux";
import { configureStore } from "./store/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import Heap from "./analytics/heap";
import axios from "axios";
import { ThemeProvider } from "./Theme";
import { SearchLoadingMessageProvider } from "./components/curie/SearchLoadingMessageProvider";

const { store, persistor } = configureStore();

import { setOrigConfig, updateConfig } from "./store/actions/config-data";

const pullConfig = (rest: any) => axios.get("/deploy-tag.json").then(rest);

const repullConfig = () =>
  axios.get("/deploy-tag.json").then(({ data }) => {
    store.dispatch(updateConfig(data));
  });

pullConfig(({ data }: { data: any }) => {
  if (data.branch) {
    store.dispatch(setOrigConfig(data));
    setInterval(repullConfig, 10000);
  }
});

export default (props: any) => {
  return (
    <span>
      <Growlers store={store} />
      <ThemeProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <SearchLoadingMessageProvider>
              <Heap />
              {routes}
            </SearchLoadingMessageProvider>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </span>
  );
};
