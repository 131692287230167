interface TagOption {
  id: string;
  name: string;
  isH1: boolean;
}

export const getOptionsAndNameList = (tags: TagOption[]) => {
  const names = tags.map(i => i.name);
  const options = tags.filter(i => !i.isH1);

  return {
    names,
    options
  };
};

export const isValidTagName = ({
  inputVal,
  names
}: {
  inputVal: string;
  names: string[];
}) => {
  return (
    inputVal.trim() !== "" &&
    !names.find(i => i.toLowerCase().trim() === inputVal.toLowerCase().trim())
  );
};
