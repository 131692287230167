interface PersonCounts {
  countClinicalTrials: number;
  countPublications: number;
  grants: number;
  congresses: number;
}

export const getTotalWorksForKOL = ({
  countClinicalTrials,
  countPublications,
  grants,
  congresses
}: PersonCounts): number =>
  countClinicalTrials + countPublications + grants + congresses;

export const kolCanRequestInfo = ({
  infoRequestsResolved,
  ...counts
}: PersonCounts & { infoRequestsResolved: boolean | null }): boolean => {
  if (infoRequestsResolved === true) return false;

  return getTotalWorksForKOL(counts) === 0;
};
