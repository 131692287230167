import { ENDPOINTS } from "./api-endpoints";
import {
  GetLiteProfileInterface,
  LiteProfile,
  SlackKOLRequestPayload,
  SlackKOLRequestPayloadValidationErrors,
  PersonKOLDataRequests,
  KOLDataRequest,
  UserNotFoundError
} from "@h1eng/interfaces";

export const createKolRequest = async (payload: SlackKOLRequestPayload) => {
  const res = await fetch(ENDPOINTS.requestKol, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify(payload)
  });

  const data: {
    errors: SlackKOLRequestPayloadValidationErrors | false;
  } = await res.json();

  return data;
};

export const createKolDataRequest = async (personId: string) => {
  const req = await fetch(`${ENDPOINTS.requestKolData}/${personId}`, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    }
  });

  if (req.status === 404) {
    throw new UserNotFoundError();
  } else if (req.status !== 200) {
    throw new Error(req.statusText);
  }

  const res: KOLDataRequest = await req.json();

  return res;
};

export const fetchRequestsForPerson = async (personId: string) => {
  return fetch(`${ENDPOINTS.personRequests}/${personId}`, {
    method: "GET",
    mode: "cors",
    credentials: "include"
  })
    .then(res => {
      if (res.status === 404) {
        throw new UserNotFoundError();
      } else if (res.status !== 200) {
        throw new Error(res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .then((res: PersonKOLDataRequests) => res);
};

export const getLiteProfile = async (search: GetLiteProfileInterface) => {
  const asw = await fetch(`${ENDPOINTS.liteProfile}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(search)
  });
  const data = (await asw.json()) as LiteProfile[];
  return data;
};
