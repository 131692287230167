import { ProjectFeatures } from "@h1eng/interfaces";
import { ENDPOINTS } from "./api-endpoints";
import Axios from "axios";
const JSONAPIDeserializer = require("jsonapi-serializer").Deserializer;

const deserializer = new JSONAPIDeserializer({
  keyForAttribute: "camelCase"
});

export const getProjects = async (userId: string) =>
  Axios.get(`${ENDPOINTS.project}/${userId}?include=userProjectJoins`, {
    withCredentials: true
  })
    .then(async ({ data }: any) => {
      const cleaned = await deserializer.deserialize(data);
      return cleaned.userProjectJoins.map((j: any) => j.projectId) as string[];
    })
    .catch(e => {});

export const fetchProjectFeatures = async (): Promise<ProjectFeatures[]> =>
  fetch(ENDPOINTS.projectFeatures, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    mode: "cors",
    credentials: "include"
  }).then(res => res.json());
