/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { connect } from "react-redux";
import { SearchView, SearchTypes } from "@h1eng/interfaces";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import classNames from "classnames";
import { RootState } from "../../../store/reducers";

interface ComponentProps {
  searchView: SearchView;
  setSearchView: (view: SearchView) => void;
}

export const Selected = {};
export const Unselected = {};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "auto"
    },
    button: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "41px",
      width: "148px",
      cursor: "pointer",
      backgroundColor: "#FFFFFF",
      color: "#333"
    },
    selectedButton: { backgroundColor: "#060464", color: "#fff" },
    middleButton: {
      borderTop: "1px solid #A39E92",
      borderBottom: "1px solid #A39E92"
    },
    text: {
      fontFamily: "Montserrat",
      fontSize: " 14px",
      fontWeight: 600,
      lineHeight: "18px",
      color: "inherit"
    },
    borderRightSide: {
      border: "1px solid #A39E92",
      borderRadius: "4px 0 0 4px"
    },
    borderLeftSide: {
      border: "1px solid #A39E92",
      borderRadius: "0 4px 4px 0"
    },
    disabledButton: {
      color: "rgba(0, 0, 0, 0.26)",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      cursor: "default",
      borderColor: theme.palette.divider
    }
  });

interface MappedStateProps {
  searchType: SearchTypes;
}

type Props = ComponentProps & MappedStateProps & WithStyles<typeof styles>;

class ViewSelectorComponent extends React.Component<Props> {
  setView = (view: SearchView) => () => {
    if (this.searchedWorkDisabled && view === SearchView.SearchedWork) return;
    this.props.setSearchView(view);
  };

  componentWillMount() {
    if (this.searchedWorkDisabled) {
      this.props.setSearchView(SearchView.TotalWork);
    }
  }

  get searchedWorkDisabled() {
    return this.props.searchType === SearchTypes.NAME;
  }

  render() {
    const { classes, searchView } = this.props;

    return (
      <div className={classes.root}>
        <div
          className={classNames(classes.button, classes.borderRightSide, {
            [classes.selectedButton]:
              searchView === SearchView.SearchedWork &&
              !this.searchedWorkDisabled,
            [classes.disabledButton]: this.searchedWorkDisabled
          })}
          onClick={this.setView(SearchView.SearchedWork)}
        >
          <div className={classNames(classes.text)}>Searched Work</div>
        </div>
        <div
          className={classNames(
            classes.button,
            classes.middleButton,
            searchView === SearchView.TotalWork && classes.selectedButton
          )}
          onClick={this.setView(SearchView.TotalWork)}
        >
          <div className={classNames(classes.text)}>Total Work</div>
        </div>
        <div
          className={classNames(
            classes.button,
            classes.borderLeftSide,
            searchView === SearchView.WorkOverTime && classes.selectedButton
          )}
          onClick={this.setView(SearchView.WorkOverTime)}
        >
          <div className={classNames(classes.text)}>Work Over Time</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): MappedStateProps => ({
  searchType: state.searchFilters.searchType
});

export const ViewSelector = connect(mapStateToProps)(
  withStyles(styles)(ViewSelectorComponent)
);
