import {
  Epic,
  StateObservable,
  ofType,
  ActionsObservable
} from "redux-observable";
import { RootState } from "../../reducers";
import { switchMap } from "rxjs/operators";
import { of } from "rxjs";
import {
  setFilterCompletionQueryLoading,
  requestSpecialtyOptions,
  requestCountryOptions,
  requestStateOptions,
  requestInstitutionOptions,
  requestPublicationJournalOptions,
  requestPublicationTypeOptions,
  requestTrialStatusOptions,
  requestTrialPhaseOptions,
  requestTrialStudyTypeOptions,
  requestTrialFunderTypeOptions,
  requestTrialSponsorOptions,
  requestCongressNameOptions,
  requestCongressTypeOptions,
  requestGrantFunderOptions,
  requestPaymentCompanyOptions,
  requestPaymentDrugOrDeviceOptions,
  requestPaymentFundingTypeOptions,
  requestTagNameOptions
} from "../../actions";

const queryActions = [
  requestSpecialtyOptions,
  requestCountryOptions,
  requestStateOptions,
  requestInstitutionOptions,
  requestPublicationJournalOptions,
  requestPublicationTypeOptions,
  requestTagNameOptions,
  requestTrialStatusOptions,
  requestTrialPhaseOptions,
  requestTrialStudyTypeOptions,
  requestTrialFunderTypeOptions,
  requestTrialSponsorOptions,
  requestCongressNameOptions,
  requestCongressTypeOptions,
  requestGrantFunderOptions,
  requestPaymentCompanyOptions,
  requestPaymentDrugOrDeviceOptions,
  requestPaymentFundingTypeOptions
];

const requestActions = queryActions.map(act => act.request);

const successActions = queryActions.map(act => act.success);

const failureActions = queryActions.map(act => act.failure);

const handleSetLoadingFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(...requestActions),
    switchMap(() => of(setFilterCompletionQueryLoading(true)))
  );

const handleUnsetLoadingFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>
) =>
  action$.pipe(
    ofType(...[...successActions, ...failureActions]),
    switchMap(() => of(setFilterCompletionQueryLoading(false)))
  );

export default [handleSetLoadingFlow, handleUnsetLoadingFlow];
