import * as React from "react";
import { connect } from "react-redux";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import { RootState } from "../../../store/reducers";
import {
  setKolDataRequestPersonId,
  fetchSelectedPersonRequests
} from "../../../store/actions";
import { getCurrentUserId } from "../../../store/selectors";
import { PersonKOLDataRequests } from "@h1eng/interfaces";
import { RequestKOLDataButton } from "../KOLResult/ResultCards/ResultCard/RequestKOLDataButton";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 24,
      paddingBottom: 70,
      paddingTop: 70,
      backgroundColor: "#ffffff",
      borderRadius: 2,
      border: "1px solid #ecf0f1"
    },
    textWrapper: {
      fontFamily: theme.typography.fontFamily,
      color: "#333",
      fontSize: 14,
      lineHeight: "18px",
      fontWeight: 500,
      textAlign: "center",
      maxWidth: 476,
      width: "100%",
      marginBottom: 24,
      marginLeft: "auto",
      marginRight: "auto"
    },
    buttonWrapper: {
      display: "flex",
      marginLeft: "auto",
      marginRight: "auto",
      width: "fit-content",
      height: 41
    },
    infoRequestedButton: {
      boxShadow: "none"
    }
  });

interface ComponentProps {
  personId: string;
}

interface MappedStateProps {
  selectedPersonId: string | null;
  userId: string;
  personRequests: PersonKOLDataRequests | null;
}

interface DispatchProps {
  setSelectedPersonId: (id: string | null) => void;
  fetchRequests: (id: string) => void;
}

type Props = ComponentProps &
  MappedStateProps &
  DispatchProps &
  WithStyles<typeof styles>;

const RequestInfoComponent: React.FunctionComponent<Props> = ({
  personId,
  personRequests,
  userId,
  selectedPersonId,
  fetchRequests,
  setSelectedPersonId,
  classes
}) => {
  React.useEffect(() => {
    setSelectedPersonId(personId);
    fetchRequests(personId);
  }, []);

  if (personRequests === null || personRequests.infoRequestsResolved === true) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.textWrapper}>
        This profile is missing some important information. If you request more
        information about this person, we will prioritize making the information
        available.
      </div>
      <div className={classes.buttonWrapper}>
        {personRequests !== null && (
          <RequestKOLDataButton
            personId={personId}
            kolDataRequests={personRequests.requests}
            variant="contained"
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState): MappedStateProps => ({
  selectedPersonId: state.userInfoRequest.requestKolData.personId,
  userId: getCurrentUserId(state),
  personRequests: state.userInfoRequest.requestKolData.selectedPersonRequests
});

const mapDispatchToProps: DispatchProps = {
  setSelectedPersonId: setKolDataRequestPersonId,
  fetchRequests: fetchSelectedPersonRequests.request
};

export const RequestInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RequestInfoComponent));
