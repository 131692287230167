import * as React from "react";
import { connect } from "react-redux";
import { TagInterface } from "@h1eng/interfaces";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import { removeTagFromPerson } from "../../../../store/actions/tags";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: 5,
      marginRight: 5,
      height: 28,
      fontSize: 14,
      lineHeight: "14px"
    },
    clickedRoot: {
      display: "none"
    },
    deleteIcon: {
      color: "#333",
      "&:hover": {
        color: "rgba(51, 51, 51, 0.26)"
      }
    },
    disabledDeleteIcon: {
      color: "rgba(51, 51, 51, 0.26)",
      cursor: "default"
    }
  });

interface ComponentProps {
  personId: string;
  tag: TagInterface;
}

interface DispatchProps {
  removeTag: (props: { personId: string; tagId: string }) => void;
}

type Props = ComponentProps & DispatchProps & WithStyles<typeof styles>;

const TagComponent: React.FunctionComponent<Props> = ({
  personId,
  classes,
  tag,
  removeTag
}) => {
  const [clicked, setClicked] = React.useState(false);

  const onDelete = tag.isH1
    ? undefined
    : () => {
        if (!clicked) {
          setClicked(true);
          removeTag({ personId, tagId: tag.id });
        }
      };

  const deleteIcon = (
    <CloseIcon
      className={classNames(
        classes.deleteIcon,
        clicked && classes.disabledDeleteIcon
      )}
      fontSize="small"
    />
  );

  return (
    <Chip
      className={classNames(classes.root, clicked && classes.clickedRoot)}
      label={tag.name}
      onDelete={onDelete}
      deleteIcon={deleteIcon}
    />
  );
};

const mapDispatchToProps: DispatchProps = {
  removeTag: removeTagFromPerson.request
};

export const Tag = connect(
  undefined,
  mapDispatchToProps
)(withStyles(styles)(TagComponent));
