import { isActionOf } from "typesafe-actions";
import {
  Epic,
  StateObservable,
  ofType,
  ActionsObservable
} from "redux-observable";
import {
  requestTagNameOptions,
  setTagNameQuery,
  resetInitialOptions,
  SearchFilterAction
} from "../../actions/searchFilters";
import { RootState } from "../../reducers";
import {
  debounceTime,
  switchMap,
  takeUntil,
  skip,
  map,
  filter,
  catchError
} from "rxjs/operators";
import { from, of, pipe } from "rxjs";
import { getOptions } from "../../../apis/searchFilters";
import { DEBOUNCE_TIME } from ".";

const setTagNameQueryFlow: Epic<SearchFilterAction, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(ofType(setTagNameQuery)).pipe(
    debounceTime(DEBOUNCE_TIME),
    switchMap(({ payload }) =>
      !!payload
        ? of(
            requestTagNameOptions.request(state$.value.searchFilters.tags.name)
          ).pipe(takeUntil(action$.pipe(skip(1))))
        : of(resetInitialOptions())
    )
  );

const getTagNameOptionsFlow: Epic<any, any, any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf(requestTagNameOptions.request)),
    switchMap(({ payload }) =>
      from(
        getOptions(
          payload
          // state$.value.searchFilters.initialOptions.tags.name
        )
      ).pipe(
        map(requestTagNameOptions.success),
        catchError(
          pipe(
            requestTagNameOptions.failure,
            of
          )
        )
      )
    )
  );

export default [setTagNameQueryFlow, getTagNameOptionsFlow];
