import * as React from "react";
// import { withStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";

export interface CheckboxFilterGroupMappedStateProps {
  options: string[];
  values: string[];
}

export interface CheckboxFilterGroupDispatchProps {
  setValues: (values: string[]) => void;
}

export interface CheckboxFilterGroupProps {
  label?: React.ReactElement<any>;
  helperText?: React.ReactElement<any>;
}

type Props = CheckboxFilterGroupMappedStateProps &
  CheckboxFilterGroupDispatchProps &
  CheckboxFilterGroupProps;

export class CheckboxFilterGroup extends React.Component<Props> {
  render() {
    const { label, values, options, helperText } = this.props;

    return (
      <FormControl>
        {!!label && <FormLabel>{label}</FormLabel>}
        <FormGroup>
          {options.map((option, i) => (
            <FormControlLabel
              key={`checkbox-filter-group-option-${option}-${i}`}
              control={
                <Checkbox
                  color="primary"
                  checked={values.indexOf(option) !== -1}
                  value={option}
                  onChange={this.handleChange(option)}
                />
              }
              label={option}
            />
          ))}
        </FormGroup>
        {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }

  private handleChange = (value: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    let { values } = this.props;

    if (checked) {
      values = [...values, value];
    } else {
      values = values.filter(i => i !== value);
    }

    this.props.setValues(values);
  };
}
