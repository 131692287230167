import * as React from "react";
import { ListMeta } from "../../ListManagement/ListManagementInterfaces";
import { connect } from "react-redux";
import { MODAL } from "../../../Modal";
import { CreateNewListModal } from "../../../modals/createNewList";
import {
  createGrowler,
  addToList,
  ListManipulationInterface,
  removePeopleFromList,
  exportKOLs,
  clearKOLExportIds
} from "../../../../store/actions";
import { RootState } from "../../../../store/reducers";
import {
  getSelectedProject,
  currentUser,
  getSelectedListId,
  getOtherAvailableLists
} from "../../../../store/selectors";
import { UserVO } from "../../../../models/UserVO";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { GrowlerInterface } from "../../../../store/actions/growler";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SvgIcon from "@material-ui/core/SvgIcon";
import TextField from "@material-ui/core/TextField";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import InputAdornment from "@material-ui/core/InputAdornment";
import ListItemText from "@material-ui/core/ListItemText";
import classnames from "classnames";
import ListSubheader from "@material-ui/core/ListSubheader";
import { TagManagerModal } from "../../common/TagManager/TagManagerModal";

const styles = createStyles({
  textFieldRoot: {
    justifyContent: "flex-end",
    marginBottom: 11
  },
  inputRoot: {
    borderBottom: "2px solid #060464",
    fontSize: 14,
    fontWeight: 500,
    width: 100,
    cursor: "pointer",
    "&:hover": {
      borderBottom: "2px solid #060464",
      fontSize: 14,
      fontWeight: 500
    },
    "&:before": {
      display: "none"
    },
    "&:after": {
      display: "none"
    },
    "&:focus": {
      borderBottom: "2px solid #060464",
      fontSize: 14,
      fontWeight: 500
    }
  },
  pointer: {
    cursor: "pointer"
  },
  blueText: {
    color: "rgb(10, 170, 205)"
  },
  disabled: {
    opacity: 0.7,
    fontStyle: "italic",
    cursor: "default"
  }
});

interface Action {
  text: string;
  icon?: React.ReactElement<any>;
  onClick: (...args: any) => void;
  inset?: boolean;
  bold?: boolean;
  className?: string;
}

interface BulkActionsProps {
  selectedIds: string[];
  style?: React.CSSProperties;
}

interface MappedStateProps {
  user: UserVO;
  projectId: string;
  selectedListId: string | null;
  listsAvailable: ListMeta[];
}

interface DispatchProps {
  createNewGrowler: (opts: GrowlerInterface) => void;
  addToList: (opts: ListManipulationInterface) => void;
  removeFromList: (opts: ListManipulationInterface) => void;
  exportKols: () => void;
  clearSelectedKols: () => void;
}

type Props = BulkActionsProps &
  MappedStateProps &
  DispatchProps &
  RouteComponentProps &
  WithStyles<typeof styles>;

interface State {
  anchorEl: EventTarget | null;
  modalOpen: boolean;
  tagManagerModalOpen: boolean;
}

class BulkActionsComponent extends React.Component<Props, State> {
  state: State = {
    anchorEl: null,
    modalOpen: false,
    tagManagerModalOpen: false
  };

  openMenu = (e: React.SyntheticEvent) => {
    if (this.props.selectedIds.length === 0) return;
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  closeMenu = () => {
    this.setState({ anchorEl: null });
  };

  setModalOpen = (modalOpen: boolean) => () => {
    this.setState({ modalOpen });
  };

  downloadItems = () => {
    this.props.exportKols();

    this.closeMenu();
  };

  setTagManagerModalOpen = (tagManagerModalOpen: boolean) => {
    this.setState({ tagManagerModalOpen });
  };

  get actions(): Action[] {
    const { selectedIds, selectedListId, classes } = this.props;

    let res: Action[];

    const lists = this.props.listsAvailable.map(i => ({
      text: i.title,
      inset: true,
      onClick: () => {
        this.props.addToList({ personIds: selectedIds, listId: i.id });
        this.closeMenu();
      }
    }));

    const addIcon = (
      <SvgIcon color="inherit" width={16} height={16}>
        <path d="M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
      </SvgIcon>
    );

    res = [
      ...lists,
      {
        text: "Add/Remove Tags",
        icon: addIcon,
        bold: true,
        onClick: () => {
          this.closeMenu();
          this.setTagManagerModalOpen(true);
        },
        className: classes.blueText
      },
      {
        text: "Create New List",
        icon: addIcon,
        onClick: () => {
          this.closeMenu();
          this.setModalOpen(true)();
        },
        bold: true,
        className: classes.blueText
      },
      {
        text: "Download Profiles",
        icon: (
          <SvgIcon color="inherit" width={16} height={16}>
            <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
          </SvgIcon>
        ),
        onClick: this.downloadItems,
        bold: true,
        className: classes.blueText
      }
    ];

    if (selectedListId !== null) {
      res = [
        ...res,
        // {
        //   text: "Add to List",
        //   icon: (
        //     <SvgIcon color="inherit" width={16} height={16}>
        //       <path d="M17,18L12,15.82L7,18V5H17M17,3H7A2,2 0 0,0 5,5V21L12,18L19,21V5C19,3.89 18.1,3 17,3Z" />
        //     </SvgIcon>
        //   ),
        //   onClick: () => {
        //     this.props.addToList({
        //       personIds: this.props.selectedIds,
        //       listId: selectedListId
        //     });
        //     this.closeMenu();
        //   },
        //   bold: true,
        //   className: classes.blueText
        // },
        {
          text: "Remove from List",
          icon: (
            <SvgIcon color="inherit" width={16} height={16}>
              <path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
            </SvgIcon>
          ),
          onClick: () => {
            this.props.removeFromList({
              personIds: selectedIds,
              listId: selectedListId
            });
            this.props.clearSelectedKols();
            this.closeMenu();
          },
          bold: true,
          className: classes.blueText
        }
      ];
    }

    return res;
  }

  render() {
    const { selectedIds, classes } = this.props;
    const { anchorEl } = this.state;

    const disabled = selectedIds.length === 0;

    return (
      <>
        <MODAL open={this.state.modalOpen} onClose={this.setModalOpen(false)}>
          <CreateNewListModal
            personIds={selectedIds}
            onClose={this.setModalOpen(false)}
          />
        </MODAL>
        <TextField
          defaultValue="Actions"
          aria-owns={anchorEl ? "bulk-actions-menu" : undefined}
          aria-haspopup="true"
          onClick={this.openMenu}
          InputProps={{
            readOnly: true,
            classes: {
              root: classnames(classes.inputRoot, {
                [classes.disabled]: disabled
              }),
              focused: classnames(classes.inputRoot, {
                [classes.disabled]: disabled
              }),
              input: classnames(classes.pointer, {
                [classes.disabled]: disabled
              })
            },
            endAdornment: (
              <InputAdornment
                position="end"
                className={classnames(classes.pointer, {
                  [classes.disabled]: disabled
                })}
              >
                <ArrowDropDown />
              </InputAdornment>
            )
          }}
          classes={{
            root: classes.textFieldRoot
          }}
        />
        <Menu
          id="bulk-actions-menu"
          anchorEl={anchorEl as HTMLElement}
          open={Boolean(anchorEl)}
          onClose={this.closeMenu}
        >
          {this.props.listsAvailable.length > 0 && (
            <ListSubheader
              style={{
                outline: "none",
                cursor: "default",
                fontSize: 14,
                fontWeight: 600,
                color: "#333"
              }}
            >
              Save to list:
            </ListSubheader>
          )}
          {this.actions.map(action => (
            <MenuItem
              key={`action-item-${action.text}`}
              onClick={action.onClick}
              disabled={disabled}
              className={action.className}
              style={{ height: 18, paddingLeft: action.inset ? 0 : undefined }}
            >
              {!!action.icon && action.icon}
              <ListItemText
                inset={action.inset || !!action.icon}
                primary={action.text}
                style={{
                  height: 18
                  // paddingLeft: action.inset ? 24 : undefined
                }}
                primaryTypographyProps={{
                  color: "inherit",
                  style: {
                    fontSize: 14,
                    height: 18,
                    fontWeight: action.bold ? "bold" : "normal"
                  }
                }}
              />
            </MenuItem>
          ))}
        </Menu>
        <TagManagerModal
          open={this.state.tagManagerModalOpen}
          setOpen={this.setTagManagerModalOpen}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): MappedStateProps => ({
  projectId: getSelectedProject(state)!,
  user: currentUser(state)!,
  selectedListId: getSelectedListId(state),
  listsAvailable: getOtherAvailableLists(state)
});

const mapDispatchToProps: DispatchProps = {
  createNewGrowler: createGrowler,
  addToList: addToList.request,
  removeFromList: removePeopleFromList.request,
  exportKols: exportKOLs.request,
  clearSelectedKols: clearKOLExportIds
};

export const BulkActions = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(BulkActionsComponent)));
