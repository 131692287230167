export interface PeopleSearchDocument {
  id: string;
  _id: string;
  name: string;
  personNames: Array<{
    id: string;
    projectIds: string[];
    first: string;
    middle: string;
    last: string;
  }>;
  names: {
    first: string;
    middle: string;
    last: string;
  };
  titles: string[];
  designations: string[];
  specialty: string[];
  locations: Array<{ city: string; state: string } | null>;
  scores: Array<{ projectId: string; score: number }>;
  departments: string[];
  schools: string[];
  institutions: string[];
  projects: string[];
  collaborators: number;
}

export interface GenericSearchDocument {
  terms: string[];
  type: GenericSearchEnum;
  id: string;
  _id: string;
  docType: string;
  person_ids: { ids: string[] };
  collaborators: number;
  date: string | number | undefined;
  personNames: Array<{
    id: string;
    projectIds: string[];
    first: string;
    middle: string;
    last: string;
  }>;
  paymentSum?: number;
  citationCount?: number;
}

export enum GenericSearchEnum {
  AFFILIATION,
  CLINICALTRIAL,
  CONGRESS,
  PUBLICATION,
  GRANTS,
  PAYMENTS,
  PERSONS,
  GENERIC,
  TAG
}
export interface ElasticSearchDocuments {
  type: GenericSearchEnum;
  doc: any;
}

export interface PersonName {
  projectIds: string[];
  first: string;
  middle: string;
  last: string;
  id: string;
}

export interface TagDocument {
  _id: string;
  id: string;
  name: string;
  isH1: boolean;
  active: boolean;
  userEmail?: string;
  projectId: string;
  created: string;
  updated: string;
  tag: boolean;
  personNames: PersonName[];
}
