import { createSelector } from "reselect";
import { RootState } from "../reducers";

export const getProjectsState = (state: RootState) => state.projects;

export const getProjects = createSelector(
  getProjectsState,
  projects => projects.projects
);

export const getSelectedProject = createSelector(
  getProjectsState,
  projects => projects.selectedId
);

export const getProjectFeatures = createSelector(
  getProjectsState,
  state => state.features.find(i => i.projectId === state.selectedId) || null
);
