import * as React from "react";
import { connect } from "react-redux";
import {
  requestTagOptions,
  cancelTagOptionsRequest
} from "../../../../store/actions/tags";
import { RootState } from "../../../../store/reducers/index";

interface MappedStateProps {
  loading: boolean;
}

interface DispatchProps {
  getTagOptions: () => void;
  cancelOptionRequest: () => void;
}

type Props = MappedStateProps & DispatchProps;

const TagOptionFetcherComponent: React.FunctionComponent<Props> = ({
  getTagOptions,
  loading,
  cancelOptionRequest
}) => {
  React.useEffect(() => {
    if (!loading) {
      getTagOptions();
    }

    return () => {
      if (loading) {
        cancelOptionRequest();
      }
    };
  }, []);

  return null;
};

const mapStateToProps = (state: RootState): MappedStateProps => ({
  loading: state.tags.optionsLoading
});

const mapDispatchToProps: DispatchProps = {
  getTagOptions: requestTagOptions.request,
  cancelOptionRequest: cancelTagOptionsRequest
};

export const TagOptionFetcher = connect(
  mapStateToProps,
  mapDispatchToProps
)(TagOptionFetcherComponent);

export default TagOptionFetcher;
