import * as React from "react";
import { connect } from "react-redux";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { RootState } from "../../../store/reducers";
import {
  requestKolData,
  setKolDataRequestPersonId,
  setRequestKolDataModalOpen
} from "../../../store/actions";
import classNames from "classnames";

const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      fontFamily: "Montserrat",
      fontSize: 20,
      lineHeight: "24px",
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
      paddingLeft: 27,
      paddingRight: 20,
      paddingTop: 0,
      paddingBottom: 0,
      height: 51
    },
    closeIconButton: {
      marginLeft: "auto"
    },
    dialogContent: {
      paddingTop: 35,
      textAlign: "center"
    },
    contentTextWrapper: {
      maxWidth: 348,
      marginLeft: "auto",
      marginRight: "auto"
    },
    contentText: {
      color: "#333",
      fontFamily: theme.typography.fontFamily,
      fontSize: 14,
      lineHeight: "18px",
      textAlign: "center"
    },
    submitButton: {
      fontWeight: 600
    },
    submitButtonLoading: {
      cursor: "default",
      "&:hover": {
        backgroundColor: theme.palette.primary.main
      },
      "&:active": {
        boxShadow: theme.shadows[2]
      }
    },
    cancelButton: {
      fontWeight: "bold"
    },
    progress: {
      marginRight: theme.spacing.unit
    }
  });

interface ComponentProps {
  onConfirm?: () => void;
  onCancel?: () => void;
}

interface MappedStateProps {
  personId: string | null;
  open: boolean;
}

interface DispatchProps {
  setPersonId: (personId: string | null) => void;
  requestData: (personId: string) => void;
  setModalOpen: (open: boolean) => void;
}

type Props = ComponentProps &
  MappedStateProps &
  DispatchProps &
  WithStyles<typeof styles>;

const RequestKOLDataModalComponent: React.FunctionComponent<Props> = ({
  personId,
  setPersonId,
  requestData,
  setModalOpen,
  open,
  classes,
  onConfirm = () => {},
  onCancel = () => {}
}) => {
  if (!personId) return null;

  const [clicked, setClicked] = React.useState(false);

  const handleClose = () => {
    setPersonId(null);
    setModalOpen(false);
    onCancel();
  };

  const handleConfirm = () => {
    if (clicked) return;
    requestData(personId);
    setClicked(true);
    onConfirm();
  };

  return (
    <Dialog open={personId !== null && open}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        Request Info
        <IconButton
          onClick={handleClose}
          color="inherit"
          className={classes.closeIconButton}
        >
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.contentTextWrapper}>
          <span className={classes.contentText}>
            The process of mapping someone's entire body of work to their
            profile is a difficult process. We do so for over 1 million+
            individuals but at times might miss someone. If you believe this
            person has published peer review papers or worked on Clinical
            trials, please request more information and our team will look into
            it.
          </span>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          className={classNames(classes.submitButton, {
            [classes.submitButtonLoading]: clicked
          })}
          disableRipple={clicked}
        >
          {clicked && (
            <CircularProgress
              style={{ width: 16, height: 16 }}
              className={classes.progress}
              color="inherit"
            />
          )}
          Request a New Individual
        </Button>
        <Button
          className={classes.cancelButton}
          role="button"
          onClick={handleClose}
          color="secondary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: RootState): MappedStateProps => ({
  personId: state.userInfoRequest.requestKolData.personId,
  open: state.userInfoRequest.requestKolData.modalOpen
});

const mapDispatchToProps: DispatchProps = {
  requestData: requestKolData.request,
  setPersonId: setKolDataRequestPersonId,
  setModalOpen: setRequestKolDataModalOpen
};

export const RequestKOLDataModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RequestKOLDataModalComponent));
