import * as React from "react";
import Chip, { ChipProps } from "@material-ui/core/Chip";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";

const styles = (theme: Theme) =>
  createStyles({
    themedChip: {
      margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
      backgroundColor: "#D7D6E7",
      whiteSpace: "unset",
      height: "unset",
      minHeight: theme.spacing.unit * 4,
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit
    },
    closeIcon: {
      color: "#060464"
    },
    chipLabel: {
      whiteSpace: "unset",
      overflow: "auto",
      wordBreak: "break-word"
    }
  });

type Props = ChipProps & WithStyles<typeof styles>;

const ThemedChipComponent: React.FunctionComponent<Props> = ({
  classes,
  className,
  ...props
}) => (
  <Chip
    tabIndex={-1}
    classes={{
      label: classes.chipLabel
    }}
    className={classNames(classes.themedChip, className)}
    deleteIcon={
      <CloseIcon
        id={`${props.id}-chip-close`}
        className={classNames(classes.closeIcon, "themed-chip-delete-icon")}
        fontSize="small"
      />
    }
    {...props}
  />
);

export const ThemedChip = withStyles(styles)(ThemedChipComponent);

export default ThemedChip;
