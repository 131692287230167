import { isActionOf } from "typesafe-actions";
import { Epic, ActionsObservable } from "redux-observable";

import { filter, switchMap, map, catchError } from "rxjs/operators";
import { from, of, pipe } from "rxjs";
import {
  getProjectsForUser,
  getProjectFeatures,
  ProjectsAction
} from "../actions/projects";
import { getProjects, fetchProjectFeatures } from "../../apis/projects";

export const fetchProjectsFlow: Epic<any, any, any> = action$ => {
  return action$.pipe(
    filter(isActionOf(getProjectsForUser.request)),
    switchMap(action =>
      from(getProjects(action.payload)).pipe(
        map(getProjectsForUser.success),
        catchError(
          pipe(
            getProjectsForUser.failure,
            of
          )
        )
      )
    )
  );
};

export const fetchProjectFeaturesFlow = (
  action$: ActionsObservable<ProjectsAction>
) =>
  action$.pipe(
    filter(isActionOf(getProjectsForUser.success)),
    switchMap(() =>
      from(fetchProjectFeatures()).pipe(
        map(getProjectFeatures.success),
        catchError(
          pipe(
            getProjectFeatures.failure,
            of
          )
        )
      )
    )
  );
