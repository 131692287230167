/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import { connect } from "react-redux";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import classNames from "classnames";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ListSubheader from "@material-ui/core/ListSubheader";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { RootState } from "../../../../store/reducers/index";
import {
  bulkAddTagsToPeople,
  bulkRemoveTagsFromPeople
} from "../../../../store/actions/tags";
import { filterTags, TagOption } from "./lib/filterTags";
import {
  getOptionsAndNameList,
  isValidTagName
} from "./lib/tagValidationHelpers";
import { createTagsPermission } from "../../../../store/selectors/tagSelectors";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      minWidth: 450,
      minHeight: 370
    },
    dialogTitle: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      fontFamily: "Montserrat",
      fontSize: 20,
      lineHeight: "24px",
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
      paddingLeft: 27,
      paddingRight: 20,
      paddingTop: 0,
      paddingBottom: 0,
      height: 51
    },
    closeIconButton: {
      marginLeft: "auto"
    },
    button: {
      fontWeight: 600,
      "&:not(:first-of-type)": {
        marginTop: 5
      }
    },
    inputWrapper: {
      marginLeft: 10,
      marginRight: 10,
      paddingTop: 20,
      minWidth: 350
    },
    tagOptionMenuItem: {
      // "&:focus": {
      //   backgroundColor: "inherit"
      // }
    },
    tagMenuSubheader: {
      paddingLeft: 10,
      paddingRight: 10,
      cursor: "default",
      color: "#333",
      fontWeight: "bold",
      position: "unset"
      // paddingTop: 12,
      // paddingBottom: 8
    },
    createSubheader: {
      cursor: "pointer",
      color: theme.palette.secondary.main
    }
  });

interface ComponentProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface MappedStateProps {
  personIds: string[];
  options: TagOption[];
  createPermission: boolean;
}

interface DispatchProps {
  addTags: (
    props:
      | { personIds: string[]; tagIds: string[] }
      | { personIds: string[]; tagName: string }
  ) => void;
  removeTags: (props: { personIds: string[]; tagIds: string[] }) => void;
}

type Props = ComponentProps &
  MappedStateProps &
  DispatchProps &
  WithStyles<typeof styles>;

const TagManagerModalComponent: React.FunctionComponent<Props> = ({
  classes,
  open,
  setOpen,
  personIds,
  options,
  addTags,
  removeTags,
  createPermission
}) => {
  if (!options) return null;

  const [tags, setTags] = React.useState([] as string[]);
  const [inputVal, setInputVal] = React.useState("");

  function updateInputVal(val: string) {
    setInputVal(val);
  }

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { value } = e.target;
    updateInputVal(value);
  };

  const handleClose = () => {
    setInputVal("");
    setTags([]);
    setOpen(false);
  };

  const handleOptionClick = (tagId: string) => (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.currentTarget.blur();
    let newTags: string[];

    if (tags.indexOf(tagId) === -1) {
      newTags = [...tags, tagId];
      setInputVal("");
    } else {
      newTags = tags.filter(i => i !== tagId);
    }

    setTags(newTags);
  };

  const { options: validOptions, names } = getOptionsAndNameList(options);

  const tagOptions = inputVal.length
    ? [
        ...new Set([
          ...filterTags(inputVal, validOptions),
          ...validOptions.filter(i => tags.indexOf(i.id) > -1)
        ])
      ]
    : validOptions;

  const canCreateNewTag =
    createPermission && isValidTagName({ inputVal, names });

  const canSubmit = tags.length > 0 || canCreateNewTag;

  const creating = canSubmit && tags.length === 0;

  const handleSubmit = (forceCreating: boolean = false) => {
    if (!canSubmit) return;

    if (creating || forceCreating) {
      addTags({ personIds, tagName: inputVal });
    } else {
      addTags({ personIds, tagIds: tags });
    }

    handleClose();
  };

  const handleRemove = () => {
    if (!tags.length) return;

    removeTags({ personIds, tagIds: tags });
    handleClose();
  };

  return (
    <Dialog open={open && personIds.length > 0} className={classes.root}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        Manage Tags
        <IconButton
          onClick={handleClose}
          color="inherit"
          className={classes.closeIconButton}
        >
          <CloseIcon color="inherit" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={classes.inputWrapper}>
          <TextField
            onChange={handleInputChange}
            inputProps={{
              maxLength: 50,
              autoCapitalize: "on"
            }}
            value={inputVal}
            fullWidth
            label={<em>Tag Name</em>}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ pointerEvents: "none" }}
                >
                  <SearchIcon color="primary" />
                </InputAdornment>
              )
            }}
          />
        </div>
        <MenuList
          subheader={
            <>
              {canCreateNewTag && (
                <ListSubheader
                  className={classNames(
                    classes.tagMenuSubheader,
                    classes.createSubheader
                  )}
                  onClick={handleSubmit.bind(null, true)}
                >
                  Create New Tag
                </ListSubheader>
              )}
              {tagOptions.length > 0 && (
                <ListSubheader className={classNames(classes.tagMenuSubheader)}>
                  Existing Tags:
                </ListSubheader>
              )}
            </>
          }
        >
          {tagOptions.map(option => {
            return (
              <MenuItem
                key={option.id}
                className={classes.tagOptionMenuItem}
                selected={tags.indexOf(option.id) !== -1}
                onClick={handleOptionClick(option.id)}
              >
                {option.name}
              </MenuItem>
            );
          })}
        </MenuList>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          onClick={handleSubmit.bind(null, false)}
          color="primary"
          variant="contained"
          className={classes.button}
          disabled={!canSubmit}
        >
          {creating
            ? "Create and Add New Tag"
            : `Add Tag${tags.length > 1 ? "s" : ""}`}
        </Button>
        <Button
          onClick={handleRemove}
          disabled={tags.length < 1}
          className={classes.button}
        >
          Remove Tag{tags.length > 1 ? "s" : ""}
        </Button>
        <Button
          className={classes.button}
          role="button"
          onClick={handleClose}
          color="secondary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: RootState): MappedStateProps => ({
  personIds: state.dataExports.kols,
  options: state.tags.options,
  createPermission: createTagsPermission(state)
});

const mapDispatchToProps: DispatchProps = {
  addTags: bulkAddTagsToPeople.request,
  removeTags: bulkRemoveTagsFromPeople.request
};

export const TagManagerModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TagManagerModalComponent));
