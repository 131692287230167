import * as React from "react";
import { connect } from "react-redux";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { setKOLExportIds, exportKOLs } from "../../../store/actions";

const styles = (theme: Theme) =>
  createStyles({
    iconButton: {
      padding: 5
    }
  });

interface ComponentProps {
  ids: string[];
}

interface DispatchProps {
  setSelectedIds: (ids: string[]) => void;
  sendExportRequest: () => void;
}

type Props = ComponentProps &
  DispatchProps &
  WithStyles<typeof styles> &
  IconButtonProps;

const ExcelExportButtonComponent: React.FunctionComponent<Props> = ({
  ids,
  setSelectedIds,
  sendExportRequest,
  classes,
  ...props
}) => {
  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    setSelectedIds(ids);
    sendExportRequest();
  };

  return (
    <IconButton
      onClick={handleClick}
      color={"secondary" as any}
      className={classes.iconButton}
      {...props}
    >
      <SvgIcon color="inherit" width={16} height={16}>
        <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
      </SvgIcon>
    </IconButton>
  );
};

const mapStateToProps = undefined;

const mapDispatchToProps: DispatchProps = {
  setSelectedIds: setKOLExportIds,
  sendExportRequest: exportKOLs.request
};

export const ExcelExportButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ExcelExportButtonComponent));
