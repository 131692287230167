import { isActionOf, ActionType } from "typesafe-actions";
import { Epic, StateObservable, ActionsObservable } from "redux-observable";
import { filter, switchMap, map, catchError, mergeMap } from "rxjs/operators";
import { RootState } from "../reducers";
import { from, of, pipe, concat, empty } from "rxjs";
import {
  searchCollaborators,
  getCollaboratorsDetials,
  getLiteProfileAction,
  setPage
} from "../actions/collaborators";
import { findCollaborators, collaboratorsDetails } from "../../apis/search";
import {
  GetCollaboratorsInterface,
  GetCollaboratorsDetialsInterface,
  GetLiteProfileInterface
} from "@h1eng/interfaces";
import { getLiteProfile } from "../../apis/requestKol";
import {
  getCurrentIds,
  getPersonId,
  getElasticSearchDate
} from "../selectors/collaborators";
import {
  getDocumentSearchBarState,
  getDocumentSearchBarFilterDateDisplayText,
  getDocumentSearchBarFilterDate
} from "../selectors";
import {
  setDocumentSearchBarQuery,
  setDocumentSearchBarFilterDate,
  appendCurrentTagsForPeople
} from "../actions";

export const fetchCollaborators: Epic<any, StateObservable<RootState>, any> = (
  action$: any,
  state: StateObservable<RootState>
) => {
  return action$.pipe(
    filter(isActionOf(searchCollaborators.request)),
    switchMap((action: { payload: GetCollaboratorsInterface }) =>
      from(
        findCollaborators({
          personId: action.payload.personId,
          terms: action.payload.terms,
          date: getElasticSearchDate(state.value)
        })
      ).pipe(
        map(searchCollaborators.success),
        catchError(
          pipe(
            searchCollaborators.failure,
            of
          )
        )
      )
    )
  );
};

export const fetchTagsForCollaborators = (
  action$: ActionsObservable<ActionType<typeof getLiteProfileAction.request>>
) =>
  action$.pipe(
    filter(isActionOf(getLiteProfileAction.request)),
    switchMap(({ payload }) =>
      of(appendCurrentTagsForPeople.request(payload.personIds))
    )
  );

export const fetchCollaboratorsDetials: Epic<
  any,
  StateObservable<RootState>
> = (action$: any, state: StateObservable<RootState>) => {
  return action$.pipe(
    filter(isActionOf(getCollaboratorsDetials.request)),
    switchMap((action: { payload: GetCollaboratorsDetialsInterface }) =>
      from(
        collaboratorsDetails({
          personIds: action.payload.personIds,
          terms: action.payload.terms,
          date: getElasticSearchDate(state.value)
        })
      ).pipe(
        map(getCollaboratorsDetials.success),
        catchError(
          pipe(
            getCollaboratorsDetials.failure,
            of
          )
        )
      )
    )
  );
};

export const fetchLiteProfiles: Epic<any, StateObservable<RootState>> = (
  action$: any,
  state: StateObservable<RootState>
) => {
  return action$.pipe(
    filter(isActionOf(getLiteProfileAction.request)),
    switchMap((action: { payload: GetLiteProfileInterface }) =>
      from(
        getLiteProfile({
          personIds: action.payload.personIds
        })
      ).pipe(
        map(getLiteProfileAction.success),
        catchError(
          pipe(
            getLiteProfileAction.failure,
            of
          )
        )
      )
    )
  );
};

export const autoFetchCollaborators: Epic<any, RootState, any> = (
  action$,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    filter(isActionOf([searchCollaborators.success])),
    mergeMap(({ payload: { sortedIds } }) =>
      concat(
        of(
          getLiteProfileAction.request({
            personIds: getCurrentIds(state$.value)!
          })
        ),
        of(
          getCollaboratorsDetials.request({
            personIds: getCurrentIds(state$.value)!,
            terms: getDocumentSearchBarState(state$.value).query
          })
        )
      )
    )
  );

export const autoFetchCollaboratorsAfterPageUpdate: Epic<
  any,
  RootState,
  any
> = (action$, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(isActionOf([setPage])),
    mergeMap(e =>
      concat(
        of(
          getLiteProfileAction.request({
            personIds: getCurrentIds(state$.value)!
          })
        ),
        of(
          getCollaboratorsDetials.request({
            personIds: getCurrentIds(state$.value)!,
            terms: getDocumentSearchBarState(state$.value).query
          })
        )
      )
    )
  );

export const autoFetchCollaboratorsAfterQureyUpdate: Epic<
  any,
  RootState,
  any
> = (action$, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(isActionOf([setDocumentSearchBarQuery])),
    mergeMap(e =>
      concat(
        of(
          searchCollaborators.request({
            personId: getPersonId(state$.value)!,
            terms: getDocumentSearchBarState(state$.value).query
          })
        )
      )
    )
  );

export const autoFetchCollaboratorsAfterDateChange: Epic<
  any,
  RootState,
  any
> = (action$, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(isActionOf([setDocumentSearchBarFilterDate])),
    mergeMap(e =>
      concat(
        of(
          searchCollaborators.request({
            personId: getPersonId(state$.value)!,
            terms: getDocumentSearchBarState(state$.value).query,
            date:
              getDocumentSearchBarFilterDateDisplayText(state$.value) ===
              "All Time"
                ? undefined
                : getDocumentSearchBarFilterDate(state$.value)
          })
        )
      )
    )
  );
