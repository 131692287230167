/* tslint:disable:cyclomatic-complexity */
import * as React from "react";
import Downshift from "downshift";
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem, { MenuItemProps } from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import ThemedChip from "../ThemedChip";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: 250
    },
    container: {
      flexGrow: 1,
      position: "relative",
      width: "100%"
    },
    paper: {
      position: "absolute",
      zIndex: 1,
      marginTop: theme.spacing.unit,
      left: 0,
      right: 0,
      // minWidth: "100%",
      // width: "fit-content",
      marginBottom: theme.spacing.unit * 3,
      maxHeight: theme.spacing.unit * 40,
      overflow: "auto"
    },
    inputRoot: {
      // flexWrap: "wrap"
    },
    inputInput: {
      width: "auto",
      flexGrow: 1
    },
    divider: {
      height: theme.spacing.unit * 2
    },
    menuItem: {
      height: "auto",
      whiteSpace: "unset",
      wordBreak: "break-word"
    }
  });

function renderInput(
  inputProps: TextFieldProps &
    WithStyles<typeof styles> & {
      ref?: ((instance: any) => void) | React.RefObject<any> | null;
    }
) {
  const { InputProps, classes, ref, label, ...other } = inputProps;

  return (
    <TextField
      label={label}
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput
        },
        ...InputProps
      }}
      {...other}
    />
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
  optionTextFormatter,
  key,
  ...props
}: MenuItemProps & {
  highlightedSuggestion?: number;
  index: number;
  itemProps: any;
  selectedItem: string;
  highlightedIndex: number | null;
  suggestion: string;
  key: string;
  optionTextFormatter: (text: string) => string;
}) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = selectedItem === suggestion;

  return (
    <MenuItem
      {...itemProps}
      id={`${key.replace(/ /g, "-").replace("/", "")}-${index}`}
      key={`suggestion-${suggestion}-${index}`}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400
      }}
      value={suggestion}
      {...props}
    >
      {optionTextFormatter(suggestion)}
    </MenuItem>
  );
}

export interface AutosuggestComponentProps {
  values: string[];
  setValues: (values: string[]) => void;
  suggestions: string[];
  inputLabel?: React.ReactNode;
  disableOpenOnFocus?: boolean;
  inputValue: string;
  setInputValue: (value: string) => void;
  allowDuplicateValues?: boolean;
  SuggestionProps?: Partial<MenuItemProps>;
  disabled?: boolean;
  // Format the displayed text of options
  optionTextFormatter?: (text: string) => string;
  loading: boolean;
  initialLoading?: boolean;
}

type Props = AutosuggestComponentProps & WithStyles<typeof styles>;

interface State {
  /**
   * Used to prevent browser autocomplete
   *
   * @see https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
   */
  inputName: string;
}

class AutosuggestComponent extends React.Component<Props, State> {
  static defaultProps = {
    disableOpenOnFocus: false,
    allowDuplicateValues: false,
    disabled: false,
    optionTextFormatter: (text: string) => text,
    loading: false,
    initialLoading: false
  };

  private inputRef = React.createRef<HTMLInputElement>();

  componentWillMount() {
    this.setState({
      inputName: `autosuggest-${this.props.inputLabel}-${Date.now() +
        Math.random()}`
    });
  }

  handleChange = (item: string) => {
    let { values } = this.props;

    if (values.indexOf(item) === -1) {
      values = [...values, item];
    }

    this.props.setValues(values);
    this.blurInputRef();
  };

  handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = event.target;
    this.props.setInputValue(value);
  };

  handleDelete = (item: string) => () => {
    const values = this.props.values.filter(i => i !== item);
    this.props.setValues(values);
  };

  render() {
    const {
      classes,
      values,
      inputLabel,
      disableOpenOnFocus,
      inputValue,
      SuggestionProps,
      disabled,
      loading,
      initialLoading
    } = this.props;

    const optionTextFormatter = this.props.optionTextFormatter as (
      text: string
    ) => string;

    return (
      <Downshift
        inputValue={inputValue}
        onChange={this.handleChange}
        selectedItem={values}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue: inputValue2,
          selectedItem: selectedItem2,
          highlightedIndex,
          openMenu
        }) => (
          <div
            className={classes.container}
            id={inputLabel.props.children.replace(/ /g, "-").replace("/", "")}
          >
            {renderInput({
              label: inputLabel,
              fullWidth: true,
              classes,
              onFocus: !disableOpenOnFocus ? openMenu : undefined,
              ref: this.inputRef,
              InputProps: getInputProps({
                disabled,
                value: inputValue2,
                onChange: this.handleInputChange,
                onBlur: this.clearInputValue,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ pointerEvents: "none" }}
                  >
                    {initialLoading || loading ? (
                      <CircularProgress
                        color="primary"
                        style={{ width: 24, height: 24 }}
                      />
                    ) : (
                      <SearchIcon color="primary" />
                    )}
                  </InputAdornment>
                )
              }),
              inputProps: {
                name: this.state.inputName,
                autoComplete: "new-password",
                "aria-autocomplete": "none"
              }
            })}
            {isOpen ? (
              <Paper className={classes.paper} square>
                {loading ? (
                  <MenuItem disabled>
                    <em>Loading...</em>
                  </MenuItem>
                ) : (
                  this.props.suggestions.map((suggestion, index) =>
                    renderSuggestion({
                      optionTextFormatter,
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      selectedItem: selectedItem2,
                      className: classNames(
                        "autosuggest-option",
                        classes.menuItem
                      ),
                      disabled: this.props.allowDuplicateValues
                        ? false
                        : values.indexOf(suggestion) > -1,
                      key: inputLabel.props.children,
                      ...SuggestionProps
                    })
                  )
                )}
              </Paper>
            ) : null}
            <div>
              {this.props.values.map((item, id) => (
                <ThemedChip
                  id={`${inputLabel.props.children
                    .replace(/ /g, "-")
                    .replace("/", "")}-${id}`}
                  key={item}
                  label={optionTextFormatter(item)}
                  onDelete={this.handleDelete(item)}
                  className={"autosuggest-value-chip"}
                />
              ))}
            </div>
          </div>
        )}
      </Downshift>
    );
  }

  private blurInputRef = () => {
    const {
      inputRef: { current }
    } = this;
    if (!current) return;

    current.blur();
  };

  private clearInputValue = () => {
    this.props.setInputValue("");
  };
}

export const Autosuggest = withStyles(styles)(AutosuggestComponent);
