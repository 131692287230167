export interface AnalyticsPayload {
  [key: string]: string | number;
}

export function sendAnalytics(actionName: string, payload: AnalyticsPayload) {
  try {
    if (!window.heap) return;

    window.heap.track(actionName, payload);
  } catch (e) {
    console.log(e);
  }
}
