import * as React from "react";
import { styled } from "@h1eng/ui-components";
import { RequestKOLModal } from "./RequestKOLModal";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 35px;
`;

const Title = styled.h4`
  color: #333333;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  margin: 0;
`;

const Img = styled.img`
  width: 181px;
  height: auto;
  margin-bottom: 35px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 328px;
  text-align: center;
`;

const ContentText = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  line-height: 18px;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 15px;
`;

const Subtitle = styled(ContentText)`
  font-weight: 600;
  margin-top: 36px;
  margin-bottom: 12px;
`;

const imgUrl = "https://d10ba5vkyum61s.cloudfront.net/img/noresults.png";

export const NoResultsFound: React.SFC<{}> = props => {
  return (
    <Root>
      <Img src={imgUrl} />
      <TextWrapper>
        <Title>No results found</Title>
        <ContentText>
          You may have too many filters applied to your search query, or you may
          be using search terms outside your therapeutic areas.
        </ContentText>
        <Subtitle>Looking for a specific person?</Subtitle>
        <ContentText>
          You can request more information about a specific person here:
        </ContentText>
        <RequestKOLModal />
      </TextWrapper>
    </Root>
  );
};
