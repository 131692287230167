import * as React from "react";

import {
  createStyles,
  WithStyles,
  withStyles,
  Theme
} from "@material-ui/core/styles";
import * as _ from "lodash";
// import Input from '@material-ui/core/Input';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
// import FilledInput from '@material-ui/core/FilledInput';
// import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

import { connect } from "react-redux";
import { RootState } from "../../../../store/reducers";
import { getDateFilter } from "../../../../store/selectors/searchFilterSelectors";
import {
  setDateRangeFilterMin,
  setDateRangeFilterMax,
  setDateRangeActive
} from "../../../../store/actions/searchFilters";
import { DateRangeFilter } from "@h1eng/interfaces";

const styles = (them: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap"
    },
    selectEmpty: {
      marginTop: 16
    },
    select: {
      maxHeight: 100,
      borderColor: "#a39e92"
    }
  });

interface MappedStateProps {
  getDateFilter: DateRangeFilter;
}

//@ts-ignore
interface DispatchProps {
  setDateRangeFilterMin: (date: string) => void;
  setDateRangeFilterMax: (date: string) => void;
  setDateRangeActive: (isActive: boolean) => void;
}

type Props = MappedStateProps & DispatchProps & WithStyles<typeof styles>;
const ranges = (start?: number, end?: number) =>
  _.range(start || 1944, end || new Date().getFullYear() + 1).sort(
    (a, b) => b - a
  );

const rangeMenuItems = (start?: number, end?: number) =>
  ranges(start, end).map((e: number) => (
    <MenuItem key={e} value={e}>
      {e}
    </MenuItem>
  ));

class DatePickerComponet extends React.Component<Props> {
  render() {
    return (
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          width: "100%",
          paddingTop: "15px"
        }}
      >
        <div
          style={{
            fontFamily: "Montserrat",
            fontSize: "14px",
            color: "#435D6B",
            paddingBottom: "15px"
          }}
        >
          Results by year:
        </div>
        <div
          style={{
            display: "flex",
            fontFamily: "Montserrat",
            fontSize: "14px",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100% "
          }}
        >
          <div>
            <Select
              style={{ width: "75px" }}
              value={this.props.getDateFilter.min}
              classes={{
                selectMenu: this.props.classes.select
              }}
              onChange={e => {
                this.props.setDateRangeFilterMin(
                  Number.parseInt(e.target.value, 10)
                );
              }}
            >
              {...rangeMenuItems(null, this.props.getDateFilter.max + 1)}
            </Select>
          </div>
          <div style={{ width: "20px" }}>
            <hr />
          </div>
          <div>
            <Select
              style={{ width: "75px" }}
              classes={{
                selectMenu: this.props.classes.select
              }}
              value={this.props.getDateFilter.max}
              onChange={e => {
                this.props.setDateRangeFilterMax(
                  Number.parseInt(e.target.value, 10)
                );
              }}
            >
              {...rangeMenuItems(this.props.getDateFilter.min)}
            </Select>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): MappedStateProps => {
  return { getDateFilter: getDateFilter(state) };
};

const mapDispatchToProps: DispatchProps = {
  setDateRangeFilterMin,
  setDateRangeFilterMax,
  setDateRangeActive
};

export const DatePicker = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DatePickerComponet));
